import { TabPanels, Tabs } from '@chakra-ui/react';

import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';

import {
  PlanningBacklogHeader,
  PlanningBoardColumn,
  PlanningBacklogCoachingToolsTab,
  PlanningBacklogChallengesTab,
} from 'features/planning';

import { BoardColumnHeader, BoardColumnWrapper } from 'shared/components/Board';

export const PlanningBacklog = () => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);

  return (
    <PlanningBoardColumn>
      <BoardColumnWrapper fraction={2}>
        <Tabs variant="unstyled" height="100%" isLazy>
          <BoardColumnHeader minHeight={columnHeaderMinHeight}>
            <PlanningBacklogHeader />
          </BoardColumnHeader>
          <TabPanels height={`calc(100% - ${columnHeaderMinHeight})`}>
            <PlanningBacklogChallengesTab />
            {isCoachingToolsEnabled && <PlanningBacklogCoachingToolsTab />}
          </TabPanels>
        </Tabs>
      </BoardColumnWrapper>
    </PlanningBoardColumn>
  );
};

const columnHeaderMinHeight = '3.75rem';
