export const SessionsMiroIframe = ({ boardId }: { boardId: string }) => (
  <iframe
    data-testid="session-coaching-tool-miro-board"
    style={{ border: 'none', borderRadius: '0.25rem' }}
    title={`Miro bord ID: ${boardId}`}
    width="100%"
    height="100%"
    src={`https://miro.com/app/live-embed/${boardId}/?embedMode=view_only_without_ui&autoplay=true`}
  />
);
