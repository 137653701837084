import { ReactNode, createContext, useContext, useMemo } from 'react';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  filterChallengesByGoal,
  usePlanningBacklogQuery,
  useSelectedGoalsContext,
} from 'features/planning';

type PlanningBacklogFilterContextType = {
  filteredChallenges: (INonRepeatedChallenge | IRepeatedChallenge)[];
  isLoading: boolean;
};

const defaultPlanningBacklogFilterContext: PlanningBacklogFilterContextType = {
  filteredChallenges: [],
  isLoading: true,
};

const PlanningBacklogFilterContext = createContext(
  defaultPlanningBacklogFilterContext
);

export const PlanningBacklogFilterProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { selectedGoalsIds } = useSelectedGoalsContext();

  const { data: challenges, isLoading } = usePlanningBacklogQuery();

  const filteredChallenges = useMemo(() => {
    if (!challenges) {
      return [];
    }

    return !selectedGoalsIds.length
      ? challenges
      : filterChallengesByGoal({
          challenges,
          selectedGoalsIds,
        });
  }, [selectedGoalsIds, challenges]);

  const contextValue = useMemo(
    () => ({
      filteredChallenges,
      isLoading,
    }),
    [filteredChallenges, isLoading]
  );

  return (
    <PlanningBacklogFilterContext.Provider value={contextValue}>
      {children}
    </PlanningBacklogFilterContext.Provider>
  );
};

export const usePlanningBacklogFilterContext = () => {
  const planningBacklogFilterContext = useContext(PlanningBacklogFilterContext);

  if (!planningBacklogFilterContext) {
    throw new Error(
      'usePlanningBacklogFilterContext must be used within a PlanningBacklogFilterContext.Provider'
    );
  }

  return planningBacklogFilterContext;
};
