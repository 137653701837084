import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { getSprintsWithoutDraft } from 'features/sprint/utils';

import { Dropdown, DropdownItem } from 'shared/components/Dropdown';
import { SidebarMainHeader } from 'shared/components/Sidebar';
import { useSidebarContext } from 'shared/components/Sidebar/context';
import { Typography } from 'shared/components/Typography';

const SprintHistoryHeaderPropTypes = {
  selectedSprintId: PropTypes.string.isRequired,
  setSelectedSprintId: PropTypes.func.isRequired,
};

const SprintHistoryHeader = ({ selectedSprintId, setSelectedSprintId }) => {
  const { sprints } = useSidebarContext();

  if (!sprints) {
    return null;
  }

  return (
    <SidebarMainHeader title="History">
      {sprints.length > 1 ? (
        <Typography type="meta">
          <Dropdown
            onItemSelect={(selectedSprintId) => {
              setSelectedSprintId(selectedSprintId);
            }}
            anchorContent={
              <Box style={{ maxWidth: '13rem' }}>
                <Typography cropped={true}>
                  {
                    sprints.find(
                      (sprint) => sprint.sprintId === selectedSprintId
                    ).title
                  }
                </Typography>
              </Box>
            }
            selectedValue={selectedSprintId}
          >
            {getSprintsWithoutDraft(sprints).map((sprint) => (
              <Box
                key={sprint.sprintId}
                style={{ width: '15rem' }}
                role="listbox"
              >
                <Flex alignItems="stretch" flexDirection="column">
                  <DropdownItem value={sprint.sprintId}>
                    {sprint.title}
                  </DropdownItem>
                </Flex>
              </Box>
            ))}
          </Dropdown>
        </Typography>
      ) : null}
    </SidebarMainHeader>
  );
};

SprintHistoryHeader.propTypes = SprintHistoryHeaderPropTypes;

export default SprintHistoryHeader;
