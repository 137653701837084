import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { ChallengeNotesCardRecapSessionStatusIcon } from 'features/challengeNotes/components/Card/Recap';
import { SessionAttendanceStatusType } from 'features/sessions/config/types';

import { Typography } from 'shared/components/Typography';

type ChallengeNotesRecapCardTitleProps = {
  title: string;
  attendance: SessionAttendanceStatusType;
};

export const ChallengeNotesCardRecapTitle = ({
  title,
  attendance,
}: ChallengeNotesRecapCardTitleProps) => (
  <Spacer size="md zr">
    <Flex justifyContent="flex-start">
      <ChallengeNotesCardRecapSessionStatusIcon attendance={attendance} />
      <Spacer size="zr sm" />
      <Typography type="small" fontWeight="semiBold" color="black">
        {title}
      </Typography>
    </Flex>
  </Spacer>
);
