import { Typography } from 'shared/components/Typography';

export const PlanningTitle = () => (
  <Typography
    as="h2"
    type="semiLargeHeading"
    color="black"
    fontWeight="semiBold"
  >
    Sprint planning
  </Typography>
);
