import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { UseMutateAsyncFunction } from 'react-query';

type TUseGoalsBoardDrop = {
  dropZonePath: number[];
  onDrop: UseMutateAsyncFunction<
    any,
    unknown,
    any,
    { previousLayout: unknown }
  >;
  accept: string[];
};

export const useGoalsBoardDrop = ({
  dropZonePath,
  onDrop,
  accept,
}: TUseGoalsBoardDrop) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: useCallback(
      async (item) => {
        const isItemAtTheSameLevelAsDropzone =
          item.path.length === dropZonePath.length;

        if (!isItemAtTheSameLevelAsDropzone) {
          return;
        }

        await onDrop({
          ...item,
          dropZonePath,
        });
      },
      [dropZonePath, onDrop]
    ),
    canDrop: (item) => {
      const isItemAtTheSameColumnAsDropZone = item.path[0] === dropZonePath[0];

      const isItemAdjacentToDropZone =
        item.path[1] + 1 === dropZonePath[1] ||
        item.path[1] === dropZonePath[1];

      return !(isItemAtTheSameColumnAsDropZone && isItemAdjacentToDropZone);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return { drop, isOver, canDrop };
};
