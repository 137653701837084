import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { ColumnsType } from 'features/goal';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const useGoalsTableQuery = () => {
  const { request } = useQueryHTTPRequest();
  const { userId } = useCurrentModeUser();

  const { data, isLoading } = useQuery(
    [queryKeys.goals, userId],
    (): Promise<{ columns: ColumnsType; deepDiveAvailable: boolean }> =>
      request({ url: `/api/web/roadmaps/${userId}` }),
    {
      enabled: !!userId,
    }
  );
  return {
    columns: data?.columns || [],
    deepDiveAvailable: data?.deepDiveAvailable,
    isLoading,
  };
};
