import PropTypes from 'prop-types';

import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const CardHeaderPropTypes = {
  /**
   * The date of the data.
   * @type {string}
   * @required
   * @name date
   * @example <CardHeader date="2019-01-01T00:00:00.000Z" />
   */
  date: PropTypes.string.isRequired,
  /**
   * User picture.
   * @type {string}
   * @name picture
   * @example <CardHeader pictureUrl="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" />
   */
  pictureUrl: PropTypes.string,
  /**
   * User name.
   * @type {string}
   * @required
   * @name userName
   * @example <CardHeader userName="John Doe" />
   */
  userName: PropTypes.string.isRequired,
  /**
   * Children elements.
   * @type {ReactNode}
   * @name children
   * @example <CardHeader>Children</CardHeader>
   */
  children: PropTypes.node,
};

const CardHeader = ({ date, pictureUrl, userName, children }) => (
  <Flex>
    <Flex alignItems="center" justifyContent="start">
      <Avatar name={userName} size={SIZES.SM} src={pictureUrl} />
      <Spacer size="zr md zr zr" />
      <Spacer size="zr sm zr zr" />
      <Typography fontWeight="semiBold" type="small" color="black">
        {userName}
      </Typography>
      <Spacer size="zr sm zr zr" />
      <Typography color="gray" type="small">
        {date}
      </Typography>
    </Flex>
    {children}
  </Flex>
);

CardHeader.propTypes = CardHeaderPropTypes;

export default CardHeader;
