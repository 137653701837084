import { UserMode } from 'shared_DEPRECATED/types/User';

import { ANALYTICS_EVENTS } from './config';
import { sendAnalyticsEvent } from './utils';

export const sendSessionRecapStartAnalyticsEvent = ({
  sessionId,
  coachId,
  startRecapAt,
  userMode,
}: {
  userMode: UserMode;
  sessionId: string;
  coachId: string;
  startRecapAt: number;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.START_SESSION_RECAP,
    data: {
      userMode,
      sessionId,
      coachId,
      startRecapAt,
    },
  });

export const sendSessionRecapFinishAnalyticsEvent = ({
  sessionId,
  coachId,
  finishRecapAt,
  userMode,
}: {
  userMode: UserMode;
  sessionId: string;
  coachId: string;
  finishRecapAt: number;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.FINISH_SESSION_RECAP,
    data: {
      userMode,
      sessionId,
      coachId,
      finishRecapAt,
    },
  });

export const sendSessionRecapScheduleAnalyticsEvent = ({
  sessionId,
  coachId,
  scheduleRecapAt,
  userMode,
}: {
  userMode: UserMode;
  sessionId: string;
  coachId: string;
  scheduleRecapAt: number;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SCHEDULE_SESSION_RECAP,
    data: {
      userMode,
      sessionId,
      coachId,
      scheduleRecapAt,
    },
  });
