import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { PlanningTitle, PlanningAddSprintButton } from 'features/planning';

export const PlanningHeader = () => (
  <Flex
    flexDirection="column"
    width="100%"
    dataTestid="sprint-planning-header"
    alignItems="flex-start"
  >
    <PlanningTitle />
    <Spacer size="lg" />
    <FlexItem alignSelf="flex-end">
      <PlanningAddSprintButton />
    </FlexItem>
  </Flex>
);
