import { useFormContext } from 'react-hook-form';

import { TextInput } from 'shared_DEPRECATED/components/Form/Input';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SessionsFormCoachingToolsLinkHint } from 'features/sessions/components/Form/CoachingTools';
import { SessionsFormCoachingToolsSection } from 'features/sessions/components/Form/CoachingTools/Section';
import { COACHING_EXTERNAL_TOOL } from 'features/sessions/config';

type SessionsFormCoachingToolsLinkFieldProps = {
  formKey: string;
  coachingToolValue: string;
};
export const SessionsFormCoachingToolsLinkField = ({
  formKey,
  coachingToolValue,
}: SessionsFormCoachingToolsLinkFieldProps) => {
  const { register } = useFormContext();

  return (
    <>
      {coachingToolValue === COACHING_EXTERNAL_TOOL ? (
        <>
          <SessionsFormCoachingToolsSection title="External session tool name">
            <TextInput
              {...register(`${formKey}.unlistedToolName`)}
              //@ts-ignore
              placeholder="Add the name here"
            />
            <Spacer size="sm zr" />
          </SessionsFormCoachingToolsSection>
          <SessionsFormCoachingToolsSection title="Tool description link">
            <TextInput
              {...register(`${formKey}.link`)}
              //@ts-ignore
              placeholder="Add any related link for tool materials"
            />
          </SessionsFormCoachingToolsSection>
        </>
      ) : (
        <SessionsFormCoachingToolsSection>
          <TextInput
            {...register(`${formKey}.link`)}
            //@ts-ignore
            placeholder="Add miro link here"
          />
          <Spacer size="sm zr" />
          <SessionsFormCoachingToolsLinkHint />
        </SessionsFormCoachingToolsSection>
      )}
    </>
  );
};
