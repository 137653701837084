import { toastConfig } from 'lib/reactToastify/config';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getNoteRepliesQueryKey } from 'api/utils';
import { logError } from 'lib/sentry/logError';

export const useNoteReplyEditMutation = ({ noteId, replyId, options }) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const noteRepliesQueryKey = getNoteRepliesQueryKey(noteId);

  return useMutation(
    ['edit-note-replies', noteId, replyId],
    (text) =>
      request({
        url: `/api/notes/${noteId}/replies/${replyId}/edit`,
        body: { text },
      }),
    {
      onMutate: (text) => {
        const previousNoteReplies =
          queryClient.getQueryData(noteRepliesQueryKey);

        queryClient.setQueryData(noteRepliesQueryKey, (old) => ({
          ...old,
          replies: old.replies.map((reply) =>
            reply.replyId === replyId ? { ...reply, text } : reply
          ),
        }));

        return { previousNoteReplies };
      },
      onError: (err, _, context) => {
        toast.error(
          "There's an error with editing note reply. Please try again later.",
          {
            icon: false,
            ...toastConfig,
          }
        );

        logError(err);

        queryClient.setQueryData(
          noteRepliesQueryKey,
          context.previousNoteReplies
        );
      },
      onSettled: () => {
        queryClient.refetchQueries(noteRepliesQueryKey, { exact: true });
      },
      ...options,
    }
  );
};
