import { useNavigate } from 'react-router-dom';

import Button, { ButtonIcon } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SessionSidebarSection } from 'features/sessions/components';

import { sidebar } from 'shared/components/Sidebar';
import { Typography } from 'shared/components/Typography';

export const NextSessionSidebarHeader = ({
  clientEmail,
  coachingSessionId,
  onNextSessionSave,
}: {
  clientEmail: string;
  coachingSessionId: string;
  onNextSessionSave: () => Promise<void>;
}) => {
  const navigate = useNavigate();

  const handleClick = async () => {
    await onNextSessionSave();

    navigate(
      `/coach/participants/${clientEmail}/sessions/${coachingSessionId}`
    );
  };

  return (
    <SessionSidebarSection>
      <Flex alignItems="center">
        <Flex justifyContent="space-between">
          <ButtonIcon
            dataTestid="next-session-sidebar-close-button"
            onClick={() => {
              sidebar.close();
            }}
            icon={iconNames.chevronRight}
          />
          <Spacer size="sm" />
          <Typography color="black" as="h3">
            Next session notes
          </Typography>
        </Flex>
        {/*TODO: coachingSessionId !== 'None' statement can be deleted when backend fix issue
        https://app.asana.com/0/1203344867346473/1205601800242170/f
        */}
        {coachingSessionId && coachingSessionId !== 'None' && (
          <Button onClick={handleClick}>
            <Typography color="blue" type="meta">
              View full notes
            </Typography>
          </Button>
        )}
      </Flex>
    </SessionSidebarSection>
  );
};
