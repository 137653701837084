import { getPictureUrl } from 'shared_DEPRECATED/utils';

import { ParticipantsTableUserInfo } from 'features/participants/config/types';
import { SessionSidebarSection } from 'features/sessions/components';
import { UserInfo } from 'features/user/components';

import { useSidebarContext } from 'shared/components/Sidebar/context';

export const NextSessionUserInfo = () => {
  const { userInfo }: { userInfo: ParticipantsTableUserInfo } =
    useSidebarContext();

  return (
    <SessionSidebarSection>
      <UserInfo
        name={userInfo.fullName || userInfo.email}
        pictureUrl={getPictureUrl(userInfo.picture)}
      />
    </SessionSidebarSection>
  );
};
