import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { IChallenge } from 'features/challenge/config/types';
import { useOpenChallengeModal } from 'features/challenge/hooks';
import { useEditCustomChallenge } from 'features/challenge/hooks/useEditCustomChallenge';
import { useSprints } from 'features/sprint/hooks';

import { Typography } from 'shared/components/Typography';

type TPlanningBacklogItemMenuEditButton = {
  challenge: IChallenge;
};

export const PlanningBacklogItemMenuEditButton = ({
  challenge,
}: TPlanningBacklogItemMenuEditButton) => {
  const { data: sprints } = useSprints();

  const { editCustomChallenge: onChallengeSubmit } = useEditCustomChallenge();

  const openChallengeModal = useOpenChallengeModal();

  const openEditChallengeModal = () =>
    openChallengeModal({
      challenge,
      onChallengeSubmit,
      title: 'Create Challenge',
      sprint: sprints[0],
    });

  return (
    <OptionsMenuButton onClick={openEditChallengeModal}>
      <Typography>Edit</Typography>
    </OptionsMenuButton>
  );
};
