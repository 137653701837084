import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { removeFromArrayById } from 'shared_DEPRECATED/utils';

import { ICoachingTool } from 'features/coachingTool';
import { IPlanningDraftSprint } from 'features/planning/types';

export const useMovePlanningCoachingToolMutation = () => {
  const queryClient = useQueryClient();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({
      toolId,
      fromSprintId,
      toSprintId,
    }: {
      toolId: string;
      fromSprintId: string;
      toSprintId: string;
    }) =>
      request({
        url: `/api/web/sprint-planning/coaching-tools/move`,
        body: { toolId, fromSprintId, toSprintId },
      }),
    {
      onMutate: async ({ toolId, fromSprintId, toSprintId }) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        const coachingTools = queryClient.getQueryData<{
          items: ICoachingTool[];
        }>([queryKeys.coachingTools]);

        const coachingTool = coachingTools!.items.find(
          ({ id }) => id === toolId
        );

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => {
              let updatedSprints = [...oldVal!.items];

              const fromSprintIndex = updatedSprints.findIndex(
                (sprint) => sprint.sprintId === fromSprintId
              );

              updatedSprints[fromSprintIndex].coachingTools =
                removeFromArrayById({
                  array: updatedSprints[fromSprintIndex].coachingTools,
                  id: toolId,
                  idProperty: 'id',
                });

              const toSprintIndex = updatedSprints.findIndex(
                (sprint) => sprint.sprintId === toSprintId
              );

              updatedSprints[toSprintIndex].coachingTools.push(coachingTool!);

              return { ...oldVal, items: updatedSprints };
            }
          );
        }

        return { previousDraftSprintsValue };
      },
      onError: (
        err: Error,
        __: any,
        context:
          | {
              previousDraftSprintsValue?: {
                items: IPlanningDraftSprint[];
              };
            }
          | undefined
      ) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints],
            context.previousDraftSprintsValue
          );
        }
        logError(err);
      },
      onSuccess: () => {},
    }
  );
};
