import React from 'react';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalBreakdownDropZone,
  GoalChallengeCard,
  GoalBreakdownChallengeCardMenu,
  GoalFocusAreaChallengeCardMenu,
  useMoveGoalBreakdownChallengesMutation,
  GoalBreakdownChallengeType,
} from 'features/goal';

import { BoardColumnItem } from 'shared/components/Board';

type TGoalBreakdownBoardChallenges = {
  challenges: GoalBreakdownChallengeType[];
  canDrop: (challengeIndex: number) => (item: any) => boolean;
  focusAreaId?: string;
};

const getChallengeMenuComponent = ({
  focusAreaId,
  challenge,
}: {
  challenge: GoalBreakdownChallengeType;
  focusAreaId?: string;
}) =>
  focusAreaId ? (
    <GoalFocusAreaChallengeCardMenu
      focusAreaId={focusAreaId}
      challenge={challenge}
    />
  ) : (
    <GoalBreakdownChallengeCardMenu challenge={challenge} />
  );

export const GoalBreakdownBoardChallenges = ({
  challenges,
  canDrop,
  focusAreaId,
}: TGoalBreakdownBoardChallenges) => {
  const ChallengeMenuComponent = (index: number) =>
    getChallengeMenuComponent({
      focusAreaId,
      challenge: challenges[index],
    });

  const { mutateAsync } = useMoveGoalBreakdownChallengesMutation({
    focusAreaId,
  });

  const isFocusAreaEmpty = focusAreaId && !challenges.length;

  return (
    <>
      {challenges.map((challenge, challengeIndex) => (
        <React.Fragment
          key={`${challenge.customChallengeId}-${challengeIndex}`}
        >
          <GoalBreakdownDropZone
            onDrop={mutateAsync}
            handleCanDrop={canDrop(challengeIndex)}
            dropZoneIndex={challengeIndex}
            accept={['challenge']}
            isLast={false}
          >
            <BoardColumnItem
              item={{
                ...challenge,
                parentFocusAreaId: focusAreaId!,
                index: challengeIndex,
              }}
              type={'challenge'}
            >
              <GoalChallengeCard
                title={challenge.title}
                picture={challenge.picture}
                emoji={challenge.emoji}
                dimensions={challenge.dimensions}
              >
                {ChallengeMenuComponent(challengeIndex)}
              </GoalChallengeCard>
            </BoardColumnItem>
          </GoalBreakdownDropZone>
          <Spacer size="xsm" />
        </React.Fragment>
      ))}
      {!isFocusAreaEmpty && (
        <GoalBreakdownDropZone
          handleCanDrop={canDrop(challenges.length)}
          dropZoneIndex={challenges.length}
          onDrop={mutateAsync}
          accept={['challenge']}
          isLast={false}
        />
      )}
    </>
  );
};
