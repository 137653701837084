import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';

import { Button, ButtonIcon } from 'shared';
import { Divider } from 'shared_DEPRECATED/components/Divider';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Image from 'shared_DEPRECATED/components/Image';
import { LinkNewTab } from 'shared_DEPRECATED/components/Link/LinkNewTab';
import Loader from 'shared_DEPRECATED/components/Loader';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  ChallengeInfoImage,
  ChallengeInfoName,
} from 'features/challenge/components/Info/';
import { CHALLENGE_TYPES } from 'features/challenge/config';
import { getDimensionsText } from 'features/lifeDimensions/utils';
import { useChallengeQuickStartSprintModalContent } from 'features/planning';
import { useChallengeAnalytics } from 'features/sprint/hooks/useChallengeAnalytics';
import { getChallengeFrequencyText } from 'features/sprint/utils';

import { VARIANTS } from 'shared/components/Button';
import { ModalBody, ModalFooter, ModalHeader } from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';
import { Typography } from 'shared/components/Typography';

const ChallengeQuickStartSprintModalContentPropTypes = {
  qssId: PropTypes.string.isRequired,
  onBackBtnClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const ChallengeQuickStartSprintModalContent = ({ qssId, onBackBtnClick }) => {
  const { qss, isLoading, onChallengeClick, onSubmit } =
    useChallengeQuickStartSprintModalContent({ qssId });

  const { sendChallengeAddedFromLibEvent } = useChallengeAnalytics();

  return (
    <FormProvider>
      <ModalHeader>
        <ButtonIcon
          ariaLabel="back"
          icon={<Icon name={iconNames.arrowBigLeft} height={12} width={14} />}
          dataTestid="back-button"
          onClick={onBackBtnClick}
          size="sm"
          variant={VARIANTS.TERTIARY}
        />
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader size="sm" />
        ) : (
          <>
            {qss && (
              <>
                <Image
                  height={140}
                  borderRadius="0.5rem"
                  src={qss.picture.url}
                />
                <Spacers sizes={['md zr', 'xsm zr']} />
                <Typography
                  as="h1"
                  type="heading"
                  fontWeight="semiBold"
                  color="black"
                >
                  {qss.title}
                </Typography>
                <Spacers sizes={['md zr xsm']} />
                <Typography color="gray" type="meta" fontWeight="regular">
                  {getDimensionsText(qss.dimensions, qss.dimensions.length)}
                </Typography>
                <Spacers sizes={['md zr xsm']} />
                <Typography color="gray" type="meta" fontWeight="regular">
                  {qss.description}
                </Typography>
                <Spacers sizes={['md zr', 'xsm zr']} />
                <Divider />
                <Spacers sizes={['md zr', 'xsm zr']} />
                <Typography
                  as="h2"
                  type="meta"
                  fontWeight="medium"
                  color="black"
                >
                  Repeated challenges
                </Typography>
                <Spacers sizes={['md zr']} />
                <Flex
                  as="ul"
                  gap="0.75rem"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  {qss.repeatingChallenges.map((challenge) => {
                    const {
                      curatedChallengeId,
                      title,
                      emoji,
                      pictureUrl,
                      target,
                    } = challenge;

                    return (
                      <li key={curatedChallengeId}>
                        <Button onClick={onChallengeClick(challenge)}>
                          <Flex>
                            <FlexItem flexShrink={0}>
                              <Spacers sizes={['zr md zr zr', 'zr sm zr zr']}>
                                <ChallengeInfoImage
                                  title={title}
                                  emoji={emoji}
                                  pictureUrl={pictureUrl}
                                />
                              </Spacers>
                            </FlexItem>
                            <Flex flexDirection="column" alignItems="start">
                              <ChallengeInfoName>{title}</ChallengeInfoName>
                              <Typography
                                color="gray"
                                type="small"
                                fontWeight="regular"
                              >
                                {getChallengeFrequencyText(target)}
                              </Typography>
                            </Flex>
                          </Flex>
                        </Button>
                      </li>
                    );
                  })}
                </Flex>
                {qss.oneTimeChallenges.length > 0 && (
                  <>
                    <Spacers sizes={['md zr', 'xsm zr']} />
                    <Divider />
                    <Spacers sizes={['md zr', 'xsm zr']} />
                    <Typography
                      as="h2"
                      type="meta"
                      fontWeight="medium"
                      color="black"
                    >
                      One-time challenges
                    </Typography>
                    <Spacers sizes={['md zr']} />
                    <Flex
                      as="ul"
                      gap="0.75rem"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      {qss.oneTimeChallenges.map((challenge) => {
                        const { curatedChallengeId, title, emoji, pictureUrl } =
                          challenge;

                        return (
                          <li key={curatedChallengeId}>
                            <Button onClick={onChallengeClick(challenge)}>
                              <Flex>
                                <FlexItem flexShrink={0}>
                                  <Spacers
                                    sizes={['zr md zr zr', 'zr sm zr zr']}
                                  >
                                    <ChallengeInfoImage
                                      title={title}
                                      emoji={emoji}
                                      pictureUrl={pictureUrl}
                                    />
                                  </Spacers>
                                </FlexItem>
                                <ChallengeInfoName>{title}</ChallengeInfoName>
                              </Flex>
                            </Button>
                          </li>
                        );
                      })}
                    </Flex>
                  </>
                )}
                <Spacers sizes={['md zr', 'xsm zr']} />
                <Divider />
                <Spacers sizes={['md zr', 'xsm zr']} />
                <Typography
                  as="h2"
                  type="meta"
                  fontWeight="medium"
                  color="black"
                >
                  References and related content
                </Typography>
                <Spacers sizes={['md zr']} />
                <Flex
                  as="ul"
                  gap="1.5rem"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  {qss.links.map(({ title, url, subtitle }) => (
                    <li key={url}>
                      <LinkNewTab href={url}>
                        <Flex
                          as="span"
                          gap="0.25rem"
                          justifyContent="flex-start"
                        >
                          <Icon
                            name={iconNames.link}
                            height={16}
                            width={16}
                            fill="var(--fgInteractive)"
                          />
                          <Typography color="blue" type="small">
                            {title}
                          </Typography>
                        </Flex>
                      </LinkNewTab>
                      <Spacers sizes={['xsm zr']} />
                      <Typography
                        as="p"
                        type="meta"
                        fontWeight="regular"
                        color="black"
                      >
                        {subtitle}
                      </Typography>
                    </li>
                  ))}
                </Flex>
              </>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => {
            sendChallengeAddedFromLibEvent();

            const qssRepeatedChallenges = qss.repeatingChallenges.map(
              (challenge) => ({
                ...challenge,
                type: CHALLENGE_TYPES.REPEATING,
                source: {
                  curatedChallengeId: challenge.curatedChallengeId,
                },
              })
            );

            const qssOneTimeChallenges = qss.oneTimeChallenges.map(
              (challenge) => ({
                ...challenge,
                type: CHALLENGE_TYPES.NON_REPEATING,
                target: {
                  ...challenge.target,
                  dueDate: dateUtils().format('YYYY-MM-DD'),
                },
                source: {
                  curatedChallengeId: challenge.curatedChallengeId,
                },
              })
            );

            onSubmit([...qssRepeatedChallenges, ...qssOneTimeChallenges]);
          }}
          variant={VARIANTS.PRIMARY}
          dataTestid="challenge-dialog-submit"
        >
          <Spacers sizes={['md', 'sm']}>
            <Typography fontWeight="medium" type="meta">
              Try this sprint
            </Typography>
          </Spacers>
        </ModalButton>
      </ModalFooter>
    </FormProvider>
  );
};

ChallengeQuickStartSprintModalContent.propTypes =
  ChallengeQuickStartSprintModalContentPropTypes;

export default ChallengeQuickStartSprintModalContent;
