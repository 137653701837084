import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import DimensionCheckbox from 'features/challenge/components/Dimension/Checkbox/DimensionCheckbox';
import {
  DIMENSIONS,
  EXPLORE_DIALOG_DEFAULT_DIMENSIONS_FILTER_VALUE,
} from 'features/challenge/config';
import { dimensionFirstLetterToUpperCase } from 'features/challenge/utils';
import { useDimensionsQuery } from 'features/lifeDimensions/hooks/query/useDimensions';
import { useExploreDialogContentContext } from 'features/planning';

export const ExploreDialogDimensionCheckboxes = () => {
  const { data: dimensions, isLoading } = useDimensionsQuery({
    selector: (data) =>
      data.items
        .map((dimension) => dimensionFirstLetterToUpperCase(dimension.name))
        .filter((dimension) => dimension !== DIMENSIONS.OTHER),
  });
  const context = useExploreDialogContentContext();

  const { setSelectedDimensions } = context;

  let selectedDimensions = useMemo(
    () => context.selectedDimensions || {},
    [context.selectedDimensions]
  );

  const handleDimensionChange = useCallback(
    (dimension) => {
      const newSelectedDimensions = {
        ...selectedDimensions,
      };
      const newDimensionValue = !selectedDimensions[dimension];

      if (!newDimensionValue) {
        delete newSelectedDimensions[dimension];
      } else {
        newSelectedDimensions[dimension] = newDimensionValue;
      }

      setSelectedDimensions(
        Object.keys(newSelectedDimensions).length === 0
          ? EXPLORE_DIALOG_DEFAULT_DIMENSIONS_FILTER_VALUE
          : newSelectedDimensions
      );
    },
    [selectedDimensions, setSelectedDimensions]
  );

  if (isLoading) {
    return null;
  }

  return (
    <Box style={{ overflowX: 'scroll' }}>
      <Flex justifyContent="flex-start">
        {dimensions.map((dimension, index) => (
          <FlexItem key={`${dimension}-${index}`} flexShrink={0}>
            <Spacer size={index < dimensions.length - 1 ? 'zr md zr zr' : 'zr'}>
              <DimensionCheckbox
                name={dimension}
                checked={selectedDimensions[dimension] || false}
                onChange={() => handleDimensionChange(dimension)}
              />
            </Spacer>
          </FlexItem>
        ))}
      </Flex>
    </Box>
  );
};

ExploreDialogDimensionCheckboxes.propTypes = {
  selectedDimensions: PropTypes.object.isRequired,
  setSelectedDimensions: PropTypes.func.isRequired,
};
