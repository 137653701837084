import { Menu, MenuList, MenuProps } from '@chakra-ui/react';
import { ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';

import { Nullable } from 'shared_DEPRECATED/types';

import { DropdownAnchor } from './Anchor';
import { DropdownContextProvider } from './context';

type TDropdown = {
  children: ReactNode;
  anchorContent: ReactNode;
  dataTestid?: Nullable<string>;
  onItemSelect?: (value: string) => void;
  selectedValue?: string;
  anchorSpacing?: string[];
  withChevronIcon?: boolean;
};

const defaultSpacing: string[] = [];

export const Dropdown = ({
  children,
  anchorContent,
  onItemSelect,
  selectedValue,
  anchorSpacing = defaultSpacing,
  withChevronIcon,
  ...props
}: TDropdown & MenuProps) => {
  const onSelect = useCallback(
    (value) => {
      if (onItemSelect) {
        onItemSelect(value);
      }
    },
    [onItemSelect]
  );

  return (
    <Menu {...props}>
      {({ isOpen }) => (
        <>
          <DropdownAnchor
            anchorContent={anchorContent}
            open={isOpen}
            spacing={anchorSpacing}
            withChevronIcon={withChevronIcon}
          />
          {createPortal(
            <MenuList>
              <DropdownContextProvider
                onItemSelect={onSelect}
                selectedValue={selectedValue}
              >
                {children}
              </DropdownContextProvider>
            </MenuList>,
            document.body
          )}
        </>
      )}
    </Menu>
  );
};
