import { TabList } from '@chakra-ui/react';

import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { PlanningBacklogHeaderTab } from 'features/planning';

export const PlanningBacklogHeader = () => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);

  return (
    <Spacer size="sm">
      <Flex justifyContent="space-between" width="100%">
        <Flex>
          <TabList>
            <PlanningBacklogHeaderTab>Challenges</PlanningBacklogHeaderTab>
            {isCoachingToolsEnabled && (
              <PlanningBacklogHeaderTab>
                Coaching tools
              </PlanningBacklogHeaderTab>
            )}
          </TabList>
        </Flex>
      </Flex>
    </Spacer>
  );
};
