import { useNavigate } from 'react-router-dom';

import Box from 'shared_DEPRECATED/components/Box';
import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';

import {
  ParticipantsTableActionDisabled,
  ParticipantsTableActionWrapper,
  ParticipantsTableCounter,
} from 'features/participants/components/Table/Actions/';
import {
  ParticipantsTableLatestSprint,
  ParticipantsTableUserInfo,
} from 'features/participants/config/types';
import { useChatMessagesCountChange } from 'features/participants/hooks';

type ParticipantsTableMessagesPropTypes = {
  userInfo: ParticipantsTableUserInfo;
  latestSprint: ParticipantsTableLatestSprint;
  chatId: Nullable<string>;
  unreadChatMessagesNumber: number;
};

export const ParticipantsTableMessages = ({
  userInfo,
  latestSprint,
  chatId,
  unreadChatMessagesNumber,
}: ParticipantsTableMessagesPropTypes) => {
  const { email: userEmail } = userInfo;
  const navigate = useNavigate();

  useChatMessagesCountChange({
    chatId,
    userEmail,
  });

  const handleMessageClick = () => {
    navigate(
      `/coach/participants/${userEmail}/${
        latestSprint?.sprintId || 'dashboard'
      }`,
      {
        state: {
          openChat: true,
        },
      }
    );
  };

  return chatId ? (
    <Button
      variant={VARIANTS.TERTIARY}
      onClick={handleMessageClick}
      dataTestid="participant-messages-btn"
    >
      <ParticipantsTableActionWrapper>
        <Box style={{ position: 'relative' }}>
          <Icon
            name={iconNames.paperPlane}
            stroke="var(--fgPrimary)"
            width={18}
            height={18}
          />
          {unreadChatMessagesNumber > 0 && (
            <ParticipantsTableCounter value={unreadChatMessagesNumber} />
          )}
        </Box>
      </ParticipantsTableActionWrapper>
    </Button>
  ) : (
    <ParticipantsTableActionDisabled
      icon={iconNames.paperPlane}
      label="User has an inactive coaching status"
    />
  );
};
