import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';

import { DraftSprint } from 'features/sprint';
import { IDraftSprint } from 'features/sprint/config/types';

export const useCreateDraftSprintInstanceWithWeeksCount = () => {
  const weeksCount = useSprintDurationFeatureFlag();

  return (sprint: IDraftSprint): DraftSprint =>
    new DraftSprint({ ...sprint, weeksCount } as IDraftSprint);
};
