import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { useDeletePlanningBacklogChallengesMutation } from 'features/planning';

import { Typography } from 'shared/components/Typography';

type TPlanningBacklogMenuRemoveButton = {
  challengeId: string;
};

export const PlanningBacklogMenuRemoveButton = ({
  challengeId,
}: TPlanningBacklogMenuRemoveButton) => {
  const { mutateAsync: deleteChallenge } =
    useDeletePlanningBacklogChallengesMutation();

  return (
    <OptionsMenuButton onClick={() => deleteChallenge([challengeId])}>
      <Typography color="red">Remove</Typography>
    </OptionsMenuButton>
  );
};
