import PropTypes from 'airbnb-prop-types';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import CardBody from './CardBody';
import CardFooter from './CardFooter';

const CardContainer = ({ children }) => (
  <Spacer size="zr zr zr lg">
    <Spacer size="zr zr zr lg">
      <Spacer size="zr zr zr sm">{children}</Spacer>
    </Spacer>
  </Spacer>
);

CardContainer.propTypes = {
  /**
   * The content of the component.
   * @type {React.ReactNode}
   * @required
   * @name children
   * @example <CardContainer><CardBody>Content</CardBody><CardFooter>Footer</CardFooter></CardContainer>
   * @example <CardContainer><CardBody>Content</CardBody></CardContainer>
   */
  children: PropTypes.childrenOfType(CardBody, CardFooter),
};

export default CardContainer;
