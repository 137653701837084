import Box from 'shared_DEPRECATED/components/Box';
import { capitalize } from 'shared_DEPRECATED/utils';

import { ChallengeGoalsType } from 'features/challenge/config/types';

import { Typography } from 'shared/components/Typography';

type TPlanningChallengeGoals = {
  goals: ChallengeGoalsType;
  maxWidth?: string;
};

export const PlanningChallengeGoals = ({
  goals,
  maxWidth = '100%',
}: TPlanningChallengeGoals) => (
  <Box style={{ maxWidth }}>
    <Typography
      whiteSpace="pre-wrap"
      color="gray"
      type="small"
      fontWeight="regular"
    >
      {getGoalsText(goals)}
    </Typography>
  </Box>
);

export const getGoalsText = (goals: ChallengeGoalsType) =>
  goals.map((goal) => capitalize(goal.title)).join(' • ');
