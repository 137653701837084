import PropTypes from 'prop-types';

import { camelCase } from 'camel-case';
import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { TARGET_TYPES } from 'features/challenge/config';
import { ChallengeTargetType } from 'features/challenge/config/propTypes';
import { getWeekdayTargetText } from 'features/challenge/utils';
import {
  getSprintHistoryWeeklyTargetText,
  getSprintHistoryDueDateTargetText,
} from 'features/sprint/utils';

import { Typography } from 'shared/components/Typography';

const ChallengeFrequencyChangedPropTypes = {
  challengeTitle: PropTypes.string.isRequired,
  before: ChallengeTargetType.isRequired,
  after: ChallengeTargetType.isRequired,
};

const TARGET_TYPE_TEXT_FORMATTER = {
  [TARGET_TYPES.WEEKLY]: getSprintHistoryWeeklyTargetText,
  [TARGET_TYPES.WEEKDAY]: getWeekdayTargetText,
  [TARGET_TYPES.DUE_DATE]: getSprintHistoryDueDateTargetText,
};

const renderFrequency = (targetConfig) => {
  const { type } = targetConfig;
  const frequencyFormatter = TARGET_TYPE_TEXT_FORMATTER[type];

  return frequencyFormatter
    ? frequencyFormatter(targetConfig[camelCase(type)])
    : '';
};

const ChallengeFrequencyChanged = ({
  challengeTitle,
  before: frequencyBefore,
  after: frequencyAfter,
}) => (
  <Typography>
    Changed frequency in the challenge{' '}
    <Typography as="strong" fontWeight="medium" color="black">
      {challengeTitle}
    </Typography>{' '}
    from{' '}
    <Box as="span" style={{ backgroundColor: 'var(--bgCompSecondaryMid)' }}>
      <Spacer as="span" size="zr zr zr sm" />
      <Typography as="strong" fontWeight="medium" color="black">
        {renderFrequency(frequencyBefore)}
      </Typography>
      <Spacer as="span" size="zr sm zr zr" />
    </Box>{' '}
    to{' '}
    <Box as="span" style={{ backgroundColor: 'var(--bgCompMinorSuccess)' }}>
      <Spacer as="span" size="zr zr zr sm" />
      <Typography as="strong" fontWeight="medium" color="black">
        {renderFrequency(frequencyAfter)}
      </Typography>
      <Spacer as="span" size="zr sm zr zr" />
    </Box>
  </Typography>
);

ChallengeFrequencyChanged.propTypes = ChallengeFrequencyChangedPropTypes;

export default ChallengeFrequencyChanged;
