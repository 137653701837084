import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

type SessionSidebarProps = {
  children: ReactNode;
  width: string;
};

export const SessionSidebar = ({ width, children }: SessionSidebarProps) => (
  <Box
    style={{
      height: '100%',
      padding: 'var(--spacing06) 0 0',
      width,
      backgroundColor: 'var(--bgSecondary)',
      borderRight: 'var(--border-secondary)',
      borderLeft: 'var(--border-secondary)',
      boxSizing: 'border-box',
      overflowY: 'auto',
    }}
  >
    <Flex
      height="100%"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
      dataTestid="session-sidebar"
    >
      {children}
    </Flex>
  </Box>
);
