import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { ICoachingTool } from 'features/coachingTool';
import { IPlanningDraftSprint } from 'features/planning';

export const useAddPlanningCoachingToolMutation = () => {
  const queryClient = useQueryClient();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ toolId, sprintId }: { toolId: string; sprintId: string }) =>
      request({
        url: `/api/web/sprint-planning/coaching-tools/add`,
        body: { toolId, sprintId },
      }),
    {
      onMutate: async ({ toolId, sprintId }) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        const coachingTools = queryClient.getQueryData<{
          items: ICoachingTool[];
        }>([queryKeys.coachingTools]);

        const coachingTool = coachingTools!.items.find(
          ({ id }) => id === toolId
        );

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => {
              const updatedSprints = [...oldVal!.items];

              const sprintIndex = updatedSprints.findIndex(
                (sprint) => sprint.sprintId === sprintId
              );

              updatedSprints[sprintIndex].coachingTools.push(coachingTool!);

              return { ...oldVal, items: updatedSprints };
            }
          );
        }

        return { previousDraftSprintsValue };
      },
      onError: (
        err: Error,
        __: any,
        context:
          | {
              previousDraftSprintsValue?: {
                items: IPlanningDraftSprint[];
              };
            }
          | undefined
      ) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints],
            context.previousDraftSprintsValue
          );
        }
        logError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.draftSprints]);
      },
    }
  );
};
