export const ANALYTICS_EVENTS = {
  START_SESSION_RECAP: 'start_session_recap',
  FINISH_SESSION_RECAP: 'finish_session_recap',
  SCHEDULE_SESSION_RECAP: 'schedule_session_recap',
  CHALLENGE_ADDED: 'challenge_added',
  CHALLENGE_ADDED_FROM_LIB: 'challenge_added_from_library',
  CHALLENGE_EDITED: 'challenge_edited',
  CHALLENGE_MARKED: 'challenge_marked',
  CHALLENGE_REMOVED: 'challenge_removed',
  CHAT_MESSAGE_SENT: 'chat_message_sent',
  CHAT_OVERLAY_OPEN: 'chat_overlay_open',
  CHAT_REACTION_ADDED: 'chat_reaction_added',
  FREQUENCY_SELECTED: 'frequency_selected',
  HISTORY_SIDEBAR_OPEN: 'history_sidebar_open',
  NOTIFICATIONS_SIDEBAR_OPEN: 'notifications_sidebar_opened',
  NOTIFICATIONS_SIDEBAR_FILTER_APPLIED: 'notifications_sidebar_filter_applied',
  SPRINT_WITH_COACH_CHANGED: 'sprint_with_coach_changed',
  SPRINT_STARTED: 'sprint_started',
  SPRINT_RESTARTED: 'sprint_restarted',
  SPRINT_FINISHED: 'sprint_finished',
  SPRINT_REFLECTION_ANSWER_EDITED: 'sprint_reflection_answer_edited',
  TARGET_SELECTED: 'target_selected',
} as const;

export type AnalyticsEvent =
  typeof ANALYTICS_EVENTS[keyof typeof ANALYTICS_EVENTS];

export const ANALYTICS_EVENTS_SOURCES = {
  PARTICIPANT_PAGE: 'participant_page',
  PARTICIPANTS_LIST: 'participants_list',
} as const;

export type AnalyticsEventSource =
  typeof ANALYTICS_EVENTS_SOURCES[keyof typeof ANALYTICS_EVENTS_SOURCES];
