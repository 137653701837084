import Linkify from 'linkify-react';

import Box from 'shared_DEPRECATED/components/Box';
import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SessionsFormCoachingToolsSection } from 'features/sessions/components/Form/CoachingTools/Section';
import { SessionsMiroIframe } from 'features/sessions/components/MiroIframe';
import { getMiroBoardId } from 'features/sessions/utils';

import { Typography } from 'shared/components/Typography';

export const NextSessionToolsLink = ({ link }: { link: string }) => {
  const miroBoardId = getMiroBoardId(link);
  return (
    <>
      {link && (
        <FlexItem alignSelf="stretch">
          <Spacer size="md zr">
            <Typography type="small" color="gray" fontWeight="semiBold">
              Tool description link
            </Typography>
          </Spacer>
          {miroBoardId ? (
            <SessionsFormCoachingToolsSection>
              <Box style={{ height: '14rem' }}>
                <SessionsMiroIframe boardId={miroBoardId} />
              </Box>
              <Spacer size="sm zr" />
            </SessionsFormCoachingToolsSection>
          ) : (
            <Linkify options={{ target: '_blank' }}>
              <Typography type="small" wordBreak="break-word" color="black">
                {link}
              </Typography>
            </Linkify>
          )}
        </FlexItem>
      )}
    </>
  );
};
