import {
  PlanningSprintModel,
  PlanningBoardColumn,
  PlanningSprint,
} from 'features/planning';

export const PlanningActiveFinishedSprints = ({
  sprints,
}: {
  sprints: PlanningSprintModel[];
}) => (
  <>
    {sprints?.map((sprint: PlanningSprintModel) => (
      <PlanningBoardColumn
        key={sprint.sprintId}
        shouldScrollIntoView={sprint.sprintId === sprints.at(-1)?.sprintId}
      >
        <PlanningSprint sprint={sprint} />
      </PlanningBoardColumn>
    ))}
  </>
);
