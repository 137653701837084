import { useCallback, useRef } from 'react';
import ReactPlayer from 'react-player';

import Box from 'shared_DEPRECATED/components/Box';
import { Video } from 'shared_DEPRECATED/components/Video/Video';

import { ChallengeNotesCardVideoModal } from 'features/challengeNotes/components/Card/VideoModal';

import { SIZES, modal } from 'shared/components/Modal';

export const ChallengeNotesCardVideoAttachment = ({ src }: { src: string }) => {
  const videoRef = useRef<ReactPlayer>(null);

  const handleVideoClick = useCallback(
    (src) =>
      modal.open({
        content: <ChallengeNotesCardVideoModal src={src} />,
        props: {
          title: '',
          size: SIZES.SM,
        },
      }),
    []
  );

  if (!src) {
    return null;
  }

  return (
    <Box
      style={{
        width: '8rem',
        height: '8rem',
        overflow: 'hidden',
        borderRadius: '0.5rem',
      }}
    >
      <Video
        ref={videoRef}
        light={true}
        onClickPreview={() => {
          videoRef.current?.showPreview();
          handleVideoClick(src);
        }}
        height="100%"
        src={src}
        data-testId="challenge-notes-card-video"
      />
    </Box>
  );
};
