// should be moved to a separate feature folder e.g. upload
import { useMutation, MutationOptions } from 'react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

interface UploadData {
  file: File;
  key: string;
  presignedUrl: string;
}

const useUploadFileMutation = (
  options: MutationOptions<any, unknown, UploadData, unknown> = {}
) => {
  const { request: requestWithoutAuth } = useMutationHTTPRequest({
    isAuthRequired: false,
  });

  return useMutation(
    async ({ file, key, presignedUrl }: UploadData) =>
      await requestWithoutAuth({
        url: presignedUrl,
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'object-key': key,
        },
        isHosApi: false,
      }),
    options
  );
};

export default useUploadFileMutation;
