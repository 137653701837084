import { useFieldArray, useFormContext } from 'react-hook-form';

import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import Button from 'shared_DEPRECATED/components/Button/Button';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  SessionSidebarSection,
  SessionSidebarSectionBody,
  SessionSidebarSectionHeader,
} from 'features/sessions/components';
import { NextSessionCoachingToolsCard } from 'features/sessions/components/NextSessionForm/CoachingToolsCard';
import { defaultCoachingTool } from 'features/sessions/config';

export const NextSessionCoachingToolsSection = () => {
  const { getValues } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey') || '';

  const {
    fields: coachingTools,
    append,
    remove,
    update,
  } = useFieldArray({
    name: `${fieldKey}.coachingTools`,
  });

  const handleCoachingToolsDelete = (index: number) => {
    if (coachingTools.length === 1) {
      update(index, defaultCoachingTool);
    } else {
      remove(index);
    }
  };

  return (
    <SessionSidebarSection>
      <SessionSidebarSectionHeader title="Next session coaching tools">
        <Button
          variant={VARIANTS.TERTIARY}
          onClick={() => append(defaultCoachingTool)}
        >
          <Icon name={iconNames.plus} />
        </Button>
      </SessionSidebarSectionHeader>
      <SessionSidebarSectionBody>
        {coachingTools.map((coachingTool, index) => (
          <NextSessionCoachingToolsCard
            key={coachingTool.id}
            onDelete={() => handleCoachingToolsDelete(index)}
            formKey={`${fieldKey}.coachingTools.${index}`}
            canBeDeleted={coachingTools.length > 1}
          />
        ))}
      </SessionSidebarSectionBody>
    </SessionSidebarSection>
  );
};
