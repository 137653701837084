import PropTypes from 'prop-types';

import Button from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { noop } from 'shared_DEPRECATED/utils/function';

import { Typography } from 'shared/components/Typography';

import { useTabContext } from './context';

const TabPropTypes = {
  /**
   * Tab content.
   */
  children: PropTypes.node.isRequired,
  /**
   * Function to call when the tab is clicked.
   */
  onClick: PropTypes.func,
  dataTestid: PropTypes.string,
};

const Tab = ({ children, onClick = noop, dataTestid }) => {
  const { isSelected, onClick: onTabClick, ...tabAttributes } = useTabContext();

  return (
    <Spacer size="zr md zr zr">
      <Spacer size="zr xsm zr zr">
        <Button
          role="tab"
          dataTestid={dataTestid}
          {...tabAttributes}
          onClick={() => {
            onTabClick();
            onClick();
          }}
        >
          <Spacer size="md zr">
            <Spacer size="sm zr">
              <Typography
                color={isSelected ? 'black' : 'gray'}
                fontWeight="medium"
                type="meta"
              >
                {children}
              </Typography>
            </Spacer>
          </Spacer>
        </Button>
      </Spacer>
    </Spacer>
  );
};

Tab.propTypes = TabPropTypes;

export default Tab;
