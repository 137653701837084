import Box from 'shared_DEPRECATED/components/Box';
import {
  FileDownloadButton,
  UploadedFile,
} from 'shared_DEPRECATED/components/File';
import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { AttachmentsType } from 'features/challengeNotes/config/propTypes';

export const ChallengeNotesCardRecapAttachments = ({ attachments }) => (
  <FlexItem width="100%">
    <Box
      style={{
        width: ' 100%',
        display: 'grid',
        gridTemplateColumns: `repeat(2, 1fr)`,
        gap: 'var(--spacing02)',
      }}
    >
      {attachments.map(({ key, url, name, contentType }) => (
        <UploadedFile key={key} url={url} name={name} contentType={contentType}>
          <FileDownloadButton url={url} name={name} />
        </UploadedFile>
      ))}
    </Box>
    <Spacer size="sm zr md" />
  </FlexItem>
);

ChallengeNotesCardRecapAttachments.propTypes = { attachments: AttachmentsType };
