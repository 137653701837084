import { useMemo } from 'react';
import { useFilters, useSortBy, useTable } from 'react-table';

import { coachSessionsTableColumnsData } from 'features/sessions/config/coachTableColumnsData';
import { SessionListItemType } from 'features/sessions/config/types';
import { getSessionsTableColumnsData } from 'features/sessions/utils';

const defaultSorting = [
  {
    id: 'startAt',
    desc: true,
  },
];

export const useCoachSessionsTableData = (sessions: SessionListItemType[]) => {
  const data = useMemo(() => getSessionsTableColumnsData(sessions), [sessions]);

  return useTable(
    {
      columns: coachSessionsTableColumnsData,
      data,
      initialState: { sortBy: defaultSorting },
    },
    useFilters,
    useSortBy
  );
};
