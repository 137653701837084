import { DrawerCloseButton } from '@chakra-ui/react';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

const SidebarMainHeaderCloseButton = () => (
  <DrawerCloseButton
    right="0px"
    left="10px"
    top="10px"
    data-testid="notes-sidebar-close-btn"
  >
    <Icon name={iconNames.chevronRight} />
  </DrawerCloseButton>
);

export default SidebarMainHeaderCloseButton;
