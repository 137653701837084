import PropTypes from 'prop-types';

import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils, getPictureUrl } from 'shared_DEPRECATED/utils';

import {
  SprintHistoryItem,
  SprintHistoryList,
  SprintHistorySection,
} from 'features/sprint/components/History';
import useSprintHistoryQuery from 'features/sprint/hooks/query/useSprintHistory';
import { groupBySprintWeek } from 'features/sprint/utils';

import { useSidebarContext } from 'shared/components/Sidebar/context';
import { Typography } from 'shared/components/Typography';

const SprintHistoryContent = ({ selectedSprintId }) => {
  const { sprints } = useSidebarContext();
  const { history, isFetching } = useSprintHistoryQuery({
    sprintId: selectedSprintId,
  });

  const selectedSprint = sprints?.find(
    (sprint) => sprint.sprintId === selectedSprintId
  );

  const sections = selectedSprint
    ? groupBySprintWeek(history, selectedSprint)
    : {};

  if (isFetching) {
    return <Loader />;
  }

  return Object.keys(sections).length === 0 ? (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <FlexItem>
        <Typography type="meta" color="gray" fontWeight="regular">
          No changes made to the sprint.
        </Typography>
      </FlexItem>
    </Flex>
  ) : (
    <Spacer size="lg zr zr">
      <SprintHistoryList>
        {Object.entries(sections).map(
          ([sectionTitle, sprintChanges], index) => (
            <SprintHistorySection
              key={sectionTitle}
              index={index}
              title={sectionTitle}
            >
              {sprintChanges.map((sprintChange) => (
                <SprintHistoryItem
                  key={sprintChange.recordId}
                  date={dateUtils.todayTimeAgoOrDate(sprintChange.recordedAt)}
                  userEmail={sprintChange.editorEmail}
                  userName={sprintChange.editorName}
                  userPictureUrl={getPictureUrl(sprintChange.editorPicture)}
                  challengeTitle={sprintChange.challengeTitle}
                  before={sprintChange.before}
                  after={sprintChange.after}
                  type={sprintChange.type}
                />
              ))}
            </SprintHistorySection>
          )
        )}
      </SprintHistoryList>
    </Spacer>
  );
};

SprintHistoryContent.propTypes = {
  selectedSprintId: PropTypes.string.isRequired,
};

export default SprintHistoryContent;
