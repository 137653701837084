import { useTab, UseTabProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { Button, Box } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const PlanningBacklogHeaderTab = forwardRef<
  HTMLButtonElement,
  UseTabProps
>((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const { children } = tabProps;

  const isSelected = !!tabProps['aria-selected'];

  return (
    <Flex flexDirection="column">
      <Button size="sm" {...tabProps} _focus={{ borderColor: 'transparent' }}>
        <Typography
          color={isSelected ? 'black' : 'gray'}
          fontWeight="semiBold"
          type="meta"
        >
          {children}
        </Typography>
      </Button>
      <Spacer size="xsm" />
      <Box
        style={{
          borderBottom: isSelected ? '2px solid var(--fgInteractive)' : 'none',
          width: '100%',
        }}
      />
    </Flex>
  );
});
