import { Box, Typography, Button } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { iconNames, Icon } from 'shared_DEPRECATED/components/Icon';

import {
  IPlanningDraftSprint,
  useMovePlanningCoachingToolMutation,
} from 'features/planning';

import { Dropdown } from 'shared/components/Dropdown';

type TCoachingToolCardMenuMoveToSprint = {
  sprints: IPlanningDraftSprint[];
  sprintId: string;
  toolId: string;
};

export const CoachingToolCardMenuMoveToSprint = ({
  sprints,
  toolId,
  sprintId,
}: TCoachingToolCardMenuMoveToSprint) => {
  const { mutateAsync: moveCoachingTool } =
    useMovePlanningCoachingToolMutation();

  const handleCoachingToolMove = async (toSprintId: string) =>
    await moveCoachingTool({
      toolId,
      fromSprintId: sprintId,
      toSprintId,
    });

  return (
    <Dropdown
      anchorContent={
        <Flex width="100%" justifyContent="space-between">
          <Typography type="body" color="black">
            Move to
          </Typography>
          <Icon name={iconNames.arrowRight} />
        </Flex>
      }
      anchorSpacing={['zr md', 'md']}
      placement="right-start"
      withChevronIcon={false}
    >
      <Box style={{ width: '100%', overflow: 'auto' }} role="listbox">
        <Flex alignItems="stretch" flexDirection="column">
          {sprints.map(({ sprintId: toSprintId, title }) => (
            <Button
              key={sprintId}
              _focus={{ outline: 'none' }}
              onClick={() => handleCoachingToolMove(toSprintId)}
              width="100%"
            >
              <Typography type="body" color="black">
                {title}
              </Typography>
            </Button>
          ))}
        </Flex>
      </Box>
    </Dropdown>
  );
};
