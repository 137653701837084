import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'shared_DEPRECATED/components/Tabs';

import { EXPLORE_DIALOG_TABS } from 'features/challenge/config';
import {
  ExploreDialogTabLibraryPanelContent,
  useExploreDialogContentContext,
  useChallengeTabsContext,
  ExploreDialogTabPanelContent,
} from 'features/planning';

export const ExploreDialogTabs = () => {
  const { setActiveTab } = useChallengeTabsContext();
  const { areMyChallenges } = useExploreDialogContentContext();

  return (
    <Tabs id="challenge-tabs">
      <TabList>
        <Tab onClick={() => setActiveTab(EXPLORE_DIALOG_TABS.CURATED)}>
          hOS challenges
        </Tab>
        {areMyChallenges && (
          <Tab onClick={() => setActiveTab(EXPLORE_DIALOG_TABS.CUSTOM)}>
            Custom challenges
          </Tab>
        )}
      </TabList>
      <TabPanel>
        <ExploreDialogTabLibraryPanelContent />
      </TabPanel>
      {areMyChallenges && (
        <TabPanel>
          <ExploreDialogTabPanelContent />
        </TabPanel>
      )}
    </Tabs>
  );
};
