import { ReactNode } from 'react';

import { PinnedColumnsProvider } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  PlanningBacklogFilterProvider,
  SelectedGoalsProvider,
} from 'features/planning';

type TPlanningWrapper = {
  children: ReactNode;
};

export const PlanningWrapper = ({ children }: TPlanningWrapper) => (
  <PinnedColumnsProvider>
    <Box
      as="article"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Spacer size="lg" height="100%">
        <Spacer size="md" height="100%">
          <Flex
            flexDirection="column"
            alignItems="stretch"
            justifyContent="flex-start"
            height="100%"
          >
            <SelectedGoalsProvider>
              <PlanningBacklogFilterProvider>
                {children}
              </PlanningBacklogFilterProvider>
            </SelectedGoalsProvider>
          </Flex>
        </Spacer>
      </Spacer>
    </Box>
  </PinnedColumnsProvider>
);
