import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  PERIODS_FOR_ACHIEVEMENT,
  IGoal,
  ColumnsType,
  handleMoveToDifferentParent,
  handleMoveWithinParent,
} from 'features/goal';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

type TMapMoveGoalMutationPostData = {
  columnIndex: number;
  index: number;
  goalId: string;
};

const mapMoveGoalMutationPostData = ({
  columnIndex,
  index,
  goalId,
}: TMapMoveGoalMutationPostData) => ({
  columnId: Object.values(PERIODS_FOR_ACHIEVEMENT)[columnIndex],
  index,
  goalId,
});

export const useMoveGoalMutation = () => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();
  const { userId } = useCurrentModeUser();

  return useMutation(
    ({
      goalId,
      dropZonePath,
    }: IGoal & { path: number[]; dropZonePath: number[] }) => {
      const columnIndex = dropZonePath[0];
      const index = dropZonePath[1];

      return request({
        url: `/api/web/roadmaps/${userId}/move-goal`,
        body: mapMoveGoalMutationPostData({ goalId, columnIndex, index }),
      });
    },
    {
      onMutate: async ({ dropZonePath, ...item }) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goals, userId],
        });

        const previousLayout = queryClient.getQueryData([
          queryKeys.goals,
          userId,
        ]);

        queryClient.setQueryData<{ columns: ColumnsType }>(
          [queryKeys.goals, userId],
          (oldVal) => {
            const isItemAtTheSameColumnAsDropZone =
              item.path[0] === dropZonePath[0];

            let updatedColumns;

            if (isItemAtTheSameColumnAsDropZone) {
              updatedColumns = handleMoveWithinParent(
                oldVal!.columns,
                dropZonePath,
                item.path,
                'goals'
              );
            } else {
              updatedColumns = handleMoveToDifferentParent(
                oldVal!.columns,
                dropZonePath,
                item.path,
                //@ts-ignore
                item,
                'goals'
              );
            }

            return {
              ...oldVal,
              columns: updatedColumns,
            };
          }
        );

        return { previousLayout };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [queryKeys.goals, userId],
          context?.previousLayout
        );
        logError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.goals, userId]);
      },
    }
  );
};
