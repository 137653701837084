import { UseFormReturn } from 'react-hook-form';

import { Sprint } from 'features/sprint';
import { IDraftSprint, ISprint } from 'features/sprint/config/types';
import { useCreateDraftSprintInstanceWithWeeksCount } from 'features/sprint/hooks/useCreateDraftSprintInstanceWithWeeksCount';

import { useIsDraftSprintPage } from './useIsDraftSprintPage';

export const useSprintFormData = (
  sprintFormMethods: UseFormReturn<IDraftSprint | ISprint>
) => {
  const isDraftSprintPage = useIsDraftSprintPage();
  const sprintFormData = sprintFormMethods.watch();
  const createDraftSprintWithWeeksCount =
    useCreateDraftSprintInstanceWithWeeksCount();

  return isDraftSprintPage
    ? createDraftSprintWithWeeksCount(sprintFormData as IDraftSprint)
    : new Sprint(sprintFormData as ISprint);
};
