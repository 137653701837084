export const FORM_TEXT_INPUT_LIMIT = 64;

export const FORM_FOCUS_AREA_NAME_INPUT_LIMIT = 100;

export const VISIBLE_DIMENSIONS_COUNT = 2;

export const GOAL_DEADLINE_DATE_PLACEHOLDER = 'Set deadline';
export const GOAL_DEADLINE_DATE_FORMAT = 'd MMM, yyyy';

export const GOAL_DEEP_DIVE_DEFAULT_VALUE = {
  why: '',
  qualitiesAndSkills: '',
  obstacles: '',
  dealingWithObstacles: '',
  support: '',
  updatedAt: '',
};

export const GOAL_DEFAULT_VALUE = {
  name: '',
  columnId: 'backlog',
  columnLabel: 'backlog',
  goalId: '',
  deadline: null,
  dimensions: [],
  deepDive: GOAL_DEEP_DIVE_DEFAULT_VALUE,
  deepDiveAvailable: true,
  completedAt: null,
  cover: null,
  note: '',
};

export const PERIODS_FOR_ACHIEVEMENT = {
  BACKLOG: 'backlog',
  ZERO_TO_ONE_YEARS: '0_1_years',
  ONE_TO_THREE_YEARS: '1_3_years',
  THREE_TO_FIVE_YEARS: '3_5_years',
  FIVE_TO_TEN_YEARS: '5_10_years',
  TEN_PLUS_YEARS: '10_plus_years',
};

export const COLUMN_HEADER_LABELS = {
  [PERIODS_FOR_ACHIEVEMENT.BACKLOG]: 'Backlog',
  [PERIODS_FOR_ACHIEVEMENT.ZERO_TO_ONE_YEARS]: '0-1',
  [PERIODS_FOR_ACHIEVEMENT.ONE_TO_THREE_YEARS]: '1-3',
  [PERIODS_FOR_ACHIEVEMENT.THREE_TO_FIVE_YEARS]: '3-5',
  [PERIODS_FOR_ACHIEVEMENT.FIVE_TO_TEN_YEARS]: '5-10',
  [PERIODS_FOR_ACHIEVEMENT.TEN_PLUS_YEARS]: '10+',
};

export const GOAL_ACCEPTS_TYPE = 'goal';
export const ACCEPTS = [GOAL_ACCEPTS_TYPE];
