import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import { ChallengeInfo } from 'features/challenge/components/Info';
import { useExploreChallengesAddChallengeModal } from 'features/planning';
import { addChallengeSource, ChallengeType } from 'features/sprint/utils';

import { Typography } from 'shared/components/Typography';

export const ExploreDialogChallengeInfo = ({ challenge }) => {
  const onAddChallengeBtnClick = useExploreChallengesAddChallengeModal({
    initialChallenge: addChallengeSource(challenge),
    title: 'Add Challenge',
  });

  return (
    <Button onClick={onAddChallengeBtnClick} variant="tertiary">
      <Spacer size="md">
        <Flex justifyContent="start">
          <Spacers sizes={['zr lg zr zr', 'zr sm zr zr']}>
            <ChallengeInfo
              emoji={challenge.emoji}
              pictureUrl={getPictureUrl(challenge.picture)}
              title={challenge.title}
            >
              <Typography as="p" color="gray" type="small" fontWeight="regular">
                {challenge.dimensions.join(', ')}
              </Typography>
            </ChallengeInfo>
          </Spacers>
        </Flex>
      </Spacer>
    </Button>
  );
};

ExploreDialogChallengeInfo.propTypes = {
  challenge: ChallengeType,
};
