import { SPRINT_STATES } from 'features/sprint/config';

export const DEFAULT_DRAFT_SPRINTS_COUNT = 3;

export const UNLINKED_CHALLENGES_VALUE = 'unlinked_challenges';

export const DESELECT_ALL_GOALS_LABEL = 'Deselect all';
export const SELECT_ALL_GOALS_LABEL = 'Select all';

export const UNLINKED_CHALLENGES_CONFIG = {
  label: 'Other challenges',
  value: UNLINKED_CHALLENGES_VALUE,
};

export const BOARD_COLUMN_WIDTH = '23rem';

export const PLANNING_SPRINT_BACKGROUND_STYLES_MAP = {
  [SPRINT_STATES.ACTIVE]: 'var(--bgCompMinorSuccess)',
  [SPRINT_STATES.FINISHED]: 'var(--bgCompMinorAccent)',
};
