import { lazy, Suspense, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { modal, SIZES } from 'shared/components/Modal';

const PlanningDraftSprintsDeleteDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/planning/components/DraftSprint/DeleteDialog'),
    'PlanningDraftSprintsDeleteDialog'
  )
);

export const useOpenPlanningDeleteDraftSprintDialog = () =>
  useCallback(({ onSubmit }) => {
    modal.open({
      content: (
        <Suspense fallback={<Loader size="sm" />}>
          <PlanningDraftSprintsDeleteDialogContent onSubmit={onSubmit} />
        </Suspense>
      ),
      props: {
        size: SIZES.MD,
      },
    });
  }, []);
