import Linkify from 'linkify-react';
import PropTypes from 'prop-types';

import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { emailPropValidator } from 'shared_DEPRECATED/utils/propsValidators';

import {
  ChallengeNotesCardContainer,
  ChallengeNotesCardFooter,
} from 'features/challengeNotes/components/Card';
import { ChallengeNotesRepliesCardHeader } from 'features/challengeNotes/components/Replies/Card/Header';
import { useUserContext } from 'features/user/context';

import { Typography } from 'shared/components/Typography';

const ChallengeNoteReplyPropTypes = {
  pictureUrl: PropTypes.string,
  userEmail: emailPropValidator,
  userName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  setEditedReplyId: PropTypes.func.isRequired,
  replyId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export const ChallengeNotesReply = ({
  pictureUrl,
  userEmail,
  userName,
  date,
  text,
  setEditedReplyId,
  replyId,
  children,
}) => {
  const { user } = useUserContext();
  const isAuthor = userEmail === user.email;

  return (
    <ChallengeNotesCardContainer
      title="Challenge note reply"
      dataTestid="user-user-challenge-note-reply-input-card"
    >
      <ChallengeNotesRepliesCardHeader
        name={userName || userEmail}
        pictureUrl={pictureUrl}
        date={date}
        isAuthor={isAuthor}
        setEditedReplyId={setEditedReplyId}
        replyId={replyId}
      />
      <Spacer size="zr zr zr lg">
        <Spacer size="md zr zr lg">
          <Spacer size="sm zr zr sm">
            <HotjarSuppression>
              <Typography
                type="small"
                color="black"
                dataTestid="user-user-challenge-note-reply-input-text"
              >
                <Linkify options={{ target: '_blank' }}>{text}</Linkify>
              </Typography>
            </HotjarSuppression>
          </Spacer>
        </Spacer>
      </Spacer>
      <ChallengeNotesCardFooter>{children}</ChallengeNotesCardFooter>
    </ChallengeNotesCardContainer>
  );
};

ChallengeNotesReply.propTypes = ChallengeNoteReplyPropTypes;
