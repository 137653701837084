import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { UseMutateAsyncFunction } from 'react-query';

import { Nullable } from 'shared_DEPRECATED/types';

type TUsePlanningBoardDrop<T> = {
  onDrop: UseMutateAsyncFunction<any, unknown, T, { previousValue: unknown }>;
  accept: string[];
  handleCanDrop: (item: T) => boolean;
  dropZoneIndex: number;
  dropZoneSprintId?: string;
  isDropzoneInBacklog: boolean;
  lowerChallengeId: Nullable<string>;
};

export const usePlanningBoardDrop = <T,>({
  onDrop,
  accept,
  handleCanDrop,
  dropZoneIndex,
  dropZoneSprintId,
  isDropzoneInBacklog,
  lowerChallengeId,
}: TUsePlanningBoardDrop<T>) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: useCallback(
      async (item) =>
        await onDrop({
          ...item,
          index: dropZoneIndex,
          toSprintId: dropZoneSprintId,
          toBacklog: isDropzoneInBacklog,
          lowerChallengeId,
        }),
      [
        onDrop,
        dropZoneIndex,
        dropZoneSprintId,
        isDropzoneInBacklog,
        lowerChallengeId,
      ]
    ),
    canDrop: handleCanDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return { drop, isOver, canDrop };
};
