import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

type TFeedbackButton = {
  openFeedbackDialog: () => void;
};

export const FeedbackButton = ({ openFeedbackDialog }: TFeedbackButton) => {
  return (
    <Box
      style={{
        position: 'fixed',
        top: '50%',
        right: '-1px',
        zIndex: 3,
        backgroundColor: 'var(--bgCompPrimaryInverse)',
        borderRadius: '0.5rem 0.5rem 0 0',
        transform: 'rotate(-90deg) translateX(50%)',
        transformOrigin: 'bottom right',
      }}
    >
      <Button onClick={openFeedbackDialog}>
        <Spacer size="sm md xsm">
          <Typography color="white">Feedback</Typography>
        </Spacer>
      </Button>
    </Box>
  );
};
