import { IconButton } from '@chakra-ui/react';

import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  VARIANTS,
  Badge,
  Typography,
  ButtonGroup,
} from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  DESELECT_ALL_GOALS_LABEL,
  PlanningFilterButton,
  SELECT_ALL_GOALS_LABEL,
  UNLINKED_CHALLENGES_CONFIG,
  UNLINKED_CHALLENGES_VALUE,
  useSelectedGoalsContext,
} from 'features/planning';

export const PlanningFilter = () => {
  const {
    goals,
    selectedGoalsIds,
    selectAllGoals,
    deselectAllGoals,
    updateSelectedGoals,
    isLoading,
  } = useSelectedGoalsContext();

  const isGoalsSelected =
    selectedGoalsIds.length === [...goals, UNLINKED_CHALLENGES_VALUE].length;

  const isNoGoalsSelected = !selectedGoalsIds.length;

  if (isLoading) {
    return null;
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={IconButton}
        aria-label="Filter"
        icon={<Icon name={iconNames.controls} height="14" width="14" />}
        variant={VARIANTS.TERTIARY}
        position="relative"
      >
        <Box
          style={{
            zIndex: 2,
            position: 'absolute',
            bottom: '55%',
            left: '55%',
          }}
        >
          {!!selectedGoalsIds.length && (
            <Badge bgColor="blue" size="xsm">
              <Typography color="whiteTotal" type="small">
                {selectedGoalsIds.length}
              </Typography>
            </Badge>
          )}
        </Box>
      </MenuButton>
      <MenuList>
        <Spacer size="md lg">
          <ButtonGroup gap="0.5rem" size="xs">
            <PlanningFilterButton
              onClick={selectAllGoals}
              disabled={isGoalsSelected}
              label={SELECT_ALL_GOALS_LABEL}
            />
            <PlanningFilterButton
              onClick={deselectAllGoals}
              disabled={isNoGoalsSelected}
              label={DESELECT_ALL_GOALS_LABEL}
            />
          </ButtonGroup>
        </Spacer>
        <Box style={{ maxHeight: '12rem', width: '100%', overflow: 'scroll' }}>
          <MenuOptionGroup
            // @ts-ignore
            onChange={(selectedOptions: string[]) =>
              updateSelectedGoals(selectedOptions)
            }
            defaultValue={selectedGoalsIds}
            type="checkbox"
            value={selectedGoalsIds}
          >
            <MenuItemOption value={UNLINKED_CHALLENGES_CONFIG.value}>
              {UNLINKED_CHALLENGES_CONFIG.label}
            </MenuItemOption>
            {goals.map(({ goalId, title }) => (
              <MenuItemOption key={goalId} value={goalId}>
                {title}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </Box>
      </MenuList>
    </Menu>
  );
};
