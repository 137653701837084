import { BoxProps } from '@chakra-ui/react';

import { Box } from 'shared';

type TBoardColumnHeader = BoxProps;

export const BoardColumnHeader = ({
  children,
  minHeight = 'auto',
}: TBoardColumnHeader) => (
  <Box padding="8px" width="100%" minHeight={minHeight}>
    {children}
  </Box>
);
