import PropTypes from 'prop-types';

import {
  Filter,
  FilterItem,
  FilterSection,
} from 'shared_DEPRECATED/components/Filter';

import {
  NOTE_FILTERS,
  NOTE_FILTERS_COMPONENT_CONFIG,
  NOTE_FILTERS_DEFAULT_VALUE,
  NOTE_STATUS,
} from 'features/challengeNotes/config';
import { useChallengeNotesFilterContext } from 'features/challengeNotes/context';
import { useFilterVisibility } from 'features/challengeNotes/hooks';

import { useSidebarContext } from 'shared/components/Sidebar/context';
import { Typography } from 'shared/components/Typography';

const ChallengeNotesFilterPropTypes = {
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    filterReadStatuses: PropTypes.string,
    filterRepliedByEmails: PropTypes.string,
  }),
};

export const ChallengeNotesFilter = () => {
  const { filterActive, filter, setFilter } = useChallengeNotesFilterContext();
  const { user } = useSidebarContext();
  const filterHidden = useFilterVisibility();

  const filterValueMapper = {
    [NOTE_FILTERS.FILTER_READ_STATUSES]: NOTE_STATUS.UNREAD,
    [NOTE_FILTERS.FILTER_REPLIED_BY_EMAILS]: user.email,
  };

  if (filterHidden) {
    return null;
  }

  const onFilterToggle = ({ filterKey, value }) => {
    setFilter({
      [filterKey]: filter[filterKey]
        ? NOTE_FILTERS_DEFAULT_VALUE[filterKey]
        : value,
    });
  };

  return (
    <Filter isActive={filterActive}>
      <FilterSection>
        {Object.entries(NOTE_FILTERS_COMPONENT_CONFIG).map(
          ([filterKey, config]) => {
            const { label, testid } = config;

            return (
              <FilterItem
                key={filterKey}
                active={!!filter[filterKey]}
                onChange={() =>
                  onFilterToggle({
                    filterKey,
                    value: filterValueMapper[filterKey],
                  })
                }
                dataTestid={`notes-sidebar-filter-${testid}`}
              >
                <Typography color="black" type="small">
                  {label}
                </Typography>
              </FilterItem>
            );
          }
        )}
      </FilterSection>
    </Filter>
  );
};

ChallengeNotesFilter.propTypes = ChallengeNotesFilterPropTypes;
