import { NonRepeatedChallenge } from 'features/challenge/NonRepeatedChallenge';
import { RepeatedChallenge } from 'features/challenge/RepeatedChallenge';
import {
  IRepeatedChallenge,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';
import { ICoachingTool } from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import { FinalSprint } from 'features/sprint';
import { SPRINT_TYPES } from 'features/sprint/config';
import { IFinalSprint, ISprint } from 'features/sprint/config/types';

export interface IPlanningSprint extends Omit<ISprint, 'oneTimeChallenges'> {}

class PlanningSprint extends FinalSprint implements IFinalSprint {
  challenges: (IRepeatedChallenge | INonRepeatedChallenge)[];
  readonly type: typeof SPRINT_TYPES[keyof typeof SPRINT_TYPES];
  coachingTools: ICoachingTool[];

  constructor(sprint: IPlanningSprint) {
    super(sprint);

    this.challenges = sprint.challenges.map((challenge) =>
      this.isNonRepeatedChallenge(challenge)
        ? new NonRepeatedChallenge(challenge)
        : new RepeatedChallenge(challenge)
    );
    this.coachingTools = sprint.coachingTools.map(
      (coachingTool) => new CoachingTool(coachingTool)
    );
    this.type = sprint.type;
  }

  isNonRepeatedChallenge(
    challenge: INonRepeatedChallenge | IRepeatedChallenge
  ): challenge is INonRepeatedChallenge {
    return !!(challenge as INonRepeatedChallenge).target?.dueDate;
  }
}

export { PlanningSprint as PlanningSprintModel };
