import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import { IChallenge } from 'features/challenge/config/types';
import { useEditCustomChallengeMutation } from 'features/challenge/hooks';

export const useEditCustomChallenge = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: editCustomChallenge } = useEditCustomChallengeMutation({
    options: {
      onMutate: async (updatedChallenge: IChallenge) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.challenges],
        });

        const previousValue = queryClient.getQueryData([queryKeys.challenges]);

        if (previousValue) {
          queryClient.setQueryData<{ items: IChallenge[] }>(
            [queryKeys.challenges],
            (oldVal) => ({
              ...oldVal,
              items: updateArrayById({
                array: oldVal!.items,
                updatedItem: { ...updatedChallenge, goals: [] },
                idProperty: 'challengeId',
              }),
            })
          );
        }

        return { previousValue };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.challenges]);
      },
      onError: (
        err: Error,
        _: any,
        context: { previousValue?: IChallenge[] }
      ) => {
        queryClient.setQueryData(
          [queryKeys.challenges],
          context?.previousValue
        );
      },
    },
  });

  return { editCustomChallenge };
};
