import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

export const useGoalBreakdownQuery = () => {
  const { goalId } = useParams();
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.goalBreakdown, goalId],
    () => request({ url: `/api/web/goals/${goalId}/breakdown` }),
    {
      enabled: !!goalId,
    }
  );
};
