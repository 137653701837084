import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useChallengeNotesFilterContext } from 'features/challengeNotes/context';
import { useChallengeNotesQuery } from 'features/challengeNotes/hooks';
import { useUnreadNotes } from 'features/challengeNotes/hooks/useUnreadNotes';

import { useSidebarContext } from 'shared/components/Sidebar/context';

const ChallengeNotesContext = React.createContext({
  notes: [],
  isFetching: false,
  unreadNotesCounterByType: {},
  noNotes: true,
});

export const ChallengeNotesProvider = ({ children }) => {
  const { filter } = useChallengeNotesFilterContext();
  const { sprint } = useSidebarContext();
  const { email } = useParams();
  const { refetch, notes, isFetching } = useChallengeNotesQuery({
    filterSprintIds: sprint.sprintId,
    userEmail: sprint.userEmail,
    ...filter,
  });

  const { unreadNotesCounter, unreadNotesCounterByType } = useUnreadNotes({
    participantId: email,
    sprintState: sprint.state,
  });

  const noNotes = notes.length === 0;

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  const providerValue = useMemo(
    () => ({
      notes,
      isFetching,
      unreadNotesCounter,
      unreadNotesCounterByType,
      noNotes,
    }),
    [notes, isFetching, unreadNotesCounter, unreadNotesCounterByType, noNotes]
  );

  return (
    <ChallengeNotesContext.Provider value={providerValue}>
      {children}
    </ChallengeNotesContext.Provider>
  );
};

ChallengeNotesProvider.propTypes = { children: PropTypes.node.isRequired };

export const useChallengeNotesContext = () => {
  const useChallengeNotesContext = useContext(ChallengeNotesContext);

  if (!useChallengeNotesContext) {
    throw new Error(
      'useChallengeNotesContext must be used in ChallengeNotesContext.Provider'
    );
  }

  return useChallengeNotesContext;
};
