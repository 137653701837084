import { Suspense, lazy, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { SIZES, modal } from 'shared/components/Modal';

const ChallengeDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/challenge/components/CreateDialog/DialogContent'),
    'ChallengeDialogContent'
  )
);

export const useOpenChallengeModal = () => {
  return useCallback(({ challenge, onChallengeSubmit, sprint, title }) => {
    modal.open({
      content: (
        <Suspense
          fallback={<Loader dataTestid="challenge-dialog-loader" size="sm" />}
        >
          <ChallengeDialogContent
            currentChallenge={challenge}
            onChallengeSubmit={onChallengeSubmit}
          />
        </Suspense>
      ),
      context: { sprint },
      props: {
        size: SIZES.MD,
        title: title || challenge.title,
      },
    });
  }, []);
};
