import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const ChallengeRemoved = ({ challengeTitle }) => (
  <Typography>
    Removed challenge{' '}
    <Box as="span" style={{ backgroundColor: 'var(--bgCompSecondaryMid)' }}>
      <Spacer as="span" size="zr zr zr sm" />
      <Typography as="strong" fontWeight="medium" color="black">
        {challengeTitle}
      </Typography>
      <Spacer as="span" size="zr sm zr zr" />
    </Box>
  </Typography>
);

ChallengeRemoved.propTypes = {
  challengeTitle: PropTypes.string.isRequired,
};

export default ChallengeRemoved;
