import { OptionsMenu } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  GoalMenuEditButton,
  GoalMenuDeleteButton,
  GoalCardType,
} from 'features/goal';

export const GoalCardMenu = ({
  goalId,
  columnId,
  name,
  dimensions,
  deadline,
  deepDive,
  columnLabel,
  completedAt,
  note,
}: GoalCardType) => {
  return (
    //@ts-ignore
    <OptionsMenu icon={iconNames.ellipsisHorizontal}>
      <GoalMenuEditButton
        goalId={goalId}
        name={name}
        columnId={columnId}
        deadline={deadline}
        dimensions={dimensions}
        deepDive={deepDive}
        columnLabel={columnLabel}
        completedAt={completedAt}
        note={note}
      />
      <GoalMenuDeleteButton goalId={goalId} columnId={columnId} />
    </OptionsMenu>
  );
};
