import { useOutletContext } from 'react-router-dom';

import { IDraftSprint } from 'features/sprint/config/types';
import { useSprintStartSubmit } from 'features/sprint/hooks';
import { useOpenStartSprintModal } from 'features/sprint/hooks/useOpenStartSprintModal';

const useSprintStart = () => {
  const { sprint } = useOutletContext<{ sprint: IDraftSprint }>();
  const { sprintId, ...sprintWithoutId } = sprint;

  const { onSubmit } = useSprintStartSubmit({
    sprint: sprintWithoutId,
  });

  const challengesCount = sprint.challengesCount;

  const isSprintReadyToStart = challengesCount > 0 && sprint.title;

  const openStartSprintModal = useOpenStartSprintModal();

  const onStartSprintClick = () =>
    openStartSprintModal({ onSubmit, title: sprint.title });

  return {
    isSprintReadyToStart,
    onStartSprintClick,
  };
};

export default useSprintStart;
