import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Image from 'shared_DEPRECATED/components/Image';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  useQuickStartSprintModal,
  useExploreDialogContentContext,
} from 'features/planning';

import { Typography } from 'shared/components/Typography';

export const ExploreDialogQuickStartSprintsList = () => {
  const { areQuickStartSprintsLoading, quickStartSprints } =
    useExploreDialogContentContext();
  const openQuickStartSprintModal = useQuickStartSprintModal();

  if (areQuickStartSprintsLoading) {
    return <Loader dataTestid="qss-list-loader" size="sm" />;
  }

  return quickStartSprints ? (
    <Flex
      as="ul"
      gap="1.5rem 0.75rem"
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {quickStartSprints.map(({ qssId, picture, title }) => (
        <li key={qssId}>
          <Button
            onClick={openQuickStartSprintModal({ qssId })}
            dataTestid="qss-name-button"
          >
            <Box
              as="div"
              style={{
                width: '11.75rem',
              }}
            >
              <Flex flexDirection="column" alignItems="start">
                <Image
                  src={picture.url}
                  width={188}
                  height={136}
                  borderRadius={5}
                />
                <Spacer size="xsm zr sm" />
                <Typography type="meta" color="black" dataTestid="qss-title">
                  {title}
                </Typography>
              </Flex>
            </Box>
          </Button>
        </li>
      ))}
    </Flex>
  ) : null;
};
