import {
  ChallengeTabsProvider,
  ExploreDialogContentProvider,
  ExploreDialogBody,
} from 'features/planning';

import { ModalHeader, ModalBody } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';

const ExploreDialogContent = () => (
  <>
    <ModalHeader>
      <Typography as="h3" type="body" fontWeight="semiBold" color="black">
        Explore Challenges
      </Typography>
    </ModalHeader>
    <ModalBody>
      <ChallengeTabsProvider>
        <ExploreDialogContentProvider>
          <ExploreDialogBody />
        </ExploreDialogContentProvider>
      </ChallengeTabsProvider>
    </ModalBody>
  </>
);

export default ExploreDialogContent;
