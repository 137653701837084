import { lazy, Suspense, useCallback } from 'react';

import { Button, Typography } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { modal, SIZES } from 'shared/components/Modal';

type TCoachingToolCardMenuRemoveButton = {
  toolId: string;
};

export const CoachingToolCardMenuOpenButton = ({
  toolId,
}: TCoachingToolCardMenuRemoveButton) => {
  const openCoachingToolModal = useCallback(() => {
    modal.open({
      content: (
        <Suspense fallback={<Loader size="sm" />}>
          <CoachingToolDialogContent toolId={toolId} />
        </Suspense>
      ),
      props: {
        size: SIZES.MD,
      },
    });
  }, [toolId]);

  return (
    <Button
      _focus={{ outline: 'none' }}
      width="100%"
      onClick={openCoachingToolModal}
    >
      <Typography type="body" color="black">
        Open tool details
      </Typography>
    </Button>
  );
};

const CoachingToolDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/coachingTool/components/Dialog'),
    'CoachingToolDialog'
  )
);
