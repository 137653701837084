import { OptionsMenu } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import { IChallenge } from 'features/challenge/config/types';
import {
  PlanningBacklogItemMenuEditButton,
  PlanningBacklogMenuRemoveButton,
} from 'features/planning';

type TPlanningBacklogItemMenu = {
  challenge: IChallenge;
};

export const PlanningBacklogMenu = ({
  challenge,
}: TPlanningBacklogItemMenu) => (
  <OptionsMenu
    //@ts-ignore
    icon={iconNames.ellipsisHorizontal}
  >
    <PlanningBacklogItemMenuEditButton challenge={challenge} />
    <PlanningBacklogMenuRemoveButton challengeId={challenge.challengeId!} />
  </OptionsMenu>
);
