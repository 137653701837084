import { ReactNode, useState } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';
import { Picture } from 'shared_DEPRECATED/types/Picture';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import { ChallengeInfo } from 'features/challenge/components/Info';
import { ChallengeGoalsType } from 'features/challenge/config/types';
import { LifeDimensions } from 'features/lifeDimensions/components/LifeDimensions';
import { PlanningChallengeGoals } from 'features/planning';

type TPlanningChallengeCard = {
  title: string;
  picture: Nullable<Picture>;
  emoji: Nullable<string>;
  dimensions: string[];
  children?: ReactNode;
  goals: ChallengeGoalsType;
};

export const PlanningChallengeCard = ({
  title,
  picture,
  emoji,
  dimensions,
  children,
  goals,
}: TPlanningChallengeCard) => {
  const [isChildrenAreVisible, setIsChildrenAreVisible] = useState(false);

  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: isChildrenAreVisible
          ? 'var(--bgCompSecondaryMid)'
          : 'var(--bgCompPrimary)',
        borderRadius: 'var(--border-radius)',
        position: 'relative',
      }}
      //@ts-ignore
      onMouseEnter={() => setIsChildrenAreVisible(true)}
      onMouseLeave={() => setIsChildrenAreVisible(false)}
      dataTestid="challenge-card"
    >
      <Spacer size="sm">
        <Flex width="100%">
          <Flex>
            <ChallengeInfo
              emoji={emoji}
              pictureUrl={getPictureUrl(picture)}
              title={title}
              isCropped={false}
            >
              <LifeDimensions
                visibleDimensionsCount={2}
                dimensions={dimensions}
              />
              {goals.length ? <PlanningChallengeGoals goals={goals} /> : null}
            </ChallengeInfo>
          </Flex>
          {isChildrenAreVisible && children}
        </Flex>
      </Spacer>
    </Box>
  );
};
