import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { removeFromArrayById } from 'shared_DEPRECATED/utils';

import { IPlanningDraftSprint } from 'features/planning';

type TUseDeletePlanningDraftSprint = {
  sprintId: string;
};

export const useDeletePlanningDraftSprint = ({
  sprintId,
}: TUseDeletePlanningDraftSprint) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      request({
        url: `/api/draft-sprints/${sprintId}/delete`,
        body: {},
      }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => ({
              ...oldVal,
              items: removeFromArrayById({
                array: oldVal!.items,
                id: sprintId,
                idProperty: 'sprintId',
              }),
            })
          );
        }

        return { previousDraftSprintsValue };
      },
      onError: (err, _, context) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints],
            context.previousDraftSprintsValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.draftSprints],
        });
      },
    }
  );
};
