import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';

import { IChallenge } from 'features/challenge/config/types';
import { useCreateCustomChallengeMutation } from 'features/challenge/hooks';
import { useCreateChallengeModal } from 'features/challenge/hooks/useCreateChallengeModal';
import { useCurrentSprint, useSprints } from 'features/sprint/hooks';

export const useAddCustomChallenge = () => {
  const { data: currentSprint } = useCurrentSprint();
  const { data: sprints } = useSprints();

  const sprint = currentSprint ?? sprints?.[0];

  const queryClient = useQueryClient();

  const { mutateAsync: addChallenge } = useCreateCustomChallengeMutation({
    options: {
      onMutate: async (challenge: IChallenge) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.challenges],
        });

        const previousValue = queryClient.getQueryData([queryKeys.challenges]);

        queryClient.setQueryData<{ items: IChallenge[] }>(
          [queryKeys.challenges],
          (oldVal) => ({
            ...oldVal,
            items: [{ ...challenge, goals: [] }, ...oldVal!.items],
          })
        );

        return { previousValue };
      },
      onError: (
        _: Error,
        __: any,
        context: { previousValue?: IChallenge[] }
      ) => {
        queryClient.setQueryData(
          [queryKeys.challenges],
          context?.previousValue
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.challenges]);
      },
    },
  });

  const openAddChallengeModal = useCreateChallengeModal({
    mutateFunc: addChallenge,
    sprint,
  });

  return openAddChallengeModal;
};
