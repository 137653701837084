import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

export const useQuickStartSprintQuery = ({ qssId }) => {
  const { request } = useQueryHTTPRequest();

  return useQuery([queryKeys.quickStartSprint, qssId], () =>
    request({ url: `/api/qss/${qssId}` })
  );
};
