import { getPictureUrl } from 'shared_DEPRECATED/utils';

import {
  ChallengeNotesCardHeader,
  ChallengeNotesCardUserInfo,
} from 'features/challengeNotes/components/Card';
import { ChallengeNotesCardRecapMenu } from 'features/challengeNotes/components/Card/Recap';
import { RecapNoteType } from 'features/challengeNotes/config/propTypes';

export const ChallengeNotesCardRecapHeader = ({ note }) => {
  return (
    <ChallengeNotesCardHeader>
      <ChallengeNotesCardUserInfo
        email={note.authorEmail}
        name={note.authorName || note.authorEmail}
        pictureUrl={getPictureUrl(note.authorPicture)}
        date={note.data.statusDate}
        noteReadStatus={note.status}
      />
      <ChallengeNotesCardRecapMenu
        userEmail={note.userEmail}
        noteId={note.noteId}
        noteReadStatus={note.status}
        coachingSessionId={note.data.coachingSessionId}
      />
    </ChallengeNotesCardHeader>
  );
};

ChallengeNotesCardRecapHeader.propTypes = { note: RecapNoteType };
