import { ButtonGroup, BoardColumnPinButton, Typography } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  PlanningDraftSprintDeleteButton,
  PlanningDraftSprintHeaderMenu,
  IPlanningDraftSprint,
} from 'features/planning';

type TPlanningDraftSprintHeader = {
  title: string;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintHeader = ({
  title,
  sprint,
}: TPlanningDraftSprintHeader) => (
  <Spacer size="sm md">
    <Flex dataTestid="sprint-planning-board-header">
      <Typography color="gray" fontWeight="semiBold" type="meta">
        {title}
      </Typography>
      <ButtonGroup spacing="0.5rem" size="sm">
        <BoardColumnPinButton columnId={sprint.sprintId} />
        <PlanningDraftSprintDeleteButton sprint={sprint} />
        <PlanningDraftSprintHeaderMenu sprint={sprint} />
      </ButtonGroup>
    </Flex>
  </Spacer>
);
