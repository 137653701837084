import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { PLANNING_SPRINT_BACKGROUND_STYLES_MAP } from 'features/planning';
import { SprintStateValues } from 'features/sprint/config/types';

import { Typography } from 'shared/components/Typography';

type TPlanningBoardItemsWrapper = {
  children: ReactNode;
  navigation?: ReactNode;
  sprintState?: SprintStateValues;
  itemsCount: number;
  type: 'challenge' | 'coaching tool';
};

export const PlanningBoardItemsWrapper = ({
  children,
  navigation,
  sprintState,
  type,
  itemsCount,
}: TPlanningBoardItemsWrapper) => {
  const title = getPlanningBoardItemsWrapperTitle(type, itemsCount);

  return (
    <Flex flexDirection="column" width="100%" alignItems="stretch">
      <Box
        style={{
          position: 'sticky',
          zIndex: 1,
          top: 0,
          backgroundColor: sprintState
            ? PLANNING_SPRINT_BACKGROUND_STYLES_MAP[sprintState]
            : 'var(--bgPrimary)',
          width: '100%',
        }}
      >
        <Box style={{ backgroundColor: 'var(--bgCompSecondaryBig)' }}>
          <Flex width="100%" height="1.75rem" alignItems="center">
            <Spacer size="sm">
              <Typography type="small" color="gray">
                {title}
              </Typography>
            </Spacer>
            {navigation}
          </Flex>
        </Box>
      </Box>
      {children}
    </Flex>
  );
};

const getPlanningBoardItemsWrapperTitle = (
  type: string,
  itemsCount: number
) => {
  const pluralEnding = itemsCount > 1 ? 's' : '';

  return `${itemsCount} ${type}${pluralEnding}`;
};
