import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const TabsContext = createContext(null);
const TabContext = createContext(null);
const TabListContext = createContext(null);
const TabPanelContext = createContext(null);

const useTabsContext = () => {
  const tabsContext = useContext(TabsContext);

  if (!tabsContext) {
    throw new Error('useTabsContext must be used in TabsContextProvider');
  }

  return tabsContext;
};

export const useTabContext = () => {
  const tabContext = useContext(TabContext);

  if (!tabContext) {
    throw Error('useTabContext must be used in TabContextProvider');
  }

  return tabContext;
};

export const useTabPanelContext = () => {
  const tabPanelContext = useContext(TabPanelContext);

  if (!tabPanelContext) {
    throw Error('useTabPanelContext must be used in TabPanelContextProvider');
  }

  return tabPanelContext;
};

const useTabListContext = () => {
  const tabListContext = useContext(TabListContext);

  if (!tabListContext) {
    throw Error('useTabListContext must be used in TabListContextProvider');
  }

  return tabListContext;
};

const TabsProviderPropTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};
const TabProviderPropTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};
const TabListProviderPropTypes = {
  children: PropTypes.node.isRequired,
};
const TabPanelProviderPropTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export const TabsProvider = ({ children, id }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabsProviderValue = useMemo(
    () => ({ id, selectedTabIndex, setSelectedTabIndex }),
    [id, selectedTabIndex, setSelectedTabIndex]
  );

  return (
    <TabsContext.Provider value={tabsProviderValue}>
      {children}
    </TabsContext.Provider>
  );
};

TabsProvider.propTypes = TabsProviderPropTypes;

export const TabProvider = ({ children, index }) => {
  const { currentTabIndex, onTabChange, tabsId } = useTabListContext();

  const isSelected = currentTabIndex === index;

  const tabProviderValue = useMemo(
    () => ({
      key: `${tabsId}-${index}`,
      id: `${tabsId}-${index}`,
      isSelected,
      onClick: () => onTabChange(index),
    }),
    [index, isSelected, onTabChange, tabsId]
  );

  return (
    <TabContext.Provider value={tabProviderValue}>
      {children}
    </TabContext.Provider>
  );
};

TabProvider.propTypes = TabProviderPropTypes;

export const TabListProvider = ({ children }) => {
  const { id, selectedTabIndex, setSelectedTabIndex } = useTabsContext();

  const onTabChange = useCallback(
    (index) => {
      setSelectedTabIndex(index);
    },
    [setSelectedTabIndex]
  );

  const tabListProviderValue = useMemo(
    () => ({ currentTabIndex: selectedTabIndex, onTabChange, tabsId: id }),
    [selectedTabIndex, onTabChange, id]
  );

  return (
    <TabListContext.Provider value={tabListProviderValue}>
      {children}
    </TabListContext.Provider>
  );
};

TabListProvider.propTypes = TabListProviderPropTypes;

export const TabPanelProvider = ({ children }) => {
  const { id, selectedTabIndex } = useTabsContext();

  const tabPanelProviderValue = useMemo(
    () => ({
      id: `${id}-${selectedTabIndex}-tab`,
    }),
    [selectedTabIndex, id]
  );

  return (
    <TabPanelContext.Provider value={tabPanelProviderValue}>
      {children[selectedTabIndex]}
    </TabPanelContext.Provider>
  );
};

TabPanelProvider.propTypes = TabPanelProviderPropTypes;
