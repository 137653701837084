import TruncateMarkup from 'react-truncate-markup';

import { Box } from 'shared';

import { Typography } from 'shared/components/Typography';

type TCoachingToolCardDescription = {
  description: string;
};

export const CoachingToolCardDescription = ({
  description,
}: TCoachingToolCardDescription) => (
  <Box
    style={{
      maxWidth: '100%',
    }}
  >
    <Typography
      color="gray"
      fontWeight="regular"
      type="meta"
      cropped
      whiteSpace="pre-wrap"
    >
      <TruncateMarkup lines={2} ellipsis={<span>...</span>}>
        <span>{description}</span>
      </TruncateMarkup>
    </Typography>
  </Box>
);
