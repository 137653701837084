import { TabPanel, TabPanelsProps } from '@chakra-ui/react';

import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  CoachingToolCard,
  CoachingToolCardMenu,
  useCoachingToolsQuery,
} from 'features/coachingTool';
import { PlanningBoardItemsWrapper } from 'features/planning';

import { BoardColumn } from 'shared/components/Board';

type TPlanningBacklogCoachingToolsTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogCoachingToolsTab = (
  props: TPlanningBacklogCoachingToolsTab
) => {
  const { data: coachingTools, isLoading } = useCoachingToolsQuery();

  if (isLoading) {
    return <Loader />;
  }

  return coachingTools?.length ? (
    <TabPanel
      {...props}
      m={0}
      p={0}
      height="100%"
      width="100%"
      overflowY="scroll"
      overflowX="hidden"
    >
      <PlanningBoardItemsWrapper
        type="coaching tool"
        itemsCount={coachingTools.length}
      >
        <BoardColumn>
          {coachingTools.map(({ name, description, picture, id }) => (
            <Spacer size="sm">
              <CoachingToolCard
                key={id}
                name={name}
                description={description}
                url={picture?.url}
              >
                <CoachingToolCardMenu toolId={id} />
              </CoachingToolCard>
            </Spacer>
          ))}
        </BoardColumn>
      </PlanningBoardItemsWrapper>
    </TabPanel>
  ) : null;
};
