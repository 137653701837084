import Box from 'shared_DEPRECATED/components/Box';
import { Nullable } from 'shared_DEPRECATED/types';

import { TABLE_CELL_DEFAULT_VALUE } from 'features/sessions/config';

import { Typography } from 'shared/components/Typography';

type SessionsTableBaseCellProps = {
  value: Nullable<string | number>;
};

export const SessionsTableBaseCell = ({
  value,
}: SessionsTableBaseCellProps) => {
  return (
    <Box style={{ maxWidth: '12rem' }}>
      <Typography
        type="small"
        color="gray"
        fontWeight="regular"
        cropped={true}
        dataTestid="sprint-week-cell"
      >
        {value || TABLE_CELL_DEFAULT_VALUE}
      </Typography>
    </Box>
  );
};
