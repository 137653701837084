import PropTypes from 'prop-types';
import React from 'react';

import { TabListProvider, TabPanelProvider, TabsProvider } from './context';

const TabsPropTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

const Tabs = ({ id, children }) => {
  const childrenArray = React.Children.toArray(children);

  // with this API we expect the first child to be a list of tabs
  // followed by a list of tab panels that correspond to those tabs
  // the ordering is determined by the position of the elements
  // that are passed in as children
  const [tabList, ...tabPanels] = childrenArray;

  return (
    <TabsProvider id={id}>
      <TabListProvider>{tabList}</TabListProvider>
      <TabPanelProvider>{tabPanels}</TabPanelProvider>
    </TabsProvider>
  );
};

Tabs.propTypes = TabsPropTypes;

export default Tabs;
