import styles from './Link.module.css';

export const LinkNewTab = ({
  href,
  children,
}: React.HTMLProps<HTMLAnchorElement>) => {
  return (
    <a
      className={styles.link}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
