import { useCallback, useEffect, useRef, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { Emitter } from 'app/socket/SocketProvider';
import { IncomingMessage } from 'http';
import { logError } from 'lib/sentry/logError';
import replaceKeysWithCamelCase from 'shared_DEPRECATED/utils/replaceKeysWithCamelCase';
import snakecase from 'snakecase-keys';

import { OutgoingSendMessages } from 'features/chat/config/types/Message';

import {
  PING_INTERVAL,
  RECONNECT_ATTEMPTS,
  RECONNECT_INTERVAL,
} from './constants';

export const useSocket = (token: string | null) => {
  const didUnmount = useRef(false);
  const [shouldConnect, setShouldConnect] = useState(true);
  const [isError, setIsError] = useState(false);

  const { readyState, sendMessage } = useWebSocket(
    token,
    {
      onOpen: () => {
        setIsError(false);
      },
      onClose: (event) => {
        isError &&
          logError(
            `Socket connection error: code - ${event.code}, reason - ${event.reason}`
          );
      },
      onError: () => {
        setIsError(true);
      },
      onMessage: (event) => {
        const message = JSON.parse(event.data);
        const normalizedMessage = replaceKeysWithCamelCase(message) as {
          event: string;
          data: IncomingMessage;
        };

        if (Emitter.listenerCount(message.event)) {
          Emitter.emit(message.event, normalizedMessage);
        }

        if (Emitter.listenerCount(message.chat_id)) {
          Emitter.emit(message.chat_id, normalizedMessage);
        }
      },
      heartbeat: {
        // @ts-ignore
        message: () => JSON.stringify({ event: 'ping' }),
        returnMessage: 'pong',
        interval: PING_INTERVAL,
      },
      retryOnError: true,
      // will attempt to reconnect up to a specified number of attempts (with a default of 20) at a specified interval (with a default of 5000 (ms))
      shouldReconnect: () => {
        // useWebSocket will handle unmounting for you
        // but in case the component is unmounting we don't want to reconnect
        return didUnmount.current === false;
      },
      onReconnectStop: () => {
        logError('Socket reconnection limit exceeded');
      },
      reconnectAttempts: RECONNECT_ATTEMPTS,
      reconnectInterval: RECONNECT_INTERVAL,
    },
    shouldConnect
  );

  const setShouldConnectToFalse = useCallback(() => {
    setShouldConnect(false);
  }, []);

  const setShouldConnectToTrue = useCallback(() => {
    setShouldConnect(true);
  }, []);

  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      setShouldConnectToTrue();
    } else {
      setShouldConnectToFalse();
    }
  }, [setShouldConnectToFalse, setShouldConnectToTrue]);

  useEffect(() => {
    window.addEventListener('online', setShouldConnectToTrue);
    window.addEventListener('offline', setShouldConnectToFalse);

    return () => {
      window.removeEventListener('online', setShouldConnectToTrue);
      window.removeEventListener('offline', setShouldConnectToFalse);
    };
  }, [setShouldConnectToFalse, setShouldConnectToTrue]);

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [onVisibilityChange]);

  useEffect(() => {
    didUnmount.current = false;

    return () => {
      didUnmount.current = true;
    };
  }, []);

  return {
    emit: (data: OutgoingSendMessages) => {
      sendMessage(JSON.stringify(snakecase(data)));
    },
    isSocketReady: readyState === ReadyState.OPEN,
  };
};
