import { MenuItem } from 'shared';

import {
  IPlanningDraftSprint,
  useDraftSprintAddLibraryChallenge,
} from 'features/planning';

type TPlanningDraftSprintAddLibraryChallengeButton = {
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintAddLibraryChallengeButton = ({
  sprint,
}: TPlanningDraftSprintAddLibraryChallengeButton) => {
  const openExploreChallengesModal = useDraftSprintAddLibraryChallenge({
    sprint,
  });

  return (
    <MenuItem onClick={openExploreChallengesModal}>Add from library</MenuItem>
  );
};
