import { Typography } from 'shared/components/Typography';

type TCoachingToolCardTitle = {
  title: string;
};

export const CoachingToolCardTitle = ({ title }: TCoachingToolCardTitle) => (
  <Typography color="black" fontWeight="medium" type="meta" cropped>
    {title}
  </Typography>
);
