import { Box } from '@chakra-ui/react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';

import { $generateHtmlFromNodes } from '@lexical/html';
import { $isRootTextContentEmpty } from '@lexical/text';
import { EditorState, LexicalEditor } from 'lexical';

import { Placeholder } from './components/Placeholder';
import { namespace, TOOLBAR_HEIGHT } from './constants';
import { BasicToolbarPlugin } from './plugins/BasicToolbarPlugin';
import './textEditorStyles.css';
import { theme } from './themes/textEditorTheme';
import { prepareEditorState, reformatLineBreaksIfAny } from './utils';

type BasicTextEditorProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

const basicTextEditorNamespace = 'hos-basic-text-editor';

export const BasicTextEditor = ({
  value,
  onChange,
  placeholder = '',
}: BasicTextEditorProps) => {
  const initialConfig = {
    namespace,
    onError: () => {},
    editorState: prepareEditorState(reformatLineBreaksIfAny(value)),
    theme,
    nodes: [],
  };

  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      const raw = $generateHtmlFromNodes(editor, null);
      const isEmpty = $isRootTextContentEmpty(false);
      onChange(isEmpty ? '' : raw);
    });
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <HistoryPlugin />
      <BasicToolbarPlugin />
      <Box
        position="relative"
        style={{ height: `calc(100% - ${TOOLBAR_HEIGHT}px)` }}
      >
        <RichTextPlugin
          placeholder={<Placeholder>{placeholder}</Placeholder>}
          contentEditable={
            <ContentEditable
              className={`${basicTextEditorNamespace}__content`}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
      </Box>
      <OnChangePlugin onChange={handleChange} />
    </LexicalComposer>
  );
};
