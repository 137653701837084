import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { v4 as uuidv4 } from 'uuid';

import { IPlanningDraftSprint } from 'features/planning';
import { DraftSprint } from 'features/sprint/DraftSprint';
import { SPRINT_STATES } from 'features/sprint/config';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const useAddPlanningDraftSprintMutation = () => {
  const queryClient = useQueryClient();
  const { userId } = useCurrentModeUser();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    () =>
      request({
        url: `/api/draft-sprints/create-empty`,
        body: { forUserId: userId },
      }),
    {
      onMutate: async () => {
        const temporarySprintId = uuidv4();

        const temporaryDraftSprint = new DraftSprint({
          challenges: [],
          sprintId: temporarySprintId,
          state: SPRINT_STATES.DRAFT,
          updatedAt: '',
          coachingTools: [],
        });
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => ({
              ...oldVal,
              items: [...oldVal!.items, temporaryDraftSprint],
            })
          );
        }

        return { previousDraftSprintsValue, temporarySprintId };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [queryKeys.draftSprints],
          context?.previousDraftSprintsValue
        );

        logError(err);
      },
      onSuccess: ({ sprint: newDraftSprint }, _, { temporarySprintId }) => {
        queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
          [queryKeys.draftSprints],
          (oldVal) => ({
            ...oldVal,
            items: oldVal!.items.map((draftSprint) =>
              draftSprint.sprintId === temporarySprintId
                ? newDraftSprint
                : draftSprint
            ),
          })
        );

        queryClient.invalidateQueries([queryKeys.draftSprints]);
      },
    }
  );
};
