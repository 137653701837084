import { useOutletContext } from 'react-router-dom';

import { DAILY_STATUS_VALUES } from 'shared_DEPRECATED/utils/config';

export const useChallengePasses = ({ challengeId }) => {
  const { sprint } = useOutletContext();

  const currentChallenge = sprint.challenges.find(
    (challenge) => challenge.sprintChallengeId === challengeId
  );

  if (!currentChallenge) {
    return {
      allPasses: 0,
      usedPasses: 0,
      leftPasses: 0,
    };
  }

  const usedPasses = Object.values(
    currentChallenge.target.dailyStatus || {}
  ).reduce(
    (sum, { status }) => (status === DAILY_STATUS_VALUES.PASS ? sum + 1 : sum),
    0
  );
  const leftPasses = currentChallenge.passes - usedPasses;

  return {
    allPasses: currentChallenge.passes,
    usedPasses,
    leftPasses,
  };
};
