import { Box } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BasicTextEditor } from 'shared';

import {
  SessionSidebarSection,
  SessionSidebarSectionBody,
  SessionSidebarSectionHeader,
} from 'features/sessions/components';

export const NextSessionNotes = () => {
  const { getValues, setValue } = useFormContext();

  const fieldKey = getValues('nextSessionFieldKey') || '';
  const notesValue = useWatch({ name: `${fieldKey}.notes` }) || '';

  const handleChange = (value: string) => {
    setValue(`${fieldKey}.notes`, value, {
      shouldDirty: true,
    });
  };

  return (
    <SessionSidebarSection>
      <SessionSidebarSectionHeader title="Next session notes" />
      <SessionSidebarSectionBody>
        <Box h="8rem">
          <BasicTextEditor
            value={notesValue}
            onChange={handleChange}
            placeholder="Start writing here"
          />
        </Box>
      </SessionSidebarSectionBody>
    </SessionSidebarSection>
  );
};
