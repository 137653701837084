import { Box, Typography } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { iconNames, Icon } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

export const CoachingToolCardHeader = () => (
  <Flex alignItems="center">
    <Box w="20px" h="20px" bg="var(--bgCompAccent)" borderRadius="sm">
      <Flex
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Icon name={iconNames.coachingTool} />
      </Flex>
    </Box>
    <Spacer size="sm" />
    <Typography color="blue" fontWeight="medium" type="small">
      Coaching tool
    </Typography>
  </Flex>
);
