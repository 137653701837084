import { usePinnedColumnsContext } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  PlanningBacklog,
  usePlanningDraftSprintsQuery,
  useSelectedGoalsContext,
  PlanningBoardWrapper,
  BOARD_COLUMN_WIDTH,
  PlanningDraftSprints,
  PlanningActiveFinishedSprints,
  PlanningSprintModel,
  IPlanningDraftSprint,
} from 'features/planning';
import { useSprintsQuery } from 'features/sprint/hooks';

type SprintsByPin<T> = {
  pinned: T[];
  unpinned: T[];
};

export const PlanningBoard = () => {
  const { pinnedColumns } = usePinnedColumnsContext();

  const {
    data: draftSprints = { pinned: [], unpinned: [] },
    isLoading: isDraftSprintsLoading,
  } = usePlanningDraftSprintsQuery<SprintsByPin<IPlanningDraftSprint>>({
    options: {
      select: (sprints: IPlanningDraftSprint[]) =>
        mapPinnedUnpinnedSprints<IPlanningDraftSprint[]>(
          sprints,
          pinnedColumns
        ) as SprintsByPin<IPlanningDraftSprint>,
    },
  });
  const {
    data: sprints = { pinned: [], unpinned: [] },
    isLoading: areSprintsLoading,
  } = useSprintsQuery<SprintsByPin<PlanningSprintModel>>({
    options: {
      select: (sprints) =>
        mapPinnedUnpinnedSprints<PlanningSprintModel[]>(
          sprints.reverse(),
          pinnedColumns
        ) as SprintsByPin<PlanningSprintModel>,
    },
  });

  const { isLoading: isGoalsLoading } = useSelectedGoalsContext();

  if (isDraftSprintsLoading || isGoalsLoading || areSprintsLoading) {
    return <Loader />;
  }

  return (
    <PlanningBoardWrapper>
      <Flex height="100%" gap="0.5rem">
        <PlanningBacklog />
        <PlanningActiveFinishedSprints sprints={sprints.pinned} />
        <PlanningDraftSprints sprints={draftSprints.pinned} />
      </Flex>
      <Box
        style={{
          width: `calc(100% - ${BOARD_COLUMN_WIDTH})`,
          height: '100%',
          overflowX: 'auto',
        }}
      >
        <Flex height="100%" width="100%" gap="0.5rem">
          <PlanningActiveFinishedSprints sprints={sprints.unpinned} />
          <PlanningDraftSprints sprints={draftSprints.unpinned} />
        </Flex>
      </Box>
    </PlanningBoardWrapper>
  );
};

const mapPinnedUnpinnedSprints = <
  T extends Array<PlanningSprintModel | IPlanningDraftSprint>
>(
  sprints: T,
  pinnedColumns: Set<string>
) =>
  sprints.reduce(
    (acc: SprintsByPin<PlanningSprintModel | IPlanningDraftSprint>, sprint) => {
      if (pinnedColumns.has(sprint.sprintId)) {
        acc.pinned.push(sprint);
      } else {
        acc.unpinned.push(sprint);
      }

      return acc;
    },
    {
      pinned: [],
      unpinned: [],
    }
  );
