import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { IChallenge } from 'features/challenge/config/types';
import { useOpenChallengeModal } from 'features/challenge/hooks';
import { useEditPlanningDraftSprintChallengeMutation } from 'features/planning';
import { IPlanningDraftSprint } from 'features/planning/types';

import { Typography } from 'shared/components/Typography';

type TPlanningDraftSprintMenuEditButton = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintMenuEditButton = ({
  challenge,
  sprint,
}: TPlanningDraftSprintMenuEditButton) => {
  const openChallengeModal = useOpenChallengeModal();

  const { mutateAsync: onChallengeSubmit } =
    useEditPlanningDraftSprintChallengeMutation({
      sprint,
    });

  const openEditChallengeModal = () =>
    openChallengeModal({
      challenge,
      onChallengeSubmit,
      title: 'Create Challenge',
      sprint,
    });

  return (
    <OptionsMenuButton onClick={openEditChallengeModal}>
      <Typography color="black">Edit</Typography>
    </OptionsMenuButton>
  );
};
