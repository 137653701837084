import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';

const SprintHistoryList = ({ children }) => (
  <Flex as="ul" flexDirection="column" alignItems="stretch">
    {children}
  </Flex>
);

SprintHistoryList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SprintHistoryList;
