import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

type TGoalYears = {
  years: string;
};

export const GoalYears = ({ years }: TGoalYears) => (
  <Typography color="green" type="meta">
    <Flex justifyContent="flex-start">
      <Icon name={iconNames.flag} fill="var(--fgSuccess)" />
      <Spacer size="sm" />
      {years}
    </Flex>
  </Typography>
);
