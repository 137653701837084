import { useLocation, useParams } from 'react-router-dom';

import { getPathParams } from 'shared_DEPRECATED/utils';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { sharedSprintLinks } from 'features/sprint/utils';

const SPRINT_URL_RESOLVER_MAP = {
  [routeNames.COACH]: (sprint, email) =>
    `${sharedSprintLinks.participants}/${email}/${sprint.sprintId}`,
  [routeNames.CONNECTIONS]: (sprint, email) =>
    `/users/${email}/sprints/${sprint.sprintId}`,
};

export const useSprintLink = (sprint) => {
  const { email } = useParams();
  const { pathname } = useLocation();
  let [firstPathParam] = getPathParams(pathname);

  const sprintUrlResolver = SPRINT_URL_RESOLVER_MAP[firstPathParam];

  return (
    sprintUrlResolver?.(sprint, email) ||
    `/${routeNames.DASHBOARD}/${sprint.sprintId}`
  );
};
