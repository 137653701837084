import {
  ModalHeader as ChakraHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { ReactChild } from 'react';

type ModalHeaderProps = {
  children?: ReactChild | null;
  onClick?: () => void;
};

export const ModalHeader = ({ children = null }: ModalHeaderProps) => (
  <>
    <ChakraHeader>{children}</ChakraHeader>
    <ModalCloseButton data-testid="modal-close" />
  </>
);
