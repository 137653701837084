import { Box } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BasicTextEditor } from 'shared';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  SessionSidebarSection,
  SessionSidebarSectionBody,
} from 'features/sessions/components';

import { Typography } from 'shared/components/Typography';

export const ParticipantProfileNote = () => {
  const { setValue, getValues } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey');
  const clientNotes =
    useWatch({ name: `${fieldKey}.coachingClientNote.text` }) || '';

  const handleChange = (value: string) => {
    setValue(`${fieldKey}.coachingClientNote.text`, value, {
      shouldDirty: true,
    });
  };

  return (
    <SessionSidebarSection divide={false}>
      <Spacer size="md zr lg" />
      <Typography type="meta" color="black" fontWeight="bold">
        Participant profile notes
      </Typography>
      <SessionSidebarSectionBody>
        <Box h="12rem">
          <BasicTextEditor
            value={clientNotes}
            onChange={handleChange}
            placeholder="Start writing here"
          />
        </Box>
      </SessionSidebarSectionBody>
      <Spacer size="lg" />
    </SessionSidebarSection>
  );
};
