import PropTypes from 'prop-types';

import {
  CHALLENGE_NOTE_STATUS,
  FAIL_REASONS,
} from 'features/challengeNotes/config/index';
import { SESSION_ATTENDANCE_STATUS } from 'features/sessions/config';

const AttachmentType = PropTypes.shape({
  key: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
  contentType: PropTypes.string,
  contentLength: PropTypes.number,
});

export const AttachmentsType = PropTypes.arrayOf(AttachmentType);

const ChallengeNoteDataType = PropTypes.shape({
  status: PropTypes.oneOf(Object.values(CHALLENGE_NOTE_STATUS)),
  text: PropTypes.string,
  failReasons: PropTypes.arrayOf(PropTypes.oneOf(FAIL_REASONS)),
  sprintId: PropTypes.string,
  challengeId: PropTypes.string,
});

const RecapNoteDataType = PropTypes.shape({
  title: PropTypes.string,
  text: PropTypes.string,
  htmlContent: PropTypes.string,
  attachments: PropTypes.arrayOf(AttachmentType),
  sprintId: PropTypes.string,
  coachSessionNoteId: PropTypes.string,
  attendance: PropTypes.oneOf(Object.values(SESSION_ATTENDANCE_STATUS)),
});

const GeneralNoteAuthorPictureType = {
  key: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  blur_hash: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

const GeneralAuthorInfoType = {
  author_email: PropTypes.string.isRequired,
  author_name: PropTypes.string.isRequired,
  author_picture: GeneralNoteAuthorPictureType,
};

const GeneralNoteType = {
  ...GeneralAuthorInfoType,
  noteId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  noteType: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  repliesCount: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([ChallengeNoteDataType, RecapNoteDataType]),
};

export const NotesType = PropTypes.arrayOf(PropTypes.shape(GeneralNoteType));

export const ChallengeNoteType = PropTypes.shape({
  ...GeneralNoteType,
  data: ChallengeNoteDataType,
});
export const ChallengeNotesType = PropTypes.arrayOf(ChallengeNoteType);

export const RecapNoteType = PropTypes.shape({
  ...GeneralNoteType,
  data: RecapNoteDataType,
});

const RecapNotesType = PropTypes.arrayOf(RecapNoteType);

export const NoteType = PropTypes.oneOfType([
  ChallengeNotesType,
  RecapNotesType,
]);

export const FailReasonsType = PropTypes.arrayOf(
  (propValue, key, componentName, location, propFullName) => {
    if (!FAIL_REASONS.includes(propValue[key])) {
      return new Error(
        'Invalid prop `' +
          propFullName +
          '` supplied to' +
          ' `' +
          componentName +
          '`. Should be one of Note Fail Reasons.'
      );
    }
  }
);
