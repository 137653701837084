import { MenuItem } from 'shared';

import {
  IPlanningDraftSprint,
  useAddDraftSprintChallenge,
} from 'features/planning';

type TPlanningDraftSprintAddChallengeButton = {
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintAddChallengeButton = ({
  sprint,
}: TPlanningDraftSprintAddChallengeButton) => {
  const openAddChallengeModal = useAddDraftSprintChallenge({ sprint });

  return <MenuItem onClick={openAddChallengeModal}>Add challenge</MenuItem>;
};
