import { useFormContext } from 'react-hook-form';

import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { ModalButton } from 'shared/components/Modal/ModalButton';
import { Typography } from 'shared/components/Typography';

type TGoalAddDialogFooter = {
  onSubmit: () => void;
};

export const GoalAddDialogFooter = ({ onSubmit }: TGoalAddDialogFooter) => {
  const { formState } = useFormContext();

  return (
    <Flex width="100%">
      <ModalButton variant={VARIANTS.SECONDARY}>
        <Spacers sizes={['md', 'sm']}>
          <Typography color="black" fontWeight="medium" type="meta">
            Cancel
          </Typography>
        </Spacers>
      </ModalButton>
      <ModalButton
        onClick={onSubmit}
        shouldClose={formState.isValid}
        variant={VARIANTS.PRIMARY}
        dataTestid="goal-done-btn"
      >
        <Spacers sizes={['md', 'sm']}>
          <Typography color="whiteTotal" fontWeight="medium" type="meta">
            Add goal
          </Typography>
        </Spacers>
      </ModalButton>
    </Flex>
  );
};
