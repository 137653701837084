import { VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type TBoardColumn = {
  children: ReactNode;
  spacing?: number;
};

export const BoardColumn = ({ children, spacing = 0 }: TBoardColumn) => (
  <VStack align="stretch" flex="1" spacing={spacing} overflow="scroll">
    {children}
  </VStack>
);
