import { ReactNode } from 'react';

import { Divider } from 'shared_DEPRECATED/components/Divider';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer/Spacer';

import { Typography } from 'shared/components/Typography';

type SessionSidebarSectionProp = {
  children: ReactNode;
  divide?: boolean;
  dataTestid?: string;
};

type SessionSidebarSectionHeaderProps = {
  title: string;
  children?: ReactNode;
};

type SessionSidebarSectionBodyType = {
  children: ReactNode;
};

export const SessionSidebarSectionHeader = ({
  title,
  children,
}: SessionSidebarSectionHeaderProps) => {
  return (
    <Flex>
      <Typography type="small" color="gray">
        {title}
      </Typography>
      {children}
    </Flex>
  );
};

export const SessionSidebarSectionBody = ({
  children,
}: SessionSidebarSectionBodyType) => {
  return (
    <>
      <Spacer size="sm zr" />
      {children}
    </>
  );
};

export const SessionSidebarSection = ({
  children,
  divide = true,
  dataTestid,
}: SessionSidebarSectionProp) => {
  return (
    <>
      <Spacer dataTestid={dataTestid} size="zr lg zr">
        {children}{' '}
      </Spacer>
      {divide && (
        <Spacer size="lg zr lg">
          <Divider color="var(--divPrimary)" />
        </Spacer>
      )}
    </>
  );
};
