import { useExploreChallengesDialog } from 'features/challenge/hooks/useExploreChallengesDialog';
import { useOpenChallengeModalWithBackButton } from 'features/challenge/hooks/useOpenChallengeModalWithBackButton';

import { useModalContext } from 'shared/components/Modal';

export const useExploreChallengesAddChallengeModal = ({
  initialChallenge,
  title,
}) => {
  const { sprint, onSubmit } = useModalContext();
  const openChallengeModal = useOpenChallengeModalWithBackButton();

  const onBackBtnClick = useExploreChallengesDialog({
    sprint,
  });

  return () =>
    openChallengeModal({
      challenge: initialChallenge,
      onChallengeSubmit: (challenge) => {
        onSubmit(challenge);
      },
      sprint,
      onBackBtnClick,
      title,
    });
};
