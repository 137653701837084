import { toastConfig } from 'lib/reactToastify/config';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useParticipantQuery } from 'features/participants/hooks';
import { IDraftSprint } from 'features/sprint/config/types';
import useSprintStartMutation from 'features/sprint/hooks/mutation/useSprintStart';
import { getParticipantSprintPageUrl } from 'features/sprint/utils';
import { USER_MODES } from 'features/user/config';
import { useUserContext } from 'features/user/context';
import { useUserMode } from 'features/user/hooks';

type useSprintStartSubmitParams = {
  sprint: Omit<IDraftSprint, 'sprintId'>;
};

export const useSprintStartSubmit = ({
  sprint,
}: useSprintStartSubmitParams) => {
  const { user } = useUserContext();
  const userMode = useUserMode();
  const { email } = useParams() as { email: string };
  const { participant } = useParticipantQuery(email);
  const navigate = useNavigate();

  const { mutateAsync: startSprint } = useSprintStartMutation({
    email,
    sprint,
    clientId: userMode === USER_MODES.coach ? participant.userId : user.userId,
    userMode,
  });

  const onSubmit = async ({ title }: { title: string }) => {
    let toastId = toast.loading('Starting sprint...', toastConfig);
    try {
      const { sprintId } = await startSprint({ title });

      toast.update(toastId, {
        render: 'Sprint started!',
        type: 'success',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });

      navigate(
        userMode === USER_MODES.coach
          ? getParticipantSprintPageUrl({ email, sprintId })
          : `/sprints/${sprintId}`,
        {
          replace: true,
        }
      );
    } catch (err) {
      toast.update(toastId, {
        render:
          "There's an error with starting sprint. Please try again later.",
        type: 'error',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    }
  };

  return { onSubmit };
};
