import { useColorMode } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { ButtonIcon } from 'shared_DEPRECATED/components/Button';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import { NextSessionToolsLink } from 'features/sessions/components/NextSessionForm';
import { COACHING_EXTERNAL_TOOL } from 'features/sessions/config';
import { CoachingTool } from 'features/sessions/config/types';

import { Typography } from 'shared/components/Typography';

type NextSessionCoachingToolsCardViewProps = {
  formKey: string;
  onDelete: () => void;
  editMode?: boolean;
  coachingTool: CoachingTool;
};
export const NextSessionCoachingToolsCardView = ({
  formKey,
  onDelete,
  coachingTool,
}: NextSessionCoachingToolsCardViewProps) => {
  const { setValue } = useFormContext();
  const { colorMode: theme } = useColorMode();
  const isThemeLight = theme === 'light';

  const { tool, unlistedToolName, link } = coachingTool;
  return (
    <>
      <FlexItem alignSelf="stretch" width="100%">
        <Flex justifyContent="space-between">
          <Typography type="body" fontWeight="semiBold" color="black">
            {tool.label}
          </Typography>
          <Flex gap="var(--spacing04)">
            <ButtonIcon
              onClick={() => setValue(`${formKey}.editMode`, true)}
              icon={iconNames.pen}
            />
            <ButtonIcon onClick={onDelete} icon={iconNames.bin} />
          </Flex>
        </Flex>
      </FlexItem>
      {tool.value === COACHING_EXTERNAL_TOOL && unlistedToolName && (
        <>
          <FlexItem alignSelf="stretch">
            <Typography
              type="small"
              color={isThemeLight ? 'gray' : 'black'}
              fontWeight="semiBold"
            >
              External session tool name
            </Typography>
          </FlexItem>
          <FlexItem alignSelf="stretch">
            <Typography type="small" color="black">
              {unlistedToolName}
            </Typography>
          </FlexItem>
        </>
      )}
      {link && <NextSessionToolsLink link={link} />}
    </>
  );
};
