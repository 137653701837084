import { MenuOptionGroup, MenuOptionGroupProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const DropdownMultipleSelectGroup = ({
  children,
  type = 'checkbox',
  ...props
}: MenuOptionGroupProps & { children: ReactNode }) => (
  <MenuOptionGroup type={type} {...props}>
    {children}
  </MenuOptionGroup>
);
