import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const ChallengeTitleChangedPropTypes = {
  before: PropTypes.string.isRequired,
  after: PropTypes.string.isRequired,
};

const ChallengeTitleChanged = ({
  before: challengeTitleBefore,
  after: challengeTitleAfter,
}) => (
  <Typography>
    Changed challenge title from{' '}
    <Box as="span" style={{ backgroundColor: 'var(--bgCompSecondaryMid)' }}>
      <Spacer as="span" size="zr zr zr sm" />
      <Typography as="strong" fontWeight="medium" color="black">
        {challengeTitleBefore}
      </Typography>
      <Spacer as="span" size="zr sm zr zr" />
    </Box>{' '}
    to{' '}
    <Box as="span" style={{ backgroundColor: 'var(--bgCompMinorSuccess)' }}>
      <Spacer as="span" size="zr zr zr sm" />
      <Typography as="strong" fontWeight="medium" color="black">
        {challengeTitleAfter}
      </Typography>
      <Spacer as="span" size="zr sm zr zr" />
    </Box>
  </Typography>
);

ChallengeTitleChanged.propTypes = ChallengeTitleChangedPropTypes;

export default ChallengeTitleChanged;
