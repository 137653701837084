import { useFormContext } from 'react-hook-form';

import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { TextInput } from 'shared_DEPRECATED/components/Form/Input';
import Spacer from 'shared_DEPRECATED/components/Spacer/Spacer';

import { SessionsFormCoachingToolsLinkHint } from 'features/sessions/components/Form/CoachingTools';
import { COACHING_EXTERNAL_TOOL } from 'features/sessions/config';

type LinkFieldsProps = {
  formKey: string;
  coachingToolValue: string;
};

export const LinkFields = ({ formKey, coachingToolValue }: LinkFieldsProps) => {
  const { register } = useFormContext();
  return (
    <Flex flexDirection="column" width="100%">
      {coachingToolValue === COACHING_EXTERNAL_TOOL ? (
        <>
          <TextInput
            {...register(`${formKey}.unlistedToolName`)}
            //@ts-ignore
            placeholder="Add the name here"
          />
          <Spacer size="md" />
          <TextInput
            {...register(`${formKey}.link`)}
            //@ts-ignore
            placeholder="Add any related link for tool materials"
          />
        </>
      ) : (
        <>
          <FlexItem>
            <TextInput
              {...register(`${formKey}.link`)}
              //@ts-ignore
              placeholder="Add miro link here"
            />
            <Spacer size="md" />
            <SessionsFormCoachingToolsLinkHint />
          </FlexItem>
        </>
      )}
    </Flex>
  );
};
