import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalBreakdownTitle,
  GoalBreakdownContent,
  useGoalBreakdownQuery,
  GoalFocusAreaHeaderMenu,
} from 'features/goal';

import { BoardColumnWrapper } from 'shared/components/Board';

export const GoalBreakdownBoard = () => {
  const { isLoading, data: goalBreakdown } = useGoalBreakdownQuery();

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <Flex width="100%">
        <GoalBreakdownTitle />
        <GoalFocusAreaHeaderMenu />
      </Flex>
      <Spacer size="md" />
      <Box style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
        {isLoading ? (
          <Loader size="sm" />
        ) : (
          <BoardColumnWrapper fraction={1}>
            <GoalBreakdownContent
              challenges={goalBreakdown.challenges}
              focusAreas={goalBreakdown.focusAreas}
            />
          </BoardColumnWrapper>
        )}
      </Box>
    </Flex>
  );
};
