import { useMemo } from 'react';

import useEventsSubscription from 'shared_DEPRECATED/hooks/useEventsSubscription.js';

import { FeedbackButton } from 'features/feedback/components/FeedbackButton';
import { FEEDBACK_CALLBACK_EVENT } from 'features/feedback/config';
import { useFeedbackDialogContext } from 'features/feedback/context/feedback';
import { useFeedbackData, useFeedbackScript } from 'features/feedback/hooks';

export const Feedback = () => {
  useFeedbackScript();
  useFeedbackData();

  const [openFeedbackDialog, setOpenFeedbackDialog] =
    useFeedbackDialogContext();

  const feedbackEvents = useMemo<Array<[string, Function]>>(
    () => [
      [
        FEEDBACK_CALLBACK_EVENT,
        ({ detail }: { detail: { openFeedbackDialog: () => void } }) => {
          setOpenFeedbackDialog(() => detail.openFeedbackDialog);
        },
      ],
    ],
    [setOpenFeedbackDialog]
  );

  useEventsSubscription(feedbackEvents);

  return openFeedbackDialog ? (
    <FeedbackButton openFeedbackDialog={openFeedbackDialog} />
  ) : (
    <></>
  );
};
