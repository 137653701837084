import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
  IChallenge,
} from 'features/challenge/config/types';
import { UNLINKED_CHALLENGES_VALUE } from 'features/planning';

export const isItemNextToTheDropZone = (
  itemIndex: number,
  dropZoneIndex: number
) => itemIndex === dropZoneIndex || itemIndex + 1 === dropZoneIndex;

export const canItemBeDroppedInDropZone =
  (currentColumn: 'sprint' | 'backlog', currentSprintId?: string) =>
  (challengeIndex: number) =>
  (
    item: IChallenge & {
      fromSprintId: string;
      index: number;
      fromBacklog: boolean;
    }
  ) => {
    const isNextToItself = isItemNextToTheDropZone(item.index, challengeIndex);

    return item.fromBacklog
      ? !(currentColumn === 'backlog' && isNextToItself)
      : currentColumn !== 'backlog' &&
          !(item.fromSprintId === currentSprintId && isNextToItself);
  };

export const filterChallengesByGoal = ({
  challenges,
  selectedGoalsIds,
}: {
  challenges: (INonRepeatedChallenge | IRepeatedChallenge)[];
  selectedGoalsIds: string[];
}) =>
  challenges.filter((challenge) => {
    if (selectedGoalsIds.includes(UNLINKED_CHALLENGES_VALUE)) {
      return (
        challenge.goals.length === 0 ||
        challenge.goals.some(({ goalId }) => selectedGoalsIds.includes(goalId))
      );
    }

    return challenge.goals.some(({ goalId }) =>
      selectedGoalsIds.includes(goalId)
    );
  });
