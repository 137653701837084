import PropTypes from 'prop-types';
import { forwardRef, useLayoutEffect, useRef } from 'react';

import classNames from 'classnames/bind';

import styles from 'shared_DEPRECATED/components/Form/Input/Input.module.css';

const inputSizes = {
  sm: {
    'font-size': 'var(--font-size-medium)',
    'line-height': 'var(--line-height-medium)',
    'font-weight': 'var(--font400)',
  },
  lg: {
    'font-size': 'var(--font-size-xl)',
    'line-height': 'var(--line-height-xl)',
    'font-weight': 'var(--font600)',
  },
};

const BaseInputPropTypes = {
  /**
   * @type {string}
   * @memberof BaseInputPropTypes
   * @name defaultValue
   * @example <BaseInput defaultValue="John Doe" />
   */
  defaultValue: PropTypes.string,
  /**
   * @type {string}
   * @memberof BaseInputPropTypes
   * @name name
   * @example <BaseInput name="John Doe" />
   */
  name: PropTypes.string.isRequired,
  /**
   * @type {string}
   * @memberof BaseInputPropTypes
   * @name placeholder
   * @example <BaseInput placeholder="John Doe" />
   */
  placeholder: PropTypes.string,
  /**
   * @type {('sm'|'lg')}
   * @required
   * @memberof BaseInputPropTypes
   * @name size
   * @example <BaseInput size="sm" />
   */
  size: PropTypes.oneOf(Object.keys(inputSizes)),
  /**
   * @type {('email'|'text'|'number'|'search')}
   * @required
   * @memberof BaseInputPropTypes
   * @name type
   * @example <BaseInput type="email" />
   */
  type: PropTypes.oneOf(['email', 'text', 'number', 'search']).isRequired,
  /**
   * @type {boolean}
   * @required
   * @memberof BaseInputPropTypes
   * @name error
   * @example <BaseInput error />
   */
  error: PropTypes.bool,
};

const cx = classNames.bind(styles);

const BaseInput = forwardRef(
  (
    {
      defaultValue,
      name,
      placeholder = '',
      size = 'sm',
      type,
      error,
      ...props
    },
    ref
  ) => {
    const externalRefType = typeof ref;
    let element = useRef(null);

    if (externalRefType === 'object' && ref !== null) {
      element = ref;
    }

    useLayoutEffect(() => {
      const elementRef = element?.current;

      if (elementRef) {
        if (externalRefType === 'function') {
          ref(elementRef);
        }
        const fontProps = inputSizes[size];
        Object.entries(fontProps).forEach(([prop, value]) =>
          elementRef.style.setProperty(`--${prop}`, value)
        );
      }
    }, [element, externalRefType, ref, size]);

    return (
      <input
        id={name}
        className={cx(styles.input, { 'input--error': error })}
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        ref={element}
        type={type}
        {...props}
      />
    );
  }
);

BaseInput.propTypes = BaseInputPropTypes;

export default BaseInput;
