import { useQuery } from 'react-query';

import {
  DEFAULT_RETRY_COUNT,
  HTTP_ERROR_STATUS_CODES,
  queryKeys,
} from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { USER_MODES } from 'features/user/config';
import { useUserMode } from 'features/user/hooks';

export const useParticipantQuery = (email, options = {}) => {
  const userMode = useUserMode();

  const { request } = useQueryHTTPRequest();
  const { data, status, isFetching, isLoading, error } = useQuery(
    `${queryKeys.participant}-${email}`,
    () => request({ url: `/api/coach/clients/${email}` }),
    {
      ...options,
      enabled: !!email && userMode === USER_MODES.coach,
      retry: (failureCount, error) =>
        failureCount < DEFAULT_RETRY_COUNT &&
        error.message !== HTTP_ERROR_STATUS_CODES.FORBIDDEN,
    }
  );

  const placeholderData = {
    userEmail: '',
    userName: '',
    userPicture: { url: '' },
    chatId: '',
    timezone: '',
  };

  return {
    participant: data || placeholderData,
    status,
    isFetching,
    isLoading,
    error,
  };
};
