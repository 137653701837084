import { Box } from '@chakra-ui/react';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { useState } from 'react';

import { $generateHtmlFromNodes } from '@lexical/html';
import { $isRootTextContentEmpty } from '@lexical/text';
import { $getRoot, $getSelection, EditorState, LexicalEditor } from 'lexical';
import { logError } from 'lib/sentry/logError';

import { ToolbarPlugin } from './ToolbarPlugin';
import { InsertTemplateButton } from './components/InsertTemplateButton/InsertTemplateButton';
import { UndoRedoButtons } from './components/UndoRedo/UndoRedoButtons';
import { namespace, TOOLBAR_HEIGHT } from './constants';
import { editorNodes } from './nodes/editorNodes';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { CodeHighlightPlugin } from './plugins/CodeHighlightPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin/FloatingTextFormatToolbarPlugin';
import { ImagePlugin, INSERT_IMAGE_COMMAND } from './plugins/ImagePlugin';
import { ListMaxIndentLevelPlugin } from './plugins/ListMaxIndentLevelPlugin';
import { ShortcutPlugin } from './plugins/ShortcutPlugin';
import { TextEditorDragAndDrop } from './plugins/TextEditorDragAndDrop';
import './textEditorStyles.css';
import { theme } from './themes/textEditorTheme';
import { prepareEditorState } from './utils';
import { convertHtmlToLexicalNodes } from './utils/convertHtmlToLexicalNodes';

type EditorProps = {
  value: string;
  onChange: (value: string) => void;
  generateTemplate: () => string;
};

export const TextEditor = ({
  value,
  onChange,
  generateTemplate,
}: EditorProps) => {
  const initialConfig = {
    namespace,
    onError: (error: Error) => {
      logError(error);
    },
    editorState: prepareEditorState(value),
    theme,
    nodes: [...editorNodes],
  };

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<
    HTMLDivElement | undefined
  >(undefined);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      const raw = $generateHtmlFromNodes(editor, null);
      const isEmpty = $isRootTextContentEmpty(false);
      onChange(isEmpty ? '' : raw);
    });
  };

  const handleTemplateClick = (editor: LexicalEditor) => {
    const template = generateTemplate();

    editor.update(() => {
      const nodes = convertHtmlToLexicalNodes(editor, template);
      $getRoot().clear();
      $getRoot().select();
      $getSelection()?.insertNodes(nodes);
    });
  };

  const handleDrop = (editor: LexicalEditor, file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        altText: 'image',
        src: reader.result as string,
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <LexicalErrorBoundary
        onError={(error: Error) => {
          logError(error);
        }}
      >
        <>
          <HistoryPlugin />
          <ToolbarPlugin
            setIsLinkEditMode={setIsLinkEditMode}
            additionalActions={
              <InsertTemplateButton handleClick={handleTemplateClick} />
            }
          >
            <UndoRedoButtons />
          </ToolbarPlugin>
          <Box
            position="relative"
            style={{ height: `calc(100% - ${TOOLBAR_HEIGHT}px)` }}
          >
            <RichTextPlugin
              placeholder={null}
              contentEditable={
                <Box
                  className={`${namespace}__container`}
                  border="var(--border-secondary)"
                  ref={onRef}
                >
                  <TextEditorDragAndDrop onDrop={handleDrop}>
                    <ContentEditable className={`${namespace}__content`} />
                  </TextEditorDragAndDrop>
                </Box>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <FloatingTextFormatToolbarPlugin
              anchorElem={floatingAnchorElem}
              setIsLinkEditMode={setIsLinkEditMode}
            />
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          </Box>
          <ListPlugin />
          <OnChangePlugin onChange={handleChange} />
          <CheckListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={1} />
          <CodeHighlightPlugin />
          <AutoLinkPlugin />
          <LinkPlugin />
          <ImagePlugin />
          <TabIndentationPlugin />
          <ShortcutPlugin />
        </>
      </LexicalErrorBoundary>
    </LexicalComposer>
  );
};
