import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { IPlanningDraftSprint } from 'features/planning';

type TUseRemovePlanningDraftSprintChallengesMutation = {
  sprint: IPlanningDraftSprint;
};

export const useRemovePlanningDraftSprintChallengesMutation = ({
  sprint,
}: TUseRemovePlanningDraftSprintChallengesMutation) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation(
    //@ts-ignore
    async (challengeIds: string[]) =>
      await request({
        url: `/api/web/sprints/${sprint.sprintId}/challenges/delete`,
        body: { challengeIds },
      }),
    {
      onMutate: async (challengeIds: string[]) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData([
          queryKeys.draftSprints,
        ]);

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => {
              const updatedSprint = {
                ...sprint,
                challenges:
                  sprint.challenges?.filter(
                    (challenge) =>
                      !challengeIds.includes(challenge.challengeId!)
                  ) || [],
              };

              return {
                ...oldVal,
                items: updateArrayById({
                  array: oldVal!.items,
                  updatedItem: updatedSprint,
                  idProperty: 'sprintId',
                }),
              };
            }
          );
        }

        return { previousDraftSprintsValue };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.draftSprints]);
      },
      onError: (
        err: Error,
        _: any,
        context: {
          previousDraftSprintsValue?: (
            | INonRepeatedChallenge
            | IRepeatedChallenge
          )[];
        }
      ) => {
        queryClient.setQueryData(
          [queryKeys.draftSprints],
          context?.previousDraftSprintsValue
        );
      },
    }
  );
};
