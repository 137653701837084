import PropTypes from 'prop-types';
import React from 'react';

import { ChallengeNotesCardTextWithHighlight } from 'features/challengeNotes/components/Card';
import {
  ChallengeNotesCardRecapHeader,
  ChallengeNotesCardRecapTitle,
} from 'features/challengeNotes/components/Card/Recap';
import {
  ChallengeNotesCardBody,
  ChallengeNotesCardContainer,
  ChallengeNotesCardFooter,
} from 'features/challengeNotes/components/Card/index';
import { NOTE_STATUS } from 'features/challengeNotes/config';
import { RecapNoteType } from 'features/challengeNotes/config/propTypes';

const ChallengeNotesCardRecapPropTypes = {
  children: PropTypes.node,
  note: RecapNoteType,
};

export const ChallengeNotesCardRecap = ({ note, children }) => (
  <ChallengeNotesCardContainer unread={note.status === NOTE_STATUS.UNREAD}>
    <ChallengeNotesCardRecapHeader note={note} />
    <ChallengeNotesCardBody>
      <ChallengeNotesCardRecapTitle
        title={note.data.title}
        attendance={note.data.attendance}
      />
      <ChallengeNotesCardTextWithHighlight nodeId={note.noteId}>
        {note.data.htmlContent}
      </ChallengeNotesCardTextWithHighlight>
    </ChallengeNotesCardBody>
    <ChallengeNotesCardFooter>{children}</ChallengeNotesCardFooter>
  </ChallengeNotesCardContainer>
);

ChallengeNotesCardRecap.propTypes = ChallengeNotesCardRecapPropTypes;
