import { BoxProps, Badge as ChakraBadge } from '@chakra-ui/react';

export const BADGE_SIZES = {
  XSM: 'xsm',
  SM: 'sm',
  MD: 'md',
} as const;

export type BADGE_COLORS =
  | 'lightOrange'
  | 'lightPurple'
  | 'lightBlue'
  | 'lightYellow'
  | 'lightRed'
  | 'lightGray'
  | 'lightGreen'
  | 'blue'
  | 'gray'
  | 'darkGray'
  | 'green'
  | 'orange'
  | 'red'
  | 'black'
  | 'white';

export const Badge = ({
  children,
  size = BADGE_SIZES.XSM,
  bgColor,
  dataTestid = '',
  textTransform = 'none',
  ...props
}: BoxProps & {
  size?: 'xsm' | 'sm' | 'md';
  bgColor: BADGE_COLORS;
  dataTestid?: string;
  textTransform?: string;
}) => (
  <ChakraBadge
    size={size}
    data-testid={dataTestid}
    variant={bgColor}
    textTransform={textTransform}
    {...props}
  >
    {children}
  </ChakraBadge>
);
