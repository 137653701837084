import { useMutation, useQueryClient } from 'react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getParticipantsSprintsQueryKey } from 'api/utils';
import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';
import { logError } from 'lib/sentry/logError';

import { IDraftSprint } from 'features/sprint/config/types';
import { mapSprintPostData } from 'features/sprint/utils';
import { USER_MODES } from 'features/user/config';

import { useSprintAnalytics } from '../useSprintAnalytics';

type TSprintStartMutation = {
  email: string;
  sprint: Omit<IDraftSprint, 'sprintId'>;
  clientId: string;
  userMode: typeof USER_MODES[keyof typeof USER_MODES];
};

const useSprintStartMutation = ({
  email,
  sprint,
  clientId,
  userMode,
}: TSprintStartMutation) => {
  const queryClient = useQueryClient();
  const queryKeys = getParticipantsSprintsQueryKey(email);

  const { request } = useMutationHTTPRequest();
  const { sendSprintStartedEvent } = useSprintAnalytics();

  const weeksCount = useSprintDurationFeatureFlag();

  return useMutation(
    async ({ title }: { title: string }) => {
      const response = await request({
        url:
          userMode === USER_MODES.coach
            ? `/api/coach/clients/${clientId}/sprints/create`
            : '/api/sprints/create',
        body: mapSprintPostData({ ...sprint, title, weeksCount }),
      });

      return response;
    },
    {
      onSuccess: async () => {
        sendSprintStartedEvent();
        await queryClient.invalidateQueries(queryKeys);
      },
      onError: (error) => {
        logError(error);
      },
    }
  );
};

export default useSprintStartMutation;
