import PropTypes from 'prop-types';

import Spacer from 'shared_DEPRECATED/components/Spacer';

const CardFooter = ({ children }) => (
  <Spacer size="md zr zr">
    <Spacer size="sm zr zr">{children}</Spacer>
  </Spacer>
);

CardFooter.propTypes = {
  /**
   * The content of the component.
   * @type {React.ReactNode}
   * @required
   * @name children
   * @example <CardFooter>Content</CardFooter>
   */
  children: PropTypes.node.isRequired,
};

export default CardFooter;
