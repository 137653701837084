import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

import { EXPLORE_DIALOG_TABS } from 'features/challenge/config';

const ChallengeTabsContext = createContext();

export const ChallengeTabsProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(EXPLORE_DIALOG_TABS.CURATED);

  const value = useMemo(
    () => ({
      activeTab,
      setActiveTab,
    }),
    [activeTab, setActiveTab]
  );

  return (
    <ChallengeTabsContext.Provider value={value}>
      {children}
    </ChallengeTabsContext.Provider>
  );
};

ChallengeTabsProvider.propTypes = {
  children: PropTypes.node,
};

export const useChallengeTabsContext = () => {
  const tabsContext = useContext(ChallengeTabsContext);

  if (!tabsContext) {
    throw new Error(
      'useChallengeTabsContext must be used within a ChallengeTabsProvider'
    );
  }

  return tabsContext;
};
