import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import useSearch from 'shared_DEPRECATED/hooks/useSearch';
import { unionBy } from 'shared_DEPRECATED/utils';

import {
  EXPLORE_DIALOG_DEFAULT_DIMENSIONS_FILTER_VALUE,
  EXPLORE_DIALOG_TABS,
} from 'features/challenge/config';
import { useLibraryChallengesQuery } from 'features/challenge/hooks/query/useLibraryChallenges';
import { useChallengeTabsContext } from 'features/planning';
import { useQuickStartSprintsQuery } from 'features/sprint/hooks/query/useQuickStartSprints';

const ExploreDialogContentContext = React.createContext();

export const ExploreDialogContentProvider = ({ children }) => {
  const { activeTab } = useChallengeTabsContext();
  const [selectedDimensions, setSelectedDimensions] = useState(
    EXPLORE_DIALOG_DEFAULT_DIMENSIONS_FILTER_VALUE
  );
  const { email: userEmail } = useParams();
  const { data: challenges, isLoading: areChallengesLoading } =
    useLibraryChallengesQuery({ userEmail });
  const { data: quickStartSprints, isLoading: areQuickStartSprintsLoading } =
    useQuickStartSprintsQuery({
      enabled: activeTab === EXPLORE_DIALOG_TABS.CURATED,
    });

  const filteredChallenges = useMemo(
    () =>
      selectedDimensions && Object.keys(selectedDimensions).length > 0
        ? challenges[activeTab].filter(
            ({ dimensions: challengeDimensions }) => {
              return challengeDimensions.some(
                (dimension) => selectedDimensions[dimension]
              );
            }
          )
        : challenges[activeTab],
    [challenges, selectedDimensions, activeTab]
  );

  const quickStartSprintList = useMemo(() => {
    if (!quickStartSprints || quickStartSprints.length === 0) {
      return [];
    }

    const quickStartSprintsByDimension = !selectedDimensions
      ? quickStartSprints
      : Object.entries(selectedDimensions).reduce((acc, [dimension, value]) => {
          if (value) {
            return acc.concat(
              quickStartSprints.find((sprint) => sprint.dimension === dimension)
            );
          }

          return acc;
        }, []);

    return unionBy(
      quickStartSprintsByDimension
        .map((quickStartSprintsSection) => quickStartSprintsSection.items)
        .flat(),
      'qssId'
    );
  }, [quickStartSprints, selectedDimensions]);

  const predicate = useCallback(
    (item, query) => item.title.toLowerCase().includes(query.toLowerCase()),
    []
  );

  const { filteredCollection, ...search } = useSearch(
    useMemo(
      () => ({
        challenges: Object.values(challenges).flat(),
        quickStartSprints: quickStartSprintList,
      }),
      [challenges, quickStartSprintList]
    ),
    predicate,
    {
      debounce: 500,
    }
  );

  useEffect(() => {
    setSelectedDimensions(EXPLORE_DIALOG_DEFAULT_DIMENSIONS_FILTER_VALUE);
  }, [activeTab]);

  useEffect(() => {
    search.query &&
      selectedDimensions &&
      setSelectedDimensions(EXPLORE_DIALOG_DEFAULT_DIMENSIONS_FILTER_VALUE);
  }, [search.query, selectedDimensions]);

  const providerValue = useMemo(
    () => ({
      areChallengesLoading,
      areQuickStartSprintsLoading,
      areMyChallenges: challenges.custom.length > 0,
      selectedDimensions,
      setSelectedDimensions,
      challenges: search.query
        ? filteredCollection.challenges
        : filteredChallenges,
      quickStartSprints: filteredCollection.quickStartSprints,
      ...search,
    }),
    [
      areChallengesLoading,
      areQuickStartSprintsLoading,
      selectedDimensions,
      setSelectedDimensions,
      search,
      filteredCollection,
      filteredChallenges,
      challenges.custom.length,
    ]
  );

  return (
    <ExploreDialogContentContext.Provider value={providerValue}>
      {children}
    </ExploreDialogContentContext.Provider>
  );
};

ExploreDialogContentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useExploreDialogContentContext = () => {
  const exploreDialogContentContext = useContext(ExploreDialogContentContext);

  if (!exploreDialogContentContext) {
    throw new Error(
      'useExploreDialogContentContext must be used in ExploreDialogContentContext.Provider'
    );
  }

  return exploreDialogContentContext;
};
