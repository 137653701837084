import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { Nullable } from 'shared_DEPRECATED/types';
import { removeFromArrayById } from 'shared_DEPRECATED/utils';

import {
  IChallenge,
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { IPlanningDraftSprint } from 'features/planning';

type MovePlanningChallengeOptionsType = IChallenge & {
  challengeId: string;
  fromBacklog: boolean;
  fromSprintId?: string;
  toBacklog: boolean;
  toSprintId?: string;
  index: number;
  lowerChallengeId: Nullable<string>;
};

export const useMovePlanningChallengeMutation = () => {
  const queryClient = useQueryClient();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    //@ts-ignore
    ({
      challengeId,
      fromBacklog,
      fromSprintId,
      toBacklog,
      toSprintId,
      lowerChallengeId,
    }: MovePlanningChallengeOptionsType) =>
      request({
        url: `/api/web/sprint-planning/challenges/${challengeId}/move`,
        body: {
          fromBacklog,
          fromSprintId,
          toBacklog,
          toSprintId,
          underChallengeId: lowerChallengeId,
        },
      }),
    {
      onMutate: async ({
        challengeId,
        fromBacklog,
        fromSprintId,
        toBacklog,
        toSprintId,
        index,
        ...challenge
      }: MovePlanningChallengeOptionsType) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.challenges],
        });

        queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousChallengeBacklogValue = queryClient.getQueryData([
          queryKeys.challenges,
        ]);

        const previousDraftSprintsValue = queryClient.getQueryData([
          queryKeys.draftSprints,
        ]);

        if (fromBacklog && toSprintId) {
          queryClient.setQueryData<{ items: IChallenge[] }>(
            [queryKeys.challenges],
            (oldVal) => ({
              ...oldVal,
              items: removeFromArrayById({
                array: oldVal!.items,
                id: challengeId,
                idProperty: 'challengeId',
              }),
            })
          );

          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            //@ts-ignore
            (oldVal) => ({
              ...oldVal,
              items: oldVal!.items.map((sprint) => {
                if (sprint.sprintId !== toSprintId) {
                  return sprint;
                }
                const updatedChallenges = [...sprint.challenges];

                updatedChallenges.splice(index, 0, {
                  ...challenge,
                  challengeId,
                } as IRepeatedChallenge | INonRepeatedChallenge);

                return {
                  ...sprint,
                  challenges: updatedChallenges,
                };
              }),
            })
          );
        }

        if (fromSprintId && toSprintId) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => {
              const updatedSprints = oldVal!.items.map((sprint) => {
                if (sprint.sprintId === fromSprintId) {
                  const updatedChallenges = removeFromArrayById({
                    array: sprint.challenges,
                    id: challengeId,
                    idProperty: 'challengeId',
                  });

                  if (fromSprintId === toSprintId) {
                    updatedChallenges.splice(index, 0, {
                      ...challenge,
                      challengeId,
                    } as IRepeatedChallenge | INonRepeatedChallenge);

                    return { ...sprint, challenges: updatedChallenges };
                  }

                  return { ...sprint, challenges: updatedChallenges };
                }

                if (sprint.sprintId === toSprintId) {
                  const updatedChallenges = [...sprint.challenges];

                  updatedChallenges.splice(index, 0, {
                    ...challenge,
                    challengeId,
                  } as IRepeatedChallenge | INonRepeatedChallenge);

                  return { ...sprint, challenges: updatedChallenges };
                }

                return sprint;
              });

              return { ...oldVal, items: updatedSprints };
            }
          );
        }

        return { previousChallengeBacklogValue, previousDraftSprintsValue };
      },
      onError: (
        err: Error,
        _: any,
        context: {
          previousChallengeBacklogValue?: IChallenge[];
          previousDraftSprintsValue: IPlanningDraftSprint[];
        }
      ) => {
        queryClient.setQueryData(
          [queryKeys.challenges],
          context?.previousChallengeBacklogValue
        );

        queryClient.setQueryData(
          [queryKeys.draftSprints],
          context?.previousDraftSprintsValue
        );

        logError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.challenges]);
        queryClient.invalidateQueries([queryKeys.draftSprints]);
      },
    }
  );
};
