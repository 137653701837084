import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import { useSprints } from 'features/sprint/hooks';
import { getSprintsWithoutDraft } from 'features/sprint/utils';

const SprintsContext = React.createContext({
  sprints: [],
});

export const SprintsProvider = ({ children }) => {
  const { data: sprints } = useSprints();

  const providerValue = useMemo(
    () => ({
      sprints: sprints ? getSprintsWithoutDraft(sprints) : [],
    }),
    [sprints]
  );

  return (
    <SprintsContext.Provider value={providerValue}>
      {children}
    </SprintsContext.Provider>
  );
};

SprintsProvider.propTypes = { children: PropTypes.node.isRequired };

export const useSprintsContext = () => {
  const sprintsContext = useContext(SprintsContext);

  if (!sprintsContext) {
    throw new Error(
      'useSprintsContext must be used in SprintsContext.Provider'
    );
  }

  return sprintsContext;
};
