import { Box, Typography } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { iconNames, Icon } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { CoachingToolImage } from 'features/coachingTool';

type TCoachingToolDialogInfo = {
  url?: string;
  name: string;
};

export const CoachingToolDialogInfo = ({
  url,
  name,
}: TCoachingToolDialogInfo) => (
  <Flex width="100%" alignItems="center" justifyContent="flex-start" gap="1rem">
    <CoachingToolImage imageUrl={url} size="md" />
    <Flex flexDirection="column" alignItems="flex-start" gap="0.5rem">
      <Flex alignItems="center">
        <Box w="20px" h="20px" bg="var(--bgCompAccent)" borderRadius="sm">
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name={iconNames.coachingTool} />
          </Flex>
        </Box>
        <Spacer size="sm" />
        <Typography color="blue" fontWeight="medium" type="meta">
          Coaching tool
        </Typography>
      </Flex>
      <Typography color="black" fontWeight="semiBold" type="heading">
        {name}
      </Typography>
    </Flex>
  </Flex>
);
