import Box from 'shared_DEPRECATED/components/Box';
import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { useEditGoal, GoalCardType } from 'features/goal';

export const GoalEditButton = ({
  goalId,
  columnId,
  name,
  dimensions,
  deadline,
  deepDive,
  columnLabel,
  completedAt,
  note,
}: GoalCardType) => {
  const { onEditGoalClick } = useEditGoal({
    goalId,
    columnId,
    name,
    dimensions,
    deadline,
    deepDive,
    columnLabel,
    completedAt,
    note,
  });

  return (
    <Box
      style={{
        borderRadius: '50%',
        overflow: 'hidden',
        border: 'var(--border-secondary)',
      }}
    >
      <Button variant={VARIANTS.TERTIARY} onClick={onEditGoalClick}>
        <Spacers sizes={['md', 'sm']}>
          <Flex alignItems="center" justifyContent="center">
            <Icon
              name={iconNames.pen}
              width={16}
              height={16}
              fill="var(--fgPrimary)"
            />
          </Flex>
        </Spacers>
      </Button>
    </Box>
  );
};
