import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { ICoachingTool } from 'features/coachingTool/types';

type TUseCoachingToolQuery = {
  toolId: string;
};

export const useCoachingToolQuery = ({ toolId }: TUseCoachingToolQuery) => {
  const { request } = useQueryHTTPRequest();

  return useQuery<{ items: ICoachingTool }, unknown, ICoachingTool>(
    [queryKeys.coachingTools, toolId],
    () => request({ url: `/api/coaching-tools/${toolId}` })
  );
};
