import { useQuery, UseQueryOptions } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import {
  IPlanningDraftSprint,
  useCreatePlanningDraftSprintInstanceWithWeeksCount,
} from 'features/planning';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

const mapPlanningDraftSprintsQueryData = (
  sprints: IPlanningDraftSprint[],
  createDraftSprint: (sprint: IPlanningDraftSprint) => IPlanningDraftSprint
): IPlanningDraftSprint[] => sprints.map((sprint) => createDraftSprint(sprint));

export const usePlanningDraftSprintsQuery = <T>({
  options,
}: {
  options?: Omit<
    UseQueryOptions<IPlanningDraftSprint[], unknown, T, 'draft-sprints'>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const { request } = useQueryHTTPRequest();
  const { userId } = useCurrentModeUser();
  const createDraftSprintWithWeeksCount =
    useCreatePlanningDraftSprintInstanceWithWeeksCount();
  const { select, ...otherOptions } = options || {};

  return useQuery<{ items: IPlanningDraftSprint[] }, unknown, T>(
    [queryKeys.draftSprints],
    () => request({ url: `/api/web/draft-sprints?user_id=${userId}` }),
    {
      // @ts-ignore
      select: (data) => {
        let selectedData = mapPlanningDraftSprintsQueryData(
          data?.items,
          createDraftSprintWithWeeksCount
        );

        return select ? select(selectedData) : selectedData;
      },
      staleTime: 1000 * 20,
      ...otherOptions,
    }
  );
};
