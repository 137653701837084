import { useMutation } from 'react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';

type TUseAddChallengesToSprintMutation = {
  sprintId: string;
  options: any;
};

export const useAddChallengesToSprintMutation = ({
  sprintId,
  options,
}: TUseAddChallengesToSprintMutation) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    async (challenges: (IRepeatedChallenge | INonRepeatedChallenge)[]) =>
      await request({
        url: `/api/web/sprints/${sprintId}/challenges/create`,
        body: { challenges },
      }),
    { ...options }
  );
};
