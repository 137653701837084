import { ReactNode, useState } from 'react';

import { Box } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  ICoachingToolProps,
  CoachingToolCardHeader,
  CoachingToolCardTitle,
  CoachingToolCardDescription,
  CoachingToolImage,
} from 'features/coachingTool';

type TCoachingToolCard = Pick<ICoachingToolProps, 'name' | 'description'> & {
  url?: string;
  children: ReactNode;
};

export const CoachingToolCard = ({
  name,
  description,
  url,
  children,
}: TCoachingToolCard) => {
  const [areChildrenVisible, setAreChildrenVisible] = useState(false);

  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: areChildrenVisible
          ? 'var(--bgCompSecondaryMid)'
          : 'var(--bgCompPrimary)',
        borderRadius: 'var(--border-radius)',
        position: 'relative',
      }}
      //@ts-ignore
      onMouseEnter={() => setAreChildrenVisible(true)}
      onMouseLeave={() => setAreChildrenVisible(false)}
    >
      <Spacer size="sm">
        <HotjarSuppression>
          <Flex
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap="0.25rem"
          >
            <CoachingToolImage imageUrl={url} />
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              gap="0.125rem"
              width="100%"
            >
              <CoachingToolCardHeader />
              <CoachingToolCardTitle title={name} />
              <CoachingToolCardDescription description={description} />
            </Flex>
            <Box style={{ display: areChildrenVisible ? 'block' : 'none' }}>
              {children}
            </Box>
          </Flex>
        </HotjarSuppression>
      </Spacer>
    </Box>
  );
};
