import { lazy, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { HTTP_ERROR_STATUS_CODES } from 'api/config';
import RoleProtectedRoute from 'app/RoleProtectedRoute';
import {
  ParticipantRouterErrorBoundary,
  RootRouterErrorBoundary,
} from 'app/error';
import Layout from 'app/layout/components/Layout/Layout';
import {
  LayoutPageContent,
  LayoutPageSidebar,
} from 'app/layout/components/page';
import NotFoundPage from 'app/pages/NotFound';
import { SeoTitle } from 'app/seo/components/Title';
import { CoachSearchPageWithFeatureFlag } from 'featureFlags/components/CoachSearchPageWithFeatureFlag';
import { PlanningWithFeatureFlag } from 'featureFlags/components/PlanningFeatureFlag';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { routeNames } from 'shared_DEPRECATED/utils/config';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { AuthProvider } from 'features/auth/providers/AuthProvider';
import { CurrentSprintProvider } from 'features/sprint/context/currentSprint';
import { UserProvider } from 'features/user/context';

const ParticipantDashboardPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/Assessments/Assessments'),
    'ParticipantDashboard'
  )
);

const ParticipantSessionsPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/participant/Sessions'),
    'ParticipantSessions'
  )
);

const ParticipantDataTrackerPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/DataTracker'), 'DataTracker')
);
const ParticipantSprintPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/coach/ParticipantSprint'),
    'ParticipantSprint'
  )
);

const ForgotPasswordPage = lazy(
  () => lazyRetry(() => import('app/pages/ForgotPassword')),
  'ForgotPasswordPage'
);

const EmailCheckPage = lazy(
  () => lazyRetry(() => import('app/pages/EmailCheck')),
  'EmailCheckPage'
);

const ParticipantsPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Participants'), 'Participants')
);
const DashboardPage = lazy(() =>
  lazyRetry(() => import('app/pages/Assessments'), 'Assessments')
);

const CoachSettingsPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Settings/Settings'), 'CoachSettings')
);

const ParticipantDashboardPageWrapper = lazy(() =>
  lazyRetry(() => import('app/pages/participant/Dashboard'), 'Dashboard')
);
const CoachClientDashboardPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/coach/ParticipantDashboard'),
    'CoachClientDashboard'
  )
);
const SharedDashboardPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/participant/SharedDashboard'),
    'SharedDashboard'
  )
);
const MySprintsContent = lazy(() =>
  lazyRetry(
    () => import('features/sprint/components/Content/MySprintsContent'),
    'MySprintsContent'
  )
);
const SharedSprintsContent = lazy(() =>
  lazyRetry(
    () => import('features/sprint/components/Content/SharedSprintsContent'),
    'SharedSprintsContent'
  )
);
const SprintForm = lazy(() =>
  lazyRetry(
    () => import('features/sprint/components/Form/SprintForm'),
    'SprintForm'
  )
);
const UsersPage = lazy(() =>
  lazyRetry(() => import('app/pages/admin/Users'), 'Users')
);
const CoachSidebar = lazy(() =>
  lazyRetry(
    () => import('app/layout/components/Layout/CoachSidebar'),
    'CoachSidebar'
  )
);

const SharedLayoutSidebar = lazy(() =>
  lazyRetry(
    () => import('app/layout/components/Layout/SharedSidebar'),
    'SharedSidebar'
  )
);

const ParticipantLayoutSidebar = lazy(() =>
  lazyRetry(
    () => import('app/layout/components/Layout/ParticipantSidebar'),
    'ParticipantSidebar'
  )
);

const GroupDetailsPage = lazy(() =>
  lazyRetry(() => import('app/pages/participant/GroupDetails'), 'GroupDetails')
);

const CoachSessionsPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Sessions/Sessions'), 'CoachSessions')
);
const CoachSessionPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Sessions/Session'), 'CoachSession')
);

const Goals = lazy(() =>
  lazyRetry(() => import('app/pages/Goals/Goals'), 'Goals')
);

const GoalPage = lazy(() =>
  lazyRetry(() => import('app/pages/Goal/Goal'), 'Goal')
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      retry: (failureCount, error) => {
        if (error?.message === HTTP_ERROR_STATUS_CODES.NOT_FOUND) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

const AppProviders = () => (
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <Outlet />
      </UserProvider>
    </QueryClientProvider>
  </AuthProvider>
);

const appRoutes = (
  <Route element={<AppProviders />} errorElement={<RootRouterErrorBoundary />}>
    <Route path="/" element={<Layout />}>
      <Route
        path="reset-password"
        element={
          <>
            <SeoTitle title="Reset Password" />
            <Suspense fallback={<Loader />}>
              <ForgotPasswordPage />
            </Suspense>
          </>
        }
      />
      <Route
        path="reset-password/:email"
        element={
          <>
            <SeoTitle title="Check Email" />
            <Suspense fallback={<Loader />}>
              <EmailCheckPage />
            </Suspense>
          </>
        }
      />
      <Route
        path={routeNames.ADMIN}
        element={<RoleProtectedRoute role={USER_TYPES.ADMIN} />}
      >
        <Route
          index
          element={
            <>
              <SeoTitle title="hOS Users" />
              <Suspense fallback={<Loader />}>
                <UsersPage />
              </Suspense>
            </>
          }
        />
      </Route>
      <Route
        path={routeNames.COACH}
        element={
          <RoleProtectedRoute role={[USER_TYPES.COACH, USER_TYPES.ADMIN]} />
        }
      >
        <Route
          path="settings"
          element={
            <>
              <SeoTitle title="Coach Settings" />
              <Suspense fallback={<Loader />}>
                <CoachSettingsPage />
              </Suspense>
            </>
          }
        />
        <Route index element={<Navigate to="participants" />} />
        <Route
          path="participants"
          element={
            <>
              <SeoTitle title="Coach Participants" />
              <Suspense fallback={<Loader />}>
                <ParticipantsPage />
              </Suspense>
            </>
          }
        />
        <Route
          path="participants/:email"
          element={
            <Suspense fallback={<Loader />}>
              <CurrentSprintProvider>
                <CoachClientDashboardPage />
              </CurrentSprintProvider>
            </Suspense>
          }
          errorElement={<ParticipantRouterErrorBoundary />}
        >
          <Route
            path="dashboard"
            element={
              <>
                <SeoTitle title="Participant Dashboard" />
                <LayoutPageSidebar>
                  <Suspense fallback={<Loader />}>
                    <CoachSidebar />
                  </Suspense>
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <Suspense fallback={<Loader />}>
                    <DashboardPage />
                  </Suspense>
                </LayoutPageContent>
              </>
            }
          />
          <Route
            path="search"
            element={
              <>
                <SeoTitle title="Participant Search" />
                <LayoutPageSidebar>
                  <Suspense fallback={<Loader />}>
                    <CoachSidebar />
                  </Suspense>
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <Suspense fallback={<Loader />}>
                    <CoachSearchPageWithFeatureFlag />
                  </Suspense>
                </LayoutPageContent>
              </>
            }
          />
          <Route
            path="sessions"
            element={
              <>
                <SeoTitle title="Participant Sessions" />
                <Suspense fallback={<Loader />}>
                  <CoachSessionsPage />
                </Suspense>
              </>
            }
          />
          <Route
            path="sessions/:sessionId"
            element={
              <>
                <SeoTitle title="Participant Session Details" />
                <Suspense fallback={<Loader />}>
                  <CoachSessionPage />
                </Suspense>
              </>
            }
          />
          <Route
            path={routeNames.PLANNING}
            element={
              <>
                <SeoTitle title="Participant Planning" />
                <LayoutPageSidebar>
                  <Suspense fallback={<Loader />}>
                    <CoachSidebar />
                  </Suspense>
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <Suspense fallback={<Loader />}>
                    <CurrentSprintProvider>
                      <PlanningWithFeatureFlag />
                    </CurrentSprintProvider>
                  </Suspense>
                </LayoutPageContent>
              </>
            }
          />
          <Route
            path={routeNames.GOALS}
            element={
              <>
                <SeoTitle title="Participant Goals" />
                <LayoutPageSidebar>
                  <Suspense fallback={<Loader />}>
                    <CoachSidebar />
                  </Suspense>
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <Suspense fallback={<Loader />}>
                    <Goals />
                  </Suspense>
                </LayoutPageContent>
              </>
            }
          />
          <Route
            path={`${routeNames.GOALS}/:goalId`}
            element={
              <>
                <SeoTitle title="Participant Goal Details" />
                <Suspense fallback={<Loader />}>
                  <GoalPage />
                </Suspense>
              </>
            }
          />
          <Route
            path="data-tracker"
            element={
              <>
                <SeoTitle title="Participant Data Tracker" />
                <Suspense fallback={<Loader />}>
                  <ParticipantDataTrackerPage />
                </Suspense>
              </>
            }
          />
          <Route
            path=":sprintId/edit"
            exact
            element={
              <>
                <SeoTitle title="Participant Sprint Edit" />
                <Suspense fallback={<Loader />}>
                  <CurrentSprintProvider>
                    <SprintForm />
                  </CurrentSprintProvider>
                </Suspense>
              </>
            }
          />
          <Route
            path=":sprintId"
            element={
              <>
                <SeoTitle title="Participant Sprint Details" />
                <Suspense fallback={<Loader />}>
                  <ParticipantSprintPage />
                </Suspense>
              </>
            }
          />
        </Route>
      </Route>
      <Route
        path={routeNames.DASHBOARD}
        element={
          <Suspense fallback={<Loader />}>
            <CurrentSprintProvider>
              <ParticipantDashboardPageWrapper />
            </CurrentSprintProvider>
          </Suspense>
        }
      >
        <Route
          path=":sprintId"
          element={
            <>
              <SeoTitle title="My Sprint Details" />
              <LayoutPageSidebar>
                <Suspense fallback={<Loader />}>
                  <ParticipantLayoutSidebar />
                </Suspense>
              </LayoutPageSidebar>
              <LayoutPageContent>
                <Suspense fallback={<Loader />}>
                  <MySprintsContent />
                </Suspense>
              </LayoutPageContent>
            </>
          }
        />
        <Route
          path=":sprintId/edit"
          element={
            <>
              <SeoTitle title="My Sprint Edit" />
              <Suspense fallback={<Loader />}>
                <SprintForm />
              </Suspense>
            </>
          }
        />
      </Route>
      <Route
        path="sessions"
        element={
          <>
            <SeoTitle title="My Coach Sessions" />
            <Suspense fallback={<Loader />}>
              <ParticipantSessionsPage />
            </Suspense>
          </>
        }
      />
      <Route
        path="dashboard"
        element={
          <>
            <SeoTitle title="My Dashboard" />
            <Suspense fallback={<Loader />}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  height: '100%',
                  width: '100%',
                }}
              >
                <LayoutPageSidebar>
                  <ParticipantLayoutSidebar />
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <ParticipantDashboardPage />
                </LayoutPageContent>
              </Box>
            </Suspense>
          </>
        }
      />
      <Route
        path={routeNames.PLANNING}
        element={
          <>
            <SeoTitle title="My Planning" />
            <Suspense fallback={<Loader />}>
              <Flex justifyContent="flex-start" width="100%" height="100%">
                <LayoutPageSidebar>
                  <ParticipantLayoutSidebar />
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <CurrentSprintProvider>
                    <PlanningWithFeatureFlag />
                  </CurrentSprintProvider>
                </LayoutPageContent>
              </Flex>
            </Suspense>
          </>
        }
      />
      <Route
        path={routeNames.GOALS}
        element={
          <>
            <SeoTitle title="My Goals" />
            <Suspense fallback={<Loader />}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  height: '100%',
                  width: '100%',
                }}
              >
                <LayoutPageSidebar>
                  <ParticipantLayoutSidebar />
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <Goals />
                </LayoutPageContent>
              </Box>
            </Suspense>
          </>
        }
      />
      <Route
        path={`${routeNames.GOALS}/:goalId`}
        element={
          <>
            <SeoTitle title="My Goal Details" />
            <Suspense fallback={<Loader />}>
              <GoalPage />
            </Suspense>
          </>
        }
      />
      <Route
        path="groups/:groupId"
        element={
          <>
            <SeoTitle title="My Group" />
            <Suspense fallback={<Loader />}>
              <GroupDetailsPage />
            </Suspense>
          </>
        }
      />
      <Route
        path={`${routeNames.CONNECTIONS}/:email`}
        element={
          <Suspense fallback={<Loader />}>
            <CurrentSprintProvider>
              <SharedDashboardPage />
            </CurrentSprintProvider>
          </Suspense>
        }
      >
        <Route
          path="sprints/:sprintId"
          element={
            <>
              <SeoTitle title="My Group Mate Sprint" />
              <Suspense fallback={<Loader />}>
                <LayoutPageSidebar>
                  <SharedLayoutSidebar />
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <SharedSprintsContent />
                </LayoutPageContent>
              </Suspense>
            </>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <>
            <SeoTitle title="Not Found Page" />
            <NotFoundPage />
          </>
        }
      />
    </Route>
  </Route>
);

export default appRoutes;
