import { lazy, Suspense, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { modal, SIZES } from 'shared/components/Modal';

const GoalDeleteConfirmationDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/goal/components/Button/Delete/Dialog'),
    'GoalDeleteDialog'
  )
);

export const useOpenDeleteGoalModal = () =>
  useCallback(({ onSubmit }) => {
    modal.open({
      content: (
        <Suspense fallback={<Loader size="sm" />}>
          <GoalDeleteConfirmationDialogContent onSubmit={onSubmit} />
        </Suspense>
      ),
      props: {
        size: SIZES.MD,
      },
    });
  }, []);
