import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  usePlanningDraftSprintDelete,
  IPlanningDraftSprint,
} from 'features/planning';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

type TPlanningDraftSprintDeleteButton = {
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintDeleteButton = ({
  sprint,
}: TPlanningDraftSprintDeleteButton) => {
  const onDeleteButtonClick = usePlanningDraftSprintDelete({ sprint });

  return (
    <ButtonIcon
      ariaLabel="delete"
      icon={
        <Icon
          name={iconNames.binFilled}
          width={16}
          height={16}
          fill="var(--fgPrimary)"
        />
      }
      dataTestid="delete-btn"
      variant={VARIANTS.TERTIARY}
      onClick={onDeleteButtonClick}
    />
  );
};
