import { ToastOptions } from 'react-toastify';

import styles from 'app/App.module.css';

export const toastConfig = {
  className: styles.toast,
  bodyClassName: styles['toast-body'],
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: 'dark',
} as ToastOptions;

export const toastErrorConfig = { ...toastConfig, icon: false };
