import { Suspense, lazy, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { SIZES, modal } from 'shared/components/Modal';

const ChallengeDialogContent = lazy(() =>
  lazyRetry(
    () =>
      import(
        'features/challenge/components/CreateDialog/DialogContentWithBackButton'
      ),
    'ChallengeDialogContentWithBackButton'
  )
);

export const useOpenChallengeModalWithBackButton = () => {
  return useCallback(
    ({
      challenge,
      onBackBtnClick,
      onChallengeSubmit,
      sprint,
      title,
      shouldCloseOnSubmit,
    }) => {
      modal.open({
        content: (
          <Suspense
            fallback={<Loader dataTestid="challenge-dialog-loader" size="sm" />}
          >
            <ChallengeDialogContent
              currentChallenge={challenge}
              onBackBtnClick={onBackBtnClick}
              onChallengeSubmit={onChallengeSubmit}
              shouldCloseOnSubmit={shouldCloseOnSubmit}
            />
          </Suspense>
        ),
        context: { sprint },
        props: {
          size: SIZES.MD,
          title: title || challenge.title,
        },
      });
    },
    []
  );
};
