import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalBadge,
  GoalTitle,
  GoalBackButton,
  GoalEditButton,
  GoalDeleteButton,
  GoalCardType,
  GoalImage,
} from 'features/goal';
import { LifeDimensions } from 'features/lifeDimensions/components/LifeDimensions';

export const GoalHeader = ({
  goalId,
  columnId,
  columnLabel,
  name,
  dimensions,
  deadline,
  deepDive,
  completedAt,
  imageUrl,
  note,
}: GoalCardType) => {
  return (
    <Flex
      as="header"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      dataTestid="goal-header"
    >
      <Flex flexDirection="column" alignItems="flex-start" width="100%">
        <GoalBackButton />
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex>
            <Spacer size="lg lg zr zr">
              <GoalImage size="md" imageUrl={imageUrl} />
            </Spacer>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              height="100%"
              gap="1rem 0"
            >
              <GoalTitle title={name} />
              <LifeDimensions dimensions={dimensions} />
              <GoalBadge
                deadline={deadline}
                years={columnLabel}
                completedAt={completedAt}
              />
            </Flex>
          </Flex>
          <Flex>
            <Spacer size="md" />
            <GoalEditButton
              name={name}
              dimensions={dimensions}
              deadline={deadline}
              deepDive={deepDive}
              goalId={goalId}
              columnId={columnId}
              columnLabel={columnLabel}
              completedAt={completedAt}
              note={note}
            />
            <Spacer size="md" />
            <GoalDeleteButton goalId={goalId} columnId={columnId} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
