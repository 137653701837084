import PropTypes from 'prop-types';

import { Button, VARIANTS } from 'shared';
import { Icon } from 'shared_DEPRECATED/components/Icon';

const CreateChallengeButton = ({ onCreateChallengeBtnClick }) => (
  <Button
    leftIcon={<Icon name="plus" width={12} height={12} />}
    onClick={onCreateChallengeBtnClick}
    variant={VARIANTS.SECONDARY}
    size="sm"
    dataTestid="create-challenges-button"
  >
    Create challenge
  </Button>
);

CreateChallengeButton.propTypes = {
  onCreateChallengeBtnClick: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
};

export default CreateChallengeButton;
