import Linkify from 'linkify-react';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SessionsFormCoachingToolsSection } from 'features/sessions/components/Form/CoachingTools/Section';
import { SessionsMiroIframe } from 'features/sessions/components/MiroIframe';
import { getMiroBoardId } from 'features/sessions/utils';

import { Typography } from 'shared/components/Typography';

export const SessionsFormCoachingToolsLink = ({ link }: { link: string }) => {
  const miroBoardId = getMiroBoardId(link);

  return (
    <>
      {miroBoardId ? (
        <SessionsFormCoachingToolsSection>
          <Box style={{ height: '24rem' }}>
            <SessionsMiroIframe boardId={miroBoardId} />
          </Box>
          <Spacer size="sm zr" />
        </SessionsFormCoachingToolsSection>
      ) : (
        <SessionsFormCoachingToolsSection>
          <Typography type="meta" fontWeight="semiBold">
            Tool description link
          </Typography>
          <Spacer size="md zr" />
          <Linkify options={{ target: '_blank' }}>{link}</Linkify>
        </SessionsFormCoachingToolsSection>
      )}
    </>
  );
};
