import { useParams } from 'react-router-dom';

import { SPRINT_STATES } from 'features/sprint/config';
import useSprintQuery from 'features/sprint/hooks/query/useSprint';

const SprintBreadcrumb = () => {
  const { sprintId, email } = useParams();

  const { data: sprint } = useSprintQuery({
    userId: sprintId === SPRINT_STATES.DRAFT ? email : '',
    sprintId,
  });

  return <span>{sprint?.title ?? ''}</span>;
};

export default SprintBreadcrumb;
