import PropTypes from 'prop-types';

import {
  Card,
  CardBody,
  CardContainer,
  CardHeader,
} from 'shared_DEPRECATED/components/Card';
import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import {
  datePropValidator,
  emailPropValidator,
} from 'shared_DEPRECATED/utils/propsValidators';

import { ChallengeTargetType } from 'features/challenge/config/propTypes';
import { SPRINT_HISTORY_CHANGE_TYPES } from 'features/sprint/config';

import { SidebarSection } from 'shared/components/Sidebar';
import { Typography } from 'shared/components/Typography';

import {
  ChallengeAdded,
  ChallengeFrequencyChanged,
  ChallengeRemoved,
  ChallengeTitleChanged,
  SprintTitleChanged,
} from './items';

const historyItems = {
  [SPRINT_HISTORY_CHANGE_TYPES.SPRINT_CHALLENGE_FREQUENCY_CHANGED]:
    ChallengeFrequencyChanged,
  [SPRINT_HISTORY_CHANGE_TYPES.SPRINT_CHALLENGE_ADDED]: ChallengeAdded,
  [SPRINT_HISTORY_CHANGE_TYPES.SPRINT_CHALLENGE_REMOVED]: ChallengeRemoved,
  [SPRINT_HISTORY_CHANGE_TYPES.SPRINT_TITLE_CHANGED]: SprintTitleChanged,
  [SPRINT_HISTORY_CHANGE_TYPES.SPRINT_CHALLENGE_TITLE_CHANGED]:
    ChallengeTitleChanged,
};
const SprintHistoryItemPropTypes = {
  userEmail: emailPropValidator,
  userPictureUrl: PropTypes.string,
  userName: PropTypes.string,
  date: datePropValidator,
  type: PropTypes.oneOf(Object.keys(SPRINT_HISTORY_CHANGE_TYPES)),
  challengeTitle: PropTypes.string,
  before: PropTypes.oneOfType([PropTypes.string, ChallengeTargetType]),
  after: PropTypes.oneOfType([PropTypes.string, ChallengeTargetType]),
};

const SprintHistoryItem = ({
  userEmail,
  userPictureUrl,
  userName,
  date,
  type,
  challengeTitle = '',
  before = '',
  after = '',
}) => {
  const SprintChangeComponent = historyItems[type];

  return (
    <FlexItem as="li">
      <SidebarSection>
        <Card>
          <CardHeader
            date={date}
            pictureUrl={userPictureUrl}
            userName={userName || userEmail}
          />
          <CardContainer>
            <CardBody>
              <Typography
                as="div"
                type="small"
                color="gray"
                fontWeight="regular"
              >
                <SprintChangeComponent
                  challengeTitle={challengeTitle}
                  before={before}
                  after={after}
                />
              </Typography>
            </CardBody>
          </CardContainer>
        </Card>
      </SidebarSection>
    </FlexItem>
  );
};

SprintHistoryItem.propTypes = SprintHistoryItemPropTypes;

export default SprintHistoryItem;
