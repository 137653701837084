import PropTypes from 'prop-types';
import { useState } from 'react';

import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { NOTE_STATUS, NOTE_TYPES } from 'features/challengeNotes/config';
import { useNoteStatusMutation } from 'features/challengeNotes/hooks';

const MenuReadStatusButtonPropTypes = {
  noteId: PropTypes.string.isRequired,
  noteType: PropTypes.oneOf(Object.values(NOTE_TYPES)).isRequired,
  userEmail: PropTypes.string.isRequired,
  noteReadStatus: PropTypes.oneOf(Object.values(NOTE_STATUS)).isRequired,
  dataTestid: PropTypes.string,
};
export const MenuReadStatusButton = ({
  noteId,
  noteType,
  noteReadStatus,
  userEmail,
  dataTestid = null,
}) => {
  const [readStatus, setReadStatus] = useState(noteReadStatus);
  const readStatusMutation = useNoteStatusMutation({
    noteId,
    userEmail,
    onSuccess: setReadStatus,
  });

  const handleReadStatusMutation = () =>
    readStatusMutation.mutate({
      currentStatus: readStatus,
      noteType,
    });

  return (
    <OptionsMenuButton
      onClick={handleReadStatusMutation}
      dataTestid={dataTestid}
    >
      {readStatus === NOTE_STATUS.UNREAD ? 'Mark as read' : 'Mark as unread'}
    </OptionsMenuButton>
  );
};

MenuReadStatusButton.propTypes = MenuReadStatusButtonPropTypes;
