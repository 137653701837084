import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { CoachingToolCard, CoachingToolCardMenu } from 'features/coachingTool';
import {
  PlanningDraftSprintHeader,
  PlanningBoardItems,
  canItemBeDroppedInDropZone,
  IPlanningDraftSprint,
  PlanningBoardColumn,
  PlanningBoardItemsWrapper,
} from 'features/planning';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
} from 'shared/components/Board';

export const PlanningDraftSprints = ({
  sprints,
}: {
  sprints: IPlanningDraftSprint[];
}) => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);

  return (
    <>
      {sprints.map((sprint) => (
        <PlanningBoardColumn key={sprint.sprintId}>
          <BoardColumnWrapper fraction={sprints.length} key={sprint.sprintId}>
            <BoardColumnHeader key={sprint.sprintId} minHeight="3.75rem">
              <PlanningDraftSprintHeader
                title={sprint.title!}
                sprint={sprint}
              />
            </BoardColumnHeader>
            <BoardColumn>
              {sprint.challenges.length && (
                <PlanningBoardItemsWrapper
                  type="challenge"
                  itemsCount={sprint.challenges.length}
                >
                  <PlanningBoardItems
                    challenges={sprint.challenges}
                    canDrop={canItemBeDroppedInDropZone(
                      'sprint',
                      sprint.sprintId
                    )}
                    sprint={sprint}
                  />
                </PlanningBoardItemsWrapper>
              )}
              {isCoachingToolsEnabled && sprint.coachingTools.length && (
                <PlanningBoardItemsWrapper
                  type="coaching tool"
                  itemsCount={sprint.coachingTools.length}
                >
                  {sprint.coachingTools.map(
                    ({ name, description, picture, id }) => (
                      <Spacer size="sm">
                        <CoachingToolCard
                          key={id}
                          name={name}
                          description={description}
                          url={picture?.url}
                        >
                          <CoachingToolCardMenu
                            toolId={id}
                            sprintId={sprint.sprintId}
                            sprintState={sprint.state}
                          />
                        </CoachingToolCard>
                      </Spacer>
                    )
                  )}
                </PlanningBoardItemsWrapper>
              )}
            </BoardColumn>
          </BoardColumnWrapper>
        </PlanningBoardColumn>
      ))}
    </>
  );
};
