import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { NonRepeatedChallenge } from 'features/challenge/NonRepeatedChallenge';
import { RepeatedChallenge } from 'features/challenge/RepeatedChallenge';
import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

type ChallengeType = IRepeatedChallenge | INonRepeatedChallenge;

const mapPlanningBacklogQueryData = (
  challenges: ChallengeType[]
): ChallengeType[] =>
  challenges.map((challenge) =>
    (challenge as INonRepeatedChallenge).target?.dueDate
      ? new NonRepeatedChallenge(challenge as INonRepeatedChallenge)
      : new RepeatedChallenge(challenge as IRepeatedChallenge)
  );

export const usePlanningBacklogQuery = () => {
  const { request } = useQueryHTTPRequest();
  const { userId } = useCurrentModeUser();

  return useQuery<{ items: ChallengeType[] }, unknown, ChallengeType[]>(
    [queryKeys.challenges],
    () =>
      request({
        url: `/api/sprint-planning/backlog/challenges?user_id=${userId}`,
      }),
    {
      select: (data) => mapPlanningBacklogQueryData(data?.items),
    }
  );
};
