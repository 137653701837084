import { lazy, Suspense, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { modal, SIZES } from 'shared/components/Modal';

const SprintStartConfirmationDialogContent = lazy(() =>
  lazyRetry(
    () =>
      import('features/sprint/components/Calendar/Header/StartButton/Dialog'),
    'SprintStartConfirmationDialogContent'
  )
);

export const useOpenStartSprintModal = () => {
  return useCallback(({ title, onSubmit }) => {
    modal.open({
      content: (
        <Suspense
          fallback={
            <Loader dataTestid="start-sprint-dialog-loader" size="sm" />
          }
        >
          <SprintStartConfirmationDialogContent
            onSubmit={({ title }: { title: string }) => {
              onSubmit({ title });
            }}
            title={title}
          />
        </Suspense>
      ),
      props: {
        size: SIZES.SM,
        title: 'Start sprint',
      },
    });
  }, []);
};
