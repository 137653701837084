import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const SprintTitleChangedPropTypes = {
  before: PropTypes.string.isRequired,
  after: PropTypes.string.isRequired,
};

const SprintTitleChanged = ({
  before: sprintTitleBefore,
  after: sprintTitleAfter,
}) => (
  <Typography>
    Changed sprint title from{' '}
    <Box as="span" style={{ backgroundColor: 'var(--bgCompSecondaryMid)' }}>
      <Spacer as="span" size="zr zr zr sm" />
      <Typography as="strong" fontWeight="medium" color="black">
        {sprintTitleBefore}
      </Typography>
      <Spacer as="span" size="zr sm zr zr" />
    </Box>{' '}
    to{' '}
    <Box as="span" style={{ backgroundColor: 'var(--bgCompMinorSuccess)' }}>
      <Spacer as="span" size="zr zr zr sm" />
      <Typography as="strong" fontWeight="medium" color="black">
        {sprintTitleAfter}
      </Typography>
      <Spacer as="span" size="zr sm zr zr" />
    </Box>
  </Typography>
);

SprintTitleChanged.propTypes = SprintTitleChangedPropTypes;

export default SprintTitleChanged;
