import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { TabPanel } from 'shared_DEPRECATED/components/Tabs';

export const ChallengeNotesTabPanel = ({ children }) => {
  return (
    <Box style={{ height: 'var(--tabs-panel-height)', overflow: 'auto' }}>
      <TabPanel>{children}</TabPanel>
    </Box>
  );
};

ChallengeNotesTabPanel.propTypes = { children: PropTypes.node.isRequired };
