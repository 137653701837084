import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import {
  useRemovePlanningDraftSprintChallengesMutation,
  IPlanningDraftSprint,
} from 'features/planning';

import { Typography } from 'shared/components/Typography';

type TPlanningDraftSprintMenuRemoveButton = {
  challengeId: string;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintMenuRemoveButton = ({
  challengeId,
  sprint,
}: TPlanningDraftSprintMenuRemoveButton) => {
  const { mutateAsync: removeSprintChallenges } =
    useRemovePlanningDraftSprintChallengesMutation({
      sprint,
    });

  return (
    <OptionsMenuButton onClick={() => removeSprintChallenges([challengeId])}>
      <Typography color="red">Remove</Typography>
    </OptionsMenuButton>
  );
};
