import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';

import { IPlanningDraftSprint, PlanningDraftSprint } from 'features/planning';

export const useCreatePlanningDraftSprintInstanceWithWeeksCount = () => {
  const weeksCount = useSprintDurationFeatureFlag();

  return (sprint: IPlanningDraftSprint): PlanningDraftSprint =>
    new PlanningDraftSprint({ ...sprint, weeksCount });
};
