import { MenuItemOption, MenuItemOptionProps } from '@chakra-ui/react';

import { forwardRef } from '@chakra-ui/system';

//explanation of the syntax  https://github.com/chakra-ui/chakra-ui/issues/5011
// MenuItemOption need to be wrapped directly with MenuOptionGroup ChakraUI provided component
export const DropdownOption = forwardRef<MenuItemOptionProps, 'button'>(
  ({ children, ...props }, ref) => (
    <MenuItemOption ref={ref} {...props}>
      {children}
    </MenuItemOption>
  )
);

DropdownOption.id = 'MenuItemOption';
