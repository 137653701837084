import { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';

import { SprintHistory } from 'features/sprint/components/History';

import { sidebar } from 'shared/components/Sidebar';

import { useSprintAnalytics } from './useSprintAnalytics';

export const useHistorySidebar = () => {
  const { sprint, sprints } = useOutletContext();

  const { sendHistorySidebarOpenedEvent } = useSprintAnalytics();

  const openHistorySidebar = useCallback(() => {
    sidebar.open({
      content: <SprintHistory />,
      props: { title: 'Sprint History Log' },
      context: { currentSprintId: sprint.sprintId, sprints },
    });
    sendHistorySidebarOpenedEvent();
  }, [sprint.sprintId, sprints, sendHistorySidebarOpenedEvent]);

  return { openHistorySidebar };
};
