import { useMutation } from 'react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

export const useNoteReactionsMutation = ({
  noteId,
  userEmail,
  onMutate,
  onError,
}) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    async ({ emoji, type }) =>
      request({
        url: `/api/challenges/notes/${noteId}/reactions/${type.toLowerCase()}`,
        body: {
          emoji,
          ownerEmail: userEmail,
        },
      }),
    {
      onMutate,
      onError: (err, __, context) => {
        onError(err, __, context);
        logError(err);
      },
    }
  );
};
