import { Checkbox as ChakraCheckbox, UseCheckboxProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

type CheckboxProps = {
  label: string;
  dataTestid?: string;
} & UseCheckboxProps;

const CheckboxIcon = ({ isChecked }: { isChecked: boolean }) => (
  <>{isChecked ? <Icon name={iconNames.checkSmall} /> : null}</>
);

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, dataTestid, ...props }: CheckboxProps, ref) => {
    return (
      <ChakraCheckbox
        ref={ref}
        {...props}
        data-testid={dataTestid}
        icon={
          // @ts-ignore
          <CheckboxIcon />
        }
      >
        {label}
      </ChakraCheckbox>
    );
  }
);
