import { useQuery } from 'react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { DAILY_STATUS_VALUES } from 'shared_DEPRECATED/utils/config';

import { DAILY_STATUS, TARGET_PERIODS } from 'features/challenge/config';
import { ChallengeTargetProgressType } from 'features/challenge/config/types';
import { useMomentumFeedContext } from 'features/momentum/context/feed';
import { getChallengeFrequencyText } from 'features/sprint/utils';

const getNonZeroMomentumItems = ({
  momentumDiff,
  challenge,
  feedDate,
}: {
  momentumDiff: {
    currentValue: number;
    changeValue: number;
  };
  challenge: {
    target: ChallengeTargetProgressType;
    title: string;
  };
  feedDate: string;
}) => ({
  currentValue: momentumDiff.currentValue,
  changeValue: momentumDiff.changeValue,
  challengeFrequency: getChallengeFrequencyText(challenge.target),
  challengeTargetAmount:
    'amount' in challenge.target && challenge.target.amount
      ? `${challenge.target.amount.value} ${challenge.target.amount.unit} per ${
          TARGET_PERIODS[
            challenge.target.period!.toUpperCase() as keyof typeof TARGET_PERIODS
          ]
        }`
      : '',
  challengeTitle: challenge.title,
  challengeStatus: ('status' in challenge.target
    ? challenge.target.status || DAILY_STATUS_VALUES.EMPTY
    : challenge.target.dailyStatus[feedDate]?.status ||
      DAILY_STATUS_VALUES.EMPTY) as DAILY_STATUS,
});

const getZeroMomentumChallenge = ({
  feedDate,
  challenge: { target, title },
}: {
  feedDate: string;
  challenge: {
    target: ChallengeTargetProgressType;
    title: string;
  };
}) => ({
  frequency: getChallengeFrequencyText(target),
  status: ('status' in target
    ? target.status || DAILY_STATUS_VALUES.EMPTY
    : target.dailyStatus[feedDate]?.status ||
      DAILY_STATUS_VALUES.EMPTY) as DAILY_STATUS,
  targetAmount:
    'amount' in target && target.amount
      ? `${target.amount.value} ${target.amount.unit} per ${
          TARGET_PERIODS[
            target.period!.toUpperCase() as keyof typeof TARGET_PERIODS
          ]
        }`
      : '',
  title: title,
});

const selectMomentumFeed = (data: {
  challenges: {
    momentumDifference: Record<
      string,
      {
        currentValue: number;
        changeValue: number;
      }
    >;
    target: ChallengeTargetProgressType;
    title: string;
  }[];
}) =>
  data.challenges.reduce(
    (feed, challenge) => {
      for (const [date, momentumDiff] of Object.entries(
        challenge.momentumDifference
      )) {
        if (!feed[date]) {
          feed[date] = {
            momentumScoreDiff: 0,
            items: [],
            zeroMomentumItems: {
              accumulatedChangeValue: 0,
              challenges: [],
            },
          };
        }

        feed[date].momentumScoreDiff += momentumDiff.changeValue;

        if (momentumDiff.currentValue > 0) {
          const feedItem = getNonZeroMomentumItems({
            momentumDiff,
            challenge,
            feedDate: date,
          });

          feed[date].items.push(feedItem);
        } else {
          const zeroMomentumChallenge = getZeroMomentumChallenge({
            challenge,
            feedDate: date,
          });

          feed[date].zeroMomentumItems = {
            accumulatedChangeValue:
              feed[date].zeroMomentumItems.accumulatedChangeValue +
              momentumDiff.changeValue,
            challenges: [
              ...feed[date].zeroMomentumItems.challenges,
              zeroMomentumChallenge,
            ],
          };
        }
      }

      return feed;
    },
    {} as Record<
      string,
      {
        momentumScoreDiff: number;
        items: {
          currentValue: number;
          changeValue: number;
          challengeFrequency: string;
          challengeTargetAmount: string;
          challengeTitle: string;
          challengeStatus: DAILY_STATUS;
        }[];
        zeroMomentumItems: {
          accumulatedChangeValue: number;
          challenges: {
            frequency: string;
            status: DAILY_STATUS;
            targetAmount: string;
            title: string;
          }[];
        };
      }
    >
  );

export const useMomentumFeedQuery = () => {
  const { sprintId } = useMomentumFeedContext();
  const { request } = useQueryHTTPRequest();

  return useQuery(
    ['momentum', sprintId],
    async () =>
      await request({ url: `/api/sprints/${sprintId}/momentum/feed` }),
    {
      select: selectMomentumFeed,
    }
  );
};
