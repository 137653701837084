import PropTypes from 'airbnb-prop-types';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import CardContainer from './CardContainer';
import CardHeader from './CardHeader';

const Card = ({ children }) => <Spacer size="sm zr">{children}</Spacer>;

Card.propTypes = {
  /**
   * The content of the component.
   * @type {React.ReactNode}
   * @required
   * @name children
   * @example <Card><CardHeader>Header</CardHeader><CardContainer><CardBody>Content</CardBody></CardContainer></Card>
   */
  children: PropTypes.childrenOfType(CardHeader, CardContainer),
};

export default Card;
