import { useFormContext } from 'react-hook-form';

import { FlexItem } from 'shared_DEPRECATED/components/Flex';

import { CoachingToolsCardEditButtons } from 'features/sessions/components';
import { SessionsFormCoachingToolsSelector } from 'features/sessions/components/Form/CoachingTools';
import { LinkFields } from 'features/sessions/components/NextSessionForm';
import { CoachingTool } from 'features/sessions/config/types';

type NextSessionCoachingToolsCardEditProps = {
  formKey: string;
  onDelete: () => void;
  canBeDeleted: boolean;
  coachingTool: CoachingTool;
};

export const NextSessionCoachingToolsCardEdit = ({
  formKey,
  onDelete,
  canBeDeleted = false,
  coachingTool,
}: NextSessionCoachingToolsCardEditProps) => {
  const { setValue } = useFormContext();

  return (
    <>
      <FlexItem alignSelf="stretch">
        <SessionsFormCoachingToolsSelector
          onSelect={(coachingTool) => {
            setValue(`${formKey}.tool`, coachingTool, { shouldDirty: true });
          }}
          selected={coachingTool.tool.value}
        />
      </FlexItem>
      <LinkFields
        formKey={formKey}
        coachingToolValue={coachingTool.tool.value}
      />
      <CoachingToolsCardEditButtons
        canBeDeleted={canBeDeleted}
        disabled={!coachingTool.tool.value}
        onDelete={onDelete}
        onSave={() => setValue(`${formKey}.editMode`, false)}
      />
    </>
  );
};
