interface TextEditorToolbarBaseSelectorProps {
  type: string;
  options: string[];
  defaultValue?: string;
}

export const TextEditorToolbarBaseSelector = ({
  type,
  options,
  defaultValue = '',
}: TextEditorToolbarBaseSelectorProps) => (
  <select className={`ql-${type}`} defaultValue={defaultValue}>
    {options.map((value) => (
      <option value={value} />
    ))}
    <option value="" />
  </select>
);
