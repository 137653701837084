import { Image } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

const IMAGE_SIZES = {
  sm: { image: '3rem', icon: '1.5rem' },
  md: { image: '6.5rem', icon: '3.25rem' },
};

type TGoalImage = {
  imageUrl: string | undefined;
  size?: keyof typeof IMAGE_SIZES;
};

export const GoalImage = ({ imageUrl, size = 'sm' }: TGoalImage) => (
  <Box style={{ minWidth: IMAGE_SIZES[size].image }}>
    {imageUrl ? (
      <Image
        alt={imageUrl}
        boxSize={IMAGE_SIZES[size].image}
        objectFit="cover"
        src={imageUrl}
        data-testId="goal-card-image"
        borderRadius="md"
      />
    ) : (
      <Box
        style={{
          width: IMAGE_SIZES[size].image,
          height: IMAGE_SIZES[size].image,
          backgroundColor: 'var(--bgCompSecondaryMid)',
          borderRadius: 'var(--border-radius)',
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Icon
            width={IMAGE_SIZES[size].icon}
            height={IMAGE_SIZES[size].icon}
            name={iconNames.imagePlaceholder}
            stroke="var(--bgCompPrimary)"
          />
        </Flex>
      </Box>
    )}
  </Box>
);
