import { ButtonIcon, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useAddCustomChallenge } from 'features/planning/hooks';

export const PlanningBacklogAddChallengeButton = () => {
  const openAddChallengeModal = useAddCustomChallenge();

  return (
    <ButtonIcon
      ariaLabel="backlog-add-btn"
      icon={
        <Icon
          name={iconNames.plus}
          width={14}
          height={14}
          fill="var(--fgPrimary)"
        />
      }
      dataTestid="backlog-add-btn"
      variant={VARIANTS.TERTIARY}
      onClick={openAddChallengeModal}
    />
  );
};
