import { toastConfig, toastErrorConfig } from 'lib/reactToastify/config';
import { useFormContext } from 'react-hook-form';
import { toast, ToastOptions } from 'react-toastify';

import { logError } from 'lib/sentry/logError';
import { getHtmlStringWithNestedLists } from 'shared_DEPRECATED/components/TextEditor/utils';

import {
  useSessionAnalyticsEvents,
  useSessionRecapMutation,
} from 'features/sessions/hooks';

export const useSessionRecapSend = () => {
  const { getValues, setValue } = useFormContext();
  const mutation = useSessionRecapMutation(getValues('coachingSessionId'));
  const { sendSessionRecapFinishEvent } = useSessionAnalyticsEvents();

  const onRecapSend = async () => {
    const recapData = getValues('recap');
    const recapPostData = {
      ...recapData,
      content: getHtmlStringWithNestedLists(recapData.content),
      submittedAt: null,
    };

    try {
      const recap = await mutation.mutateAsync(recapPostData);
      sendSessionRecapFinishEvent();
      setValue('recap', recap);

      toast.success('Session recap was successfully sent', {
        icon: false,
        ...toastConfig,
      } as ToastOptions);
    } catch (e) {
      toast.error(
        "There's an error with sending recap. Please try again later",
        toastErrorConfig as ToastOptions
      );
      logError(e);
    }
  };

  return { onRecapSend, isLoading: mutation.isLoading };
};
