import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

type TPlanningBoardWrapper = {
  children: ReactNode;
};

export const PlanningBoardWrapper = ({ children }: TPlanningBoardWrapper) => (
  <Box
    style={{
      height: `calc(100% - ${planningBoardHeaderHeight})`,
      width: '100%',
    }}
    dataTestid="sprint-planning-board"
  >
    <Flex height="100%">{children}</Flex>
  </Box>
);

const planningBoardHeaderHeight = '8.25rem';
