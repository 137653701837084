import { useState } from 'react';

import {
  SprintHistoryContent,
  SprintHistoryHeader,
} from 'features/sprint/components/History';

import { SidebarBody } from 'shared/components/Sidebar';
import { useSidebarContext } from 'shared/components/Sidebar/context';

const SprintHistory = () => {
  const { currentSprintId: sprintId } = useSidebarContext();
  const [selectedSprintId, setSelectedSprintId] = useState(sprintId);

  return (
    <>
      <SprintHistoryHeader
        selectedSprintId={selectedSprintId}
        setSelectedSprintId={setSelectedSprintId}
      />
      <SidebarBody>
        <SprintHistoryContent selectedSprintId={selectedSprintId} />
      </SidebarBody>
    </>
  );
};

export default SprintHistory;
