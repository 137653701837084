import PropTypes from 'prop-types';
import React from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { TabProvider } from './context';

const TabList = ({ children }) => (
  <Spacer size="zr zr lg">
    <Spacer size="zr zr md">
      <Box
        role="tablist"
        style={{
          boxSizing: 'border-box',
          borderBottom: 'var(--border-secondary)',
          height: 'var(--tab-header-height)',
        }}
      >
        <Flex justifyContent="stretch" width="100%">
          {React.Children.map(children, (child, index) => (
            <TabProvider index={index}>{child}</TabProvider>
          ))}
        </Flex>
      </Box>
    </Spacer>
  </Spacer>
);

TabList.propTypes = {
  /**
   * Tab list.
   */
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string,
};

export default TabList;
