import { FormProvider, useForm } from 'react-hook-form';

import { useFormAutosave } from 'shared_DEPRECATED/hooks';

import {
  NextSessionCoachingToolsSection,
  NextSessionDateAndTimeSelector,
  NextSessionNotes,
  ParticipantProfileNote,
} from 'features/sessions/components/NextSessionForm';
import {
  NextSessionSidebarHeader,
  NextSessionUserInfo,
} from 'features/sessions/components/NextSessionForm/Sidebar';
import { NextCoachingSessionAppointment } from 'features/sessions/config/types';
import { useNextSessionUpdate } from 'features/sessions/hooks/useNextSessionUpdate';

type NextSessionCoachFormPropTypes = {
  sessionAppointment: NextCoachingSessionAppointment;
};

export const NextSessionCoachFormWithHeader = ({
  sessionAppointment,
}: NextSessionCoachFormPropTypes) => {
  const formMethods = useForm<NextCoachingSessionAppointment>({
    defaultValues: sessionAppointment,
  });

  const onMutate = useNextSessionUpdate(formMethods);

  useFormAutosave({
    formMethods,
    onMutate,
    storageKey: `nextSession-${sessionAppointment.coachingSessionAppointmentId}`,
  });

  return (
    <FormProvider {...formMethods}>
      <NextSessionSidebarHeader
        onNextSessionSave={formMethods.handleSubmit(onMutate)}
        clientEmail={sessionAppointment.clientEmail}
        coachingSessionId={sessionAppointment.coachingSessionId}
      />
      <NextSessionUserInfo />
      <NextSessionDateAndTimeSelector />
      <NextSessionNotes />
      <NextSessionCoachingToolsSection />
      <ParticipantProfileNote />
    </FormProvider>
  );
};
