import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { logError } from 'lib/sentry/logError';
import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { useChallengeNotesContext } from 'features/challengeNotes/context';
import { useReadAllMutation } from 'features/challengeNotes/hooks/mutation/useReadAll';

import { Typography } from 'shared/components/Typography';

export const ChallengeNotesReadAllButton = () => {
  const { unreadNotesCounter, isFetching } = useChallengeNotesContext();
  const { mutateAsync } = useReadAllMutation();

  if (unreadNotesCounter === 0 || isFetching) {
    return null;
  }

  const handleButtonClick = async () => {
    try {
      await mutateAsync();
    } catch (err) {
      toast.error(
        "There's an error with marking notes as read. Please try again later.",
        {
          icon: false,
          ...toastConfig,
        }
      );
      logError(err);
    }
  };

  return (
    <Box
      style={{
        position: 'absolute',
        right: '1rem',
        top: '0.25rem',
      }}
    >
      <Button
        variant="tertiary"
        onClick={handleButtonClick}
        dataTestid="notes-sidebar-read-all"
      >
        <Spacer size="md">
          <Typography whiteSpace="nowrap" type="small" color="black">
            Read all
          </Typography>
        </Spacer>
      </Button>
    </Box>
  );
};
