import { useChallengeProofsFeatureFlag } from 'featureFlags/hooks/useChallengeProofsFeatureFlag';

import { ChallengeNotesFilter } from 'features/challengeNotes/components/Filter';
import { ChallengeNotesSidebarBody } from 'features/challengeNotes/components/Sidebar/SidebarBody';
import { ChallengeNotesSidebarBodyWithoutTabs } from 'features/challengeNotes/components/Sidebar/SidebarBodyWithoutTabs';
import { ChallengeNotesFilterProvider } from 'features/challengeNotes/context/filter';
import { ChallengeNotesProvider } from 'features/challengeNotes/context/notes';

import { SidebarHeaderWithoutBorder } from 'shared/components/Sidebar';

const ChallengeNotesSidebar = () => {
  const isFeatureEnabled = useChallengeProofsFeatureFlag();

  return (
    <ChallengeNotesFilterProvider>
      <ChallengeNotesProvider>
        <SidebarHeaderWithoutBorder title="Notes">
          <ChallengeNotesFilter />
        </SidebarHeaderWithoutBorder>
        {isFeatureEnabled ? (
          <ChallengeNotesSidebarBodyWithoutTabs />
        ) : (
          <ChallengeNotesSidebarBody />
        )}
      </ChallengeNotesProvider>
    </ChallengeNotesFilterProvider>
  );
};

export default ChallengeNotesSidebar;
