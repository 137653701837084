import { IconButton, IconButtonProps } from '@chakra-ui/react';

import { VARIANTS } from './config';

export type ButtonIconPropTypes = Omit<IconButtonProps, 'aria-label'> & {
  ariaLabel: string;
  variant?: typeof VARIANTS[keyof typeof VARIANTS];
  dataTestid?: string;
};

export const ButtonIcon = ({
  ariaLabel,
  dataTestid,
  stroke,
  variant,
  icon,
  ...props
}: ButtonIconPropTypes) => {
  return (
    <IconButton
      size="sm"
      {...props}
      aria-label={ariaLabel}
      data-testid={dataTestid}
      variant={variant}
      icon={icon}
    />
  );
};
