import { toastConfig } from 'lib/reactToastify/config';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getNoteRepliesQueryKey } from 'api/utils';
import { logError } from 'lib/sentry/logError';

import {
  NOTE_FILTERS,
  NOTE_STATUS,
  PARTICIPANT_NOTES_COUNT_FIELDS_BY_TYPE,
} from 'features/challengeNotes/config';
import { useChallengeNotesFilterContext } from 'features/challengeNotes/context';
import { useChallengeNotesQueryKey } from 'features/challengeNotes/hooks/useQueryKey';

export const useNoteStatusMutation = ({ noteId, userEmail, onSuccess }) => {
  const { filter } = useChallengeNotesFilterContext();
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const getUpdatedStatus = (currentStatus) =>
    currentStatus === NOTE_STATUS.UNREAD
      ? NOTE_STATUS.READ
      : NOTE_STATUS.UNREAD;
  const challengeNotesQueryKey = useChallengeNotesQueryKey();

  const updateNotesQuery = (status) => {
    const notesQueryData = queryClient.getQueryState(challengeNotesQueryKey);

    if (notesQueryData) {
      queryClient.setQueryData(challengeNotesQueryKey, (old) => {
        return filter[NOTE_FILTERS.FILTER_READ_STATUSES]
          ? old.filter((noteItem) => noteItem.noteId !== noteId)
          : old.map((noteItem) => {
              if (noteItem.noteId === noteId) {
                return {
                  ...noteItem,
                  status,
                };
              }

              return noteItem;
            });
      });
    }
  };

  const updateNoteRepliesQuery = (status) => {
    const queryKey = getNoteRepliesQueryKey(noteId);
    const noteRepliesData = queryClient.getQueryState(queryKey);

    if (noteRepliesData) {
      queryClient.setQueryData(queryKey, (oldNote) => ({
        ...oldNote,
        note: {
          ...oldNote.note,
          status,
        },
      }));
    }
  };

  return useMutation(
    `change-note-status-${noteId}`,
    ({ currentStatus }) =>
      request({
        url: `/api/notes/${noteId}/mark-${getUpdatedStatus(
          currentStatus
        )}?note_owner_email=${userEmail}`,
      }),
    {
      onMutate: ({ currentStatus }) => {
        const updatedStatus = getUpdatedStatus(currentStatus);
        updateNoteRepliesQuery(updatedStatus);
        updateNotesQuery(updatedStatus);
      },
      onError: (err, { currentStatus }) => {
        updateNoteRepliesQuery(currentStatus);
        updateNotesQuery(currentStatus);

        toast.error(
          "There's an error with changing note status. Please try again later.",
          {
            icon: false,
            ...toastConfig,
          }
        );
        logError(err);
      },
      onSuccess: (_, { currentStatus, noteType }) => {
        const updatedStatus = getUpdatedStatus(currentStatus);
        onSuccess?.(updatedStatus);

        queryClient.setQueryData(
          `${queryKeys.participant}-${userEmail}`,
          (old) => {
            const fieldName = PARTICIPANT_NOTES_COUNT_FIELDS_BY_TYPE[noteType];
            const fraction = updatedStatus === NOTE_STATUS.READ ? -1 : 1;

            return (
              old && {
                ...old,
                [fieldName]: old[fieldName] + fraction,
              }
            );
          }
        );
      },
    }
  );
};
