import Box from 'shared_DEPRECATED/components/Box';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

export const ParticipantsTableCounter = ({ value }: { value: number }) => (
  <Box style={{ position: 'absolute', bottom: '75%', left: '75%' }}>
    <Badge bgColor="blue" size="sm">
      <Typography as="span" color="whiteTotal" type="small">
        {value}
      </Typography>
    </Badge>
  </Box>
);
