import React from 'react';

import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { CoachingToolCard, CoachingToolCardMenu } from 'features/coachingTool';
import {
  PlanningSprintHeader,
  PlanningSprintWrapper,
  PlanningSprintModel,
  PlanningChallengeCard,
  PlanningBoardItemsWrapper,
} from 'features/planning';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnItem,
  BoardColumnWrapper,
} from 'shared/components/Board';

export const PlanningSprint = ({ sprint }: { sprint: PlanningSprintModel }) => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);

  return (
    <PlanningSprintWrapper sprintState={sprint.state}>
      <BoardColumnWrapper fraction={1}>
        <BoardColumnHeader minHeight="3.75rem">
          <PlanningSprintHeader title={sprint.title} sprint={sprint} />
        </BoardColumnHeader>
        <BoardColumn key={sprint.sprintId}>
          {sprint.challenges.length && (
            <PlanningBoardItemsWrapper
              type="challenge"
              itemsCount={sprint.challenges.length}
              sprintState={sprint.state}
            >
              {sprint.challenges.map((challenge, challengeIndex) => (
                <React.Fragment key={`${challenge.challengeId}`}>
                  <Spacer size="xsm" />
                  <BoardColumnItem
                    item={{
                      ...challenge,
                      index: challengeIndex,
                      fromSprintId: sprint.sprintId,
                      fromBacklog: false,
                    }}
                    type={'challenge'}
                  >
                    <Spacer size="zr sm">
                      <PlanningChallengeCard
                        title={challenge.title}
                        picture={challenge.picture}
                        emoji={challenge.emoji}
                        dimensions={challenge.dimensions}
                        goals={challenge.goals}
                      />
                    </Spacer>
                  </BoardColumnItem>
                  <Spacer size="xsm" />
                </React.Fragment>
              ))}
            </PlanningBoardItemsWrapper>
          )}
          {isCoachingToolsEnabled && sprint.coachingTools.length && (
            <PlanningBoardItemsWrapper
              type="coaching tool"
              itemsCount={sprint.coachingTools.length}
              sprintState={sprint.state}
            >
              {sprint.coachingTools.map(
                ({ name, description, picture, id }) => (
                  <Spacer size="sm">
                    <CoachingToolCard
                      key={id}
                      name={name}
                      description={description}
                      url={picture?.url}
                    >
                      <CoachingToolCardMenu
                        toolId={id}
                        sprintId={sprint.sprintId}
                        sprintState={sprint.state}
                      />
                    </CoachingToolCard>
                  </Spacer>
                )
              )}
            </PlanningBoardItemsWrapper>
          )}
        </BoardColumn>
      </BoardColumnWrapper>
    </PlanningSprintWrapper>
  );
};
