import { SortByFn } from 'react-table';

import { Dayjs } from 'dayjs';
import { getFormattedFileSize } from 'shared_DEPRECATED/components/File/utils';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { SESSION_TIME_DEFAULT_HOUR } from 'features/participants/config';
import { CoachingMode } from 'features/participants/config/types';
import {
  ATTACHMENTS_SIZE_LIMIT_IN_BYTES,
  COACHING_MODE_SESSION_DURATION,
  DEFAULT_RECAP_DATA,
  DEFAULT_SESSION_DURATION,
  defaultSessionCoachingTool,
  initialDimensionNotes,
  RECAP_SUBJECT_DEFAULT_TEXT,
  SESSION_ATTENDANCE_STATUS,
  SESSION_PHASE,
  SESSION_PHASE_LABEL,
} from 'features/sessions/config';
import {
  CoachingToolNote,
  CoachingToolWithEditMode,
  DimensionChallengeNotesType,
  NextCoachingSessionAppointment,
  SessionChallengeType,
  SessionConfigType,
  SessionDimensionChallengeNoteType,
  SessionDirtyFieldType,
  SessionListItemType,
  SessionRecapAttachmentType,
  SessionRecapType,
  SessionTableItemType,
  SessionType,
  Tool,
} from 'features/sessions/config/types';
import { SessionSprint } from 'features/sprint';
import { SPRINT_STATES } from 'features/sprint/config';
import { SprintsType } from 'features/sprint/config/types';

export const getFilterTimeSlots = (time: string) => {
  const currentDate = dateUtils();
  const selectedDate = dateUtils(time);

  return !selectedDate.isToday() || currentDate.isBefore(time);
};

export const getInitialSessionDate = () =>
  dateUtils().hour(SESSION_TIME_DEFAULT_HOUR).minute(30).toDate();

export const getSessionDurationText = (durationInSec: number) => {
  const duration = dateUtils.duration(durationInSec, 'seconds');

  const hoursFormat = duration.asHours() >= 1 ? 'H [H]' : '';
  const minutesFormat = duration.asMinutes() % 60 > 0 ? 'm [M]' : '';

  return duration.format(
    `${hoursFormat}${minutesFormat && ` ${minutesFormat}`}`
  );
};

export const getSessionTimeFrame = (startDate: string, endDate: string) => {
  const formattedStartTime = dateUtils(dateUtils.localDate(startDate)).format(
    'h:mm'
  );
  const formattedEndTime = dateUtils(dateUtils.localDate(endDate)).format(
    'h:mm A'
  );

  return `${formattedStartTime} - ${formattedEndTime}`;
};

export const getFormattedSessionRecapDate = (date?: string) =>
  dateUtils(dateUtils.localDate(date)).format('D MMM h:mm A') ?? '';

const getSessionDuration = (startTime: string, endTime: string) =>
  dateUtils.sortingDiff(endTime, startTime);

export const getSessionsTableColumnsData = (
  sessions: SessionListItemType[]
): SessionTableItemType[] => {
  return sessions.map((session) => ({
    ...session,
    sessionDuration: getSessionDuration(session.startAt, session.endAt),
    sprintWeekNumber: session.sprintDetails?.currentWeekNumber,
  }));
};

export const sortSessionsByStartDate: SortByFn<SessionTableItemType> = (
  rowA,
  rowB
) => dateUtils.sortingDiff(rowA.original.startAt, rowB.original.startAt);

export const getSessionTableSectionTitle = ({
  sprintDetails,
  coachingSessionPhase,
}: SessionListItemType) => {
  return (
    sprintDetails?.titleWithFormattedDateLabel ??
    SESSION_PHASE_LABEL[coachingSessionPhase]
  );
};

export const getTimeLabel = (startDate: string, endDate: string) => {
  const sessionDuration = getSessionDuration(startDate, endDate);

  return getSessionDurationText(sessionDuration);
};

export const getDateStringWithUpdatedTime = (
  currentDate: Date | Dayjs | string,
  newDateTime: Date | Dayjs | string
) =>
  dateUtils(currentDate)
    .hour(dateUtils(newDateTime).hour())
    .minute(dateUtils(newDateTime).minute())
    .toISOString();

export const getSessionEndTime = (
  updatedStartDate: string,
  coachingMode: CoachingMode
) => {
  return dateUtils(updatedStartDate)
    .add(
      COACHING_MODE_SESSION_DURATION[coachingMode] || DEFAULT_SESSION_DURATION,
      'minute'
    )
    .toISOString();
};

const getInitialChallengeNotes = (challenges: SessionChallengeType[]) =>
  challenges.reduce<DimensionChallengeNotesType>((notes, challenge) => {
    challenge.dimensions.forEach((dimension) => {
      notes[`${dimension}${challenge.sprintChallengeId}`] = {
        dimension,
        sprintChallengeId: challenge.sprintChallengeId,
        text: '',
      };
    });

    return notes;
  }, {});

const getChallengeDimensionNotes = (
  sessionNotes: SessionDimensionChallengeNoteType[],
  challenges: SessionChallengeType[] = []
) => {
  const initialChallengeNotes = getInitialChallengeNotes(challenges);

  return sessionNotes.reduce<DimensionChallengeNotesType>(
    (notes, note) => {
      const noteKey = note.sprintChallengeId
        ? `${note.dimension}${note.sprintChallengeId}`
        : note.dimension;
      notes[noteKey] = note;

      return notes;
    },
    { ...initialDimensionNotes, ...initialChallengeNotes }
  );
};

//TODO: can be deleted when backend sent it as a string by default
const mapCoachingTools = (coachingTools: CoachingToolNote[]) =>
  coachingTools.length > 0
    ? coachingTools.map((coachingTool) => ({ ...coachingTool }))
    : [defaultSessionCoachingTool];

const mapRecapData = (recap?: SessionRecapType) =>
  recap
    ? { ...recap, subject: recap.subject || RECAP_SUBJECT_DEFAULT_TEXT }
    : DEFAULT_RECAP_DATA;

export const mapSessionData = (session: SessionType) => ({
  ...session,
  startAt: dateUtils.utc(session.startAt).toISOString(),
  endAt: dateUtils.utc(session.endAt).toISOString(),
  dimensionChallengeNotes: getChallengeDimensionNotes(
    session.coachingSessionNotes,
    session.sprintDetails?.challenges
  ),
  recap: mapRecapData(session.recap),
  coachingTools: mapCoachingTools(session.coachingTools),
  sprintDetails:
    session.sprintDetails && new SessionSprint(session.sprintDetails),
});

export const getChallengesNumberLabel = (challengesCount: number) =>
  `${challengesCount} ${challengesCount === 1 ? 'challenge' : 'challenges'}`;

export const getChallengeTarget = (challenge: SessionChallengeType) => {
  if (challenge.dueDate) {
    return dateUtils(challenge.dueDate).format('D MMM');
  }

  const challengeTarget = challenge.target - challenge.passed;

  return `${challenge.success}/${challengeTarget}`;
};

const mapCoachingToolsPostData = (
  coachingTools: CoachingToolNote[] | CoachingToolWithEditMode[]
) =>
  coachingTools
    .filter(({ editMode }) => !editMode)
    .map((currentTool) => ({
      ...currentTool,
      tool: currentTool.tool.value,
      //@ts-ignore
      link: currentTool.link === '' ? null : currentTool.link,
    }));

export const mapNextSessionPostData = ({
  startAt,
  ...session
}: NextCoachingSessionAppointment) => {
  const startAtIsoString = dateUtils(
    dateUtils.utc(startAt)
  ).formatAsISOString();

  return {
    ...session,
    coachingTools: mapCoachingToolsPostData(session.coachingTools),
    startAt: startAtIsoString === 'Invalid Date' ? null : startAtIsoString,
  };
};

const mapSessionDatesPostData = (attendance: string, date: string) =>
  dateUtils(
    dateUtils.utc(
      attendance !== SESSION_ATTENDANCE_STATUS.ATTENDED
        ? dateUtils(date).startOf('hour').toISOString()
        : date
    )
  ).formatAsISOString();

export const mapSessionPostData = ({
  dimensionChallengeNotes,
  sprintDetails,
  dimensionsAssessment,
  nextCoachingSessionAppointment,
  attendance,
  ...session
}: SessionType) => ({
  ...session,
  attendance,
  startAt: mapSessionDatesPostData(attendance, session.startAt),
  endAt: mapSessionDatesPostData(attendance, session.endAt),
  coachingSessionNotes: Object.values(dimensionChallengeNotes).filter(
    ({ text }) => text
  ),
  coachingTools: mapCoachingToolsPostData(session.coachingTools),
});

export const getAttachmentsSizeLimitDiff = (
  attachments: SessionRecapAttachmentType[]
) => {
  const filesSizeInBytes = attachments.reduce(
    (sum, file) => sum + file.contentLength,
    0
  );

  return filesSizeInBytes - ATTACHMENTS_SIZE_LIMIT_IN_BYTES;
};

export const getFileDetails = (file: File) => ({
  name: file.name,
  contentType: file.type,
  contentLength: file.size,
});

export const getFileSizeLimitErrorText = (sizeLimitDiff: number) =>
  `☝️You exceeded the allowed attachments size of ${getFormattedFileSize(
    ATTACHMENTS_SIZE_LIMIT_IN_BYTES
  )} by ${getFormattedFileSize(sizeLimitDiff)}.`;

export const getCoachingToolsLabel = (tools: Tool[], toolKey: string) => {
  const selectedTool = tools.find(({ value }) => value === toolKey);

  return selectedTool?.label ?? 'Select session tool';
};

export const getMiroBoardId = (miroLink: string) => {
  const regex = /board\/(.+)\//;
  const match = miroLink.match(regex);

  return match?.[1];
};

//react-hook-form doesn't have any methods to get only dirty fields,
//and here we can go through any field from dirtyFields object and check if it's dirty.
//related GitHub thread here https://github.com/orgs/react-hook-form/discussions/9472
//and here https://github.com/orgs/react-hook-form/discussions/1991
export const hasDirtyFields = (
  dirtyFields?: SessionDirtyFieldType
): boolean => {
  if (!dirtyFields || typeof dirtyFields === 'boolean') {
    return !!dirtyFields;
  }

  if (Array.isArray(dirtyFields)) {
    return dirtyFields.some((arrayValue) =>
      hasDirtyFields(arrayValue as SessionDirtyFieldType)
    );
  }

  if (typeof dirtyFields === 'object') {
    return Object.values(dirtyFields).some((objectValue) =>
      hasDirtyFields(objectValue as SessionDirtyFieldType)
    );
  }

  return false;
};

export const getRecapDateString = (
  dateString: string,
  userTimeZone: Nullable<string>
) => {
  if (!dateString) return null;

  const currentTimeZone = dateUtils.tz();

  return !userTimeZone || userTimeZone === currentTimeZone
    ? dateUtils(dateUtils.localDate(dateString)).format('MMMM D, h:mm A')
    : dateUtils(dateUtils.utc(dateString))
        .tz(userTimeZone)
        .format('MMMM D, h:mm A (z)');
};

const getSessionPhase = (sprints: SprintsType) => {
  const isActiveSprintPhase = sprints[0]?.state === SPRINT_STATES.ACTIVE;

  if (isActiveSprintPhase) {
    return SESSION_PHASE.SPRINT;
  }

  const isOnboardingPhase =
    sprints.length === 0 ||
    (sprints.length === 1 && sprints[0].state === SPRINT_STATES.DRAFT);

  if (isOnboardingPhase) {
    return SESSION_PHASE.ONBOARDING;
  }

  return SESSION_PHASE.BRIDGE_MODE;
};

export const getDefaultSessionModalFormData = (
  sprints: SprintsType,
  nextSessionStartDate: string
): SessionConfigType => {
  const sessionPhase = getSessionPhase(sprints);

  return {
    sprintId:
      sessionPhase === SESSION_PHASE.SPRINT ? sprints[0].sprintId : null,
    coachingSessionPhase: sessionPhase,
    coachingSessionDate: dateUtils.localDate(nextSessionStartDate).format(),
  };
};
