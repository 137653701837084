import { createContext, useState, useContext } from 'react';

import { Nullable } from 'shared_DEPRECATED/types';

const FeedbackDialogContext = createContext<
  [
    () => void | undefined,
    React.Dispatch<React.SetStateAction<() => void | undefined>>
  ]
>([() => undefined, () => undefined]);

const FeedbackDialogProvider = ({
  children,
}: {
  children: Nullable<React.ReactNode>;
}) => {
  const feedbackDialogState = useState<() => void | undefined>(() => undefined);

  return (
    <FeedbackDialogContext.Provider value={feedbackDialogState}>
      {children}
    </FeedbackDialogContext.Provider>
  );
};

const useFeedbackDialogContext = () => {
  const context = useContext(FeedbackDialogContext);
  if (!context) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }

  return context;
};

export { FeedbackDialogProvider, useFeedbackDialogContext };
