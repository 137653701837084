import { forwardRef } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

type VideoProps = ReactPlayerProps & {
  src: string;
  controls?: boolean;
  height?: string | number;
  width?: string | number;
  className?: string;
};

export const Video = forwardRef<ReactPlayer, VideoProps>(
  (
    {
      className,
      src,
      controls = true,
      height = 'auto',
      width = '100%',
      ...props
    }: VideoProps,
    ref
  ) => (
    <ReactPlayer
      ref={ref}
      url={src}
      controls={controls}
      className={className}
      data-testid="chat-video-preview"
      height={height}
      width={width}
      {...props}
    />
  )
);
