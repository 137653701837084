import { Button, VARIANTS } from 'shared';

type TPlanningFilterButton = {
  onClick: () => void;
  disabled: boolean;
  label: string;
};

export const PlanningFilterButton = ({
  onClick,
  disabled,
  label,
}: TPlanningFilterButton) => (
  <Button onClick={onClick} variant={VARIANTS.PRIMARY} disabled={disabled}>
    {label}
  </Button>
);
