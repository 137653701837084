import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { updateArrayById } from 'shared_DEPRECATED/utils';
import { v4 as uuidv4 } from 'uuid';

import {
  IRepeatedChallenge,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  IPlanningDraftSprint,
  useExploreChallengesDialog,
} from 'features/planning';
import { useAddChallengesToSprintMutation } from 'features/sprint/hooks/mutation/useAddChallengesToSprint';

type TUseDraftSprintAddLibraryChallenge = {
  sprint: IPlanningDraftSprint;
};

export const useDraftSprintAddLibraryChallenge = ({
  sprint,
}: TUseDraftSprintAddLibraryChallenge) => {
  const queryClient = useQueryClient();

  const { mutateAsync: addChallenges } = useAddChallengesToSprintMutation({
    sprintId: sprint.sprintId,
    options: {
      onMutate: async (
        challenges: (IRepeatedChallenge | INonRepeatedChallenge)[]
      ) => {
        const updatedChallenges = [...challenges];

        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const challengesWithTemporaryChallengeIds = updatedChallenges.map(
          (challenge) => ({ ...challenge, challengeId: uuidv4() })
        );

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => ({
              ...oldVal,
              items: updateArrayById({
                array: oldVal!.items,
                updatedItem: {
                  ...sprint,
                  challenges: [
                    ...sprint.challenges,
                    ...challengesWithTemporaryChallengeIds,
                  ],
                },
                idProperty: 'sprintId',
              }),
            })
          );
        }

        return { previousDraftSprintsValue };
      },
      onError: (
        _: Error,
        __: any,
        context:
          | {
              previousDraftSprintsValue?: {
                items: IPlanningDraftSprint[];
              };
            }
          | undefined
      ) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints],
            context.previousDraftSprintsValue
          );
        }
      },
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.draftSprints],
        });
      },
    },
  });

  const onSubmit = async (
    newChallenges:
      | (IRepeatedChallenge | INonRepeatedChallenge)[]
      | (INonRepeatedChallenge | IRepeatedChallenge)
  ) => {
    try {
      const challenges = Array.isArray(newChallenges)
        ? newChallenges
        : [newChallenges];

      await addChallenges(challenges);
    } catch (error) {
      logError(error);
    }
  };

  const openExploreChallengesModal = useExploreChallengesDialog({
    sprint,
    onSubmit,
  });

  return openExploreChallengesModal;
};
