import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import {
  ChallengeNotesRepliesCard,
  ChallengeNotesReply,
} from 'features/challengeNotes/components/Replies';
import {
  useNoteRepliesQuery,
  useNoteReplyMutation,
} from 'features/challengeNotes/hooks';
import { useNoteReplyEditMutation } from 'features/challengeNotes/hooks/mutation/useNoteReplyEdit';
import { useParticipantQuery } from 'features/participants/hooks';

import { SidebarSection } from 'shared/components/Sidebar';
import { useSidebarContext } from 'shared/components/Sidebar/context';
import { Typography } from 'shared/components/Typography';

import { ChallengeNotesReplyReactions } from './ChallengeNotesReplyReactions';

export const ChallengeNotesRepliesContent = () => {
  const [editedReplyId, setEditedReplyId] = useState(null);
  const { note, user } = useSidebarContext();
  const { email } = useParams();
  const { participant } = useParticipantQuery(email);

  const {
    note: selectedNote,
    replies,
    isFetching: isRepliesFetching,
  } = useNoteRepliesQuery(note);

  const { handleSubmit, register, reset, formState, setValue } = useForm({
    defaultValues: { reply: '' },
    mode: 'onChange',
  });

  const mutation = useNoteReplyMutation(note);

  const onReplyEditSuccess = () => {
    reset();
    setEditedReplyId(null);
  };

  const editMutation = useNoteReplyEditMutation({
    noteId: note.noteId,
    replyId: editedReplyId,
    options: { onSuccess: onReplyEditSuccess },
  });

  const handleSaveReply = (formValues) => {
    if (formState.errors.reply) {
      return;
    }

    reset();
    mutation.mutate(formValues.reply);
  };

  const handleEditReply = (formValues) => {
    if (formState.errors.reply) {
      return;
    }

    editMutation.mutate(formValues.reply);
  };

  const onReplyButtonClick = editedReplyId
    ? handleSubmit(handleEditReply)
    : handleSubmit(handleSaveReply);

  const handleKeyDown = (event) => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      onReplyButtonClick();
    }
  };

  useEffect(() => {
    if (editedReplyId) {
      setValue(
        'reply',
        replies.find((reply) => reply.replyId === editedReplyId).text
      );
    }
  }, [editedReplyId, replies, setValue]);

  if (isRepliesFetching && !selectedNote) {
    return <Loader />;
  }

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Box style={{ flexGrow: 1, overflow: 'auto' }}>
        {selectedNote && <ChallengeNotesRepliesCard note={selectedNote} />}
        {replies.map((reply) => (
          <ChallengeNotesReply
            key={reply.createdAt}
            date={reply.createdAt}
            pictureUrl={getPictureUrl(reply.user.picture)}
            text={reply.text}
            userEmail={reply.user.email}
            userName={reply.user.name}
            setEditedReplyId={setEditedReplyId}
            replyId={reply.replyId}
          >
            <ChallengeNotesReplyReactions
              noteId={selectedNote.noteId}
              replyId={reply.replyId}
              reactions={reply.reactions}
              noteOwnerId={participant.userId}
            />
          </ChallengeNotesReply>
        ))}
      </Box>
      <SidebarSection borderLocation="top">
        {editedReplyId ? (
          <>
            <Flex>
              <Spacers sizes={['zr zr zr lg', 'zr zr zr lg', 'zr zr zr sm']}>
                <Typography type="meta" color="blue">
                  Edit reply
                </Typography>
              </Spacers>
              <Spacers sizes={['zr md zr zr']}>
                <Button
                  dataTestid="cancel-edit-reply-btn"
                  onClick={() => {
                    setEditedReplyId(null);
                    reset();
                  }}
                >
                  <Icon
                    name={iconNames.cross}
                    width={12}
                    height={12}
                    stroke="var(--fgPrimary)"
                  />
                </Button>
              </Spacers>
            </Flex>
            <Spacer size="sm zr" />
          </>
        ) : null}
        <Flex alignItems="flex-end">
          <Spacer size="sm zr">
            <Avatar
              name={user.name || user.email}
              size={SIZES.SM}
              src={getPictureUrl(user.picture)}
            />
          </Spacer>
          <FlexItem flexGrow={1}>
            <Spacer size="zr md">
              <Spacer size="zr sm xsm">
                <TextareaAutosize
                  style={{
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    resize: 'none',
                    fontSize: 'var(--font-size-medium)',
                    backgroundColor: 'transparent',
                    color: 'var(--fgPrimary)',
                  }}
                  placeholder="Add a reply"
                  data-testid="user-challenge-note-reply-input"
                  maxRows={4}
                  onKeyDown={handleKeyDown}
                  {...register('reply', {
                    required: true,
                    setValueAs: (value) => value.trim(),
                  })}
                />
              </Spacer>
            </Spacer>
          </FlexItem>
          <Box style={{ borderRadius: '50%', overflow: 'hidden' }}>
            <Button
              variant="primary"
              disabled={mutation.isLoading || editMutation.isLoading}
              onClick={onReplyButtonClick}
              dataTestid="send-reply-btn"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                width="28px"
                height="28px"
              >
                <Icon name={iconNames.paperPlane} width={18} height={18} />
              </Flex>
            </Button>
          </Box>
        </Flex>
      </SidebarSection>
    </Flex>
  );
};
