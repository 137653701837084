import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { SearchInput } from 'shared_DEPRECATED/components/Form/Input';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import CreateChallengeButton from 'features/challenge/components/CreateChallengeButton';
import { useInitialChallenge } from 'features/challenge/hooks/useInitialChallenge';
import {
  ExploreDialogDimensionCheckboxes,
  useExploreDialogContentContext,
  useExploreChallengesAddChallengeModal,
  ExploreDialogSearchResult,
  ExploreDialogTabs,
} from 'features/planning';

export const ExploreDialogBody = () => {
  const initialChallenge = useInitialChallenge();
  const onCreateChallengeBtnClick = useExploreChallengesAddChallengeModal({
    initialChallenge,
    title: 'Create Challenge',
  });

  const { query, handleSearchChange } = useExploreDialogContentContext();

  return (
    <>
      <Flex alignItems="stretch">
        <FlexItem flexGrow={1}>
          <SearchInput
            name="search"
            placeholder="Search all challenges"
            onChange={handleSearchChange}
          />
        </FlexItem>
        <Spacers sizes={['zr md zr zr', 'zr sm zr zr']} />
        <CreateChallengeButton
          onCreateChallengeBtnClick={onCreateChallengeBtnClick}
        />
      </Flex>
      <Spacers sizes={['md zr', 'sm zr']} />
      {!query ? <ExploreDialogDimensionCheckboxes /> : null}
      <Spacers sizes={['md zr sm']} />
      {query ? <ExploreDialogSearchResult /> : <ExploreDialogTabs />}
    </>
  );
};
