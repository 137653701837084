import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { ICoachingTool } from 'features/coachingTool/types';

export const useCoachingToolsQuery = () => {
  const { request } = useQueryHTTPRequest();

  return useQuery<{ items: ICoachingTool[] }, ICoachingTool, ICoachingTool[]>(
    [queryKeys.coachingTools],
    () => request({ url: `/api/coaching-tools` }),
    {
      select: (data) => data.items,
    }
  );
};
