import PropTypes from 'prop-types';

import {
  OptionsMenuButton,
  OptionsMenu,
} from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { MenuReadStatusButton } from 'features/challengeNotes/components/Card';
import { NOTE_STATUS, NOTE_TYPES } from 'features/challengeNotes/config';
import { useChallengeEditModal } from 'features/challengeNotes/hooks/useChallengeEditModal';

const ChallengeNotesCardMenuPropTypes = {
  noteId: PropTypes.string.isRequired,
  noteReadStatus: PropTypes.oneOf(Object.values(NOTE_STATUS)),
  challengeId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export const ChallengeNotesCardMenu = ({
  noteId,
  noteReadStatus,
  challengeId,
  userEmail,
}) => {
  const openChallengeModal = useChallengeEditModal({ challengeId });

  return (
    <OptionsMenu dataTestid="user-challenge-note-menu">
      <MenuReadStatusButton
        noteId={noteId}
        noteType={NOTE_TYPES.CHALLENGE_NOTE}
        noteReadStatus={noteReadStatus}
        userEmail={userEmail}
        dataTestid="user-challenge-note-menu-option-read"
      />
      <OptionsMenuButton
        onClick={openChallengeModal}
        dataTestid="user-challenge-note-menu-option-edit-challenge"
      >
        Edit challenge
      </OptionsMenuButton>
    </OptionsMenu>
  );
};

ChallengeNotesCardMenu.propTypes = ChallengeNotesCardMenuPropTypes;
