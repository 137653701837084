import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { getGoalDeadlineStyles, getGoalDeadlineStatus } from 'features/goal';

import { BADGE_COLORS, Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type TGoalDeadline = {
  deadline: Nullable<string>;
  completedAt: Nullable<string>;
};

export const GoalDeadline = ({ deadline, completedAt }: TGoalDeadline) => {
  const isDeadlineExpired = getGoalDeadlineStatus(deadline);

  const { badgeColor, textColor, iconName, iconColor } = getGoalDeadlineStyles({
    isDeadlineExpired,
    isCompleted: !!completedAt,
  });

  return (
    <Badge bgColor={badgeColor as BADGE_COLORS} size="sm">
      <Flex alignItems="center" justifyContent="space-between">
        <Icon name={iconNames[iconName]} fill={iconColor} />
        <Spacer size="xsm" />
        <Typography
          as="span"
          type="meta"
          //@ts-ignore
          color={textColor}
          fontWeight="medium"
          whiteSpace="nowrap"
        >
          {dateUtils(completedAt ? completedAt : deadline).format(
            'D MMM, YYYY'
          )}
        </Typography>
      </Flex>
    </Badge>
  );
};
