import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  OptionsMenu,
  OptionsMenuButton,
} from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { MenuReadStatusButton } from 'features/challengeNotes/components/Card';
import { NOTE_STATUS, NOTE_TYPES } from 'features/challengeNotes/config';

import { useSidebarContext } from 'shared/components/Sidebar/context';

const ChallengeNotesCardRecapMenuPropTypes = {
  noteId: PropTypes.string.isRequired,
  coachingSessionId: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  noteReadStatus: PropTypes.oneOf(Object.values(NOTE_STATUS)),
};

export const ChallengeNotesCardRecapMenu = ({
  noteId,
  noteReadStatus,
  coachingSessionId,
  userEmail,
}) => {
  const { sprint } = useSidebarContext();
  const navigate = useNavigate();

  const onViewSessionNoteClick = () =>
    navigate(
      `/coach/participants/${sprint.userEmail}/sessions/${coachingSessionId}`
    );

  return (
    <OptionsMenu>
      <MenuReadStatusButton
        noteReadStatus={noteReadStatus}
        noteId={noteId}
        userEmail={userEmail}
        noteType={NOTE_TYPES.SESSION_RECAP_NOTE}
      />
      {coachingSessionId && (
        <OptionsMenuButton onClick={onViewSessionNoteClick}>
          View session
        </OptionsMenuButton>
      )}
    </OptionsMenu>
  );
};

ChallengeNotesCardRecapMenu.propTypes = ChallengeNotesCardRecapMenuPropTypes;
