import { ReactNode } from 'react';

import { Nullable } from 'shared_DEPRECATED/types';

import { IChallenge } from 'features/challenge/config/types';
import { usePlanningBoardDrop } from 'features/planning';

import { BoardDropZone } from 'shared/components/Board';

type TPlanningBoardDropZone = {
  handleCanDrop: (item: IChallenge) => boolean;
  dropZoneIndex: number;
  onDrop: any;
  accept: string[];
  isLast: boolean;
  dropZoneSprintId?: string;
  isDropzoneInBacklog: boolean;
  lowerChallengeId: Nullable<string>;
  children?: ReactNode;
};

export const PlanningBoardDropZone = ({
  handleCanDrop,
  dropZoneIndex,
  onDrop,
  accept,
  isLast,
  dropZoneSprintId,
  isDropzoneInBacklog,
  lowerChallengeId,
  children,
}: TPlanningBoardDropZone) => {
  const { drop, isOver, canDrop } = usePlanningBoardDrop<IChallenge>({
    onDrop,
    accept,
    handleCanDrop,
    dropZoneIndex,
    dropZoneSprintId,
    isDropzoneInBacklog,
    lowerChallengeId,
  });

  return (
    <BoardDropZone isLast={isLast} canDrop={canDrop} isOver={isOver} ref={drop}>
      {children}
    </BoardDropZone>
  );
};
