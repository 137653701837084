import PropTypes from 'prop-types';

import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import {
  OptionsMenu,
  OptionsMenuButton,
} from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { Typography } from 'shared/components/Typography';

const ChallengeNotesRepliesCardHeaderPropTypes = {
  name: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  date: PropTypes.string.isRequired,
  isAuthor: PropTypes.bool.isRequired,
  setEditedReplyId: PropTypes.func.isRequired,
  replyId: PropTypes.string.isRequired,
};

export const ChallengeNotesRepliesCardHeader = ({
  name,
  pictureUrl,
  date,
  isAuthor,
  setEditedReplyId,
  replyId,
}) => {
  return (
    <Flex>
      <Flex justifyContent="flex-start">
        <FlexItem>
          <Avatar name={name} size={SIZES.SM} src={pictureUrl} />
        </FlexItem>
        <FlexItem>
          <Spacer size="zr zr zr md">
            <Spacer size="zr zr zr sm">
              <HotjarSuppression>
                <Flex justifyContent="flex-start">
                  <Spacer size="zr md zr zr">
                    <Typography
                      as="div"
                      fontWeight="semiBold"
                      type="small"
                      color="black"
                      dataTestid="user-challenge-note-header-author"
                    >
                      {name}
                    </Typography>
                  </Spacer>
                  <Typography
                    as="div"
                    type="small"
                    dataTestid="user-challenge-note-header-date"
                    color="black"
                  >
                    {dateUtils.todayTimeAgoOrDate(date)}
                  </Typography>
                </Flex>
              </HotjarSuppression>
            </Spacer>
          </Spacer>
        </FlexItem>
      </Flex>
      {isAuthor && (
        <OptionsMenu dataTestid={`user-challenge-note-reply-menu-${replyId}`}>
          <OptionsMenuButton
            onClick={() => setEditedReplyId(replyId)}
            dataTestid="user-challenge-note-reply-menu-option-edit-reply"
          >
            Edit
          </OptionsMenuButton>
        </OptionsMenu>
      )}
    </Flex>
  );
};

ChallengeNotesRepliesCardHeader.propTypes =
  ChallengeNotesRepliesCardHeaderPropTypes;
