import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { removeFromArrayById } from 'shared_DEPRECATED/utils';

import { IPlanningDraftSprint } from 'features/planning/types';

export const useRemovePlanningCoachingToolMutation = () => {
  const queryClient = useQueryClient();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ toolId, sprintId }: { toolId: string; sprintId: string }) =>
      request({
        url: `/api/web/sprint-planning/coaching-tools/remove`,
        body: { toolId, sprintId },
      }),
    {
      onMutate: async ({ toolId, sprintId }) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousDraftSprintsValue = queryClient.getQueryData<{
          items: IPlanningDraftSprint[];
        }>([queryKeys.draftSprints]);

        if (previousDraftSprintsValue) {
          queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
            [queryKeys.draftSprints],
            (oldVal) => {
              let updatedSprints = [...oldVal!.items];

              const sprintIndex = updatedSprints.findIndex(
                (sprint) => sprint.sprintId === sprintId
              );

              updatedSprints[sprintIndex] = {
                ...updatedSprints[sprintIndex],
                coachingTools: removeFromArrayById({
                  array: updatedSprints[sprintIndex].coachingTools,
                  id: toolId,
                  idProperty: 'id',
                }),
              };

              return { ...oldVal, items: updatedSprints };
            }
          );
        }

        return { previousDraftSprintsValue };
      },
      onError: (
        err: Error,
        __: any,
        context:
          | {
              previousDraftSprintsValue?: {
                items: IPlanningDraftSprint[];
              };
            }
          | undefined
      ) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints],
            context.previousDraftSprintsValue
          );
        }
        logError(err);
      },
      onSuccess: () => {},
    }
  );
};
