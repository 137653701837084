import Spacer from './Spacer';

type SpacersProps = {
  sizes: string[] | string;
  children?: React.ReactNode;
};

export const Spacers = ({ sizes, children }: SpacersProps) => {
  if (typeof sizes === 'string') {
    return <Spacer size={sizes}>{children}</Spacer>;
  }

  if (sizes.length === 0) {
    return <>{children}</>;
  }

  const [size, ...remainingSizes] = sizes;

  return (
    <Spacer size={size}>
      <Spacers sizes={remainingSizes}>{children}</Spacers>
    </Spacer>
  );
};
