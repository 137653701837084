import { Flex } from 'shared_DEPRECATED/components/Flex';

import { Typography } from 'shared/components/Typography';

export const GoalAddDialogHeader = () => (
  <Flex flexDirection="column" width="100%" alignItems="flex-start">
    <Typography as="h3" type="heading" fontWeight="semiBold" color="black">
      Add the goal
    </Typography>
    <Typography color="gray" fontWeight="medium" type="small">
      or dream / vision
    </Typography>
  </Flex>
);
