import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { Typography } from 'shared/components/Typography';

export const SessionsFormCoachingToolsLinkHint = () => (
  <Flex justifyContent="flex-start" gap="var(--spacing04)" alignItems="center">
    <FlexItem>
      <Icon name={iconNames.miro} />
    </FlexItem>
    <Typography type="small" color="black">
      Paste Miro board link from your session into the text field above.
    </Typography>
  </Flex>
);
