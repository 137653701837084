import { VStack } from '@chakra-ui/react';

import { iconNames, Icon } from 'shared_DEPRECATED/components/Icon';

import {
  CoachingToolCardMenuCopyToSprint,
  CoachingToolCardMenuMoveToSprint,
  CoachingToolCardMenuRemoveButton,
  CoachingToolCardMenuOpenButton,
} from 'features/coachingTool';
import {
  IPlanningDraftSprint,
  usePlanningDraftSprintsQuery,
} from 'features/planning';
import { SPRINT_STATES } from 'features/sprint/config';

import { Dropdown } from 'shared/components/Dropdown';

type TCoachingToolCardMenu = {
  toolId: string;
  sprintId?: string;
  sprintState?: string;
};

export const CoachingToolCardMenu = ({
  toolId,
  sprintId,
  sprintState,
}: TCoachingToolCardMenu) => {
  const { data: draftSprints } = usePlanningDraftSprintsQuery<
    IPlanningDraftSprint[]
  >({});

  const canToolBeMovedOrRemovedFromSprint =
    !!sprintId && sprintState !== SPRINT_STATES.FINISHED;

  const canToolBeCopyOrMovedToSprint = draftSprints?.length;

  return (
    <Dropdown
      anchorContent={<Icon name={iconNames.ellipsisHorizontal} />}
      anchorSpacing={['sm']}
      placement="right-start"
      closeOnSelect={false}
      withChevronIcon={false}
    >
      <VStack align="stretch">
        <CoachingToolCardMenuOpenButton toolId={toolId} />
        {canToolBeCopyOrMovedToSprint && (
          <CoachingToolCardMenuCopyToSprint
            toolId={toolId}
            sprints={draftSprints}
          />
        )}
        {canToolBeCopyOrMovedToSprint && canToolBeMovedOrRemovedFromSprint && (
          <CoachingToolCardMenuMoveToSprint
            sprints={draftSprints}
            sprintId={sprintId}
            toolId={toolId}
          />
        )}
        {canToolBeMovedOrRemovedFromSprint && (
          <CoachingToolCardMenuRemoveButton
            toolId={toolId}
            sprintId={sprintId}
          />
        )}
      </VStack>
    </Dropdown>
  );
};
