import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
} from 'react';

import { ChallengeGoalsType } from 'features/challenge/config/types';
import { useGoalsQuery } from 'features/goal';
import { UNLINKED_CHALLENGES_VALUE } from 'features/planning';

type SelectedGoalsContextType = {
  selectedGoalsIds: string[];
  updateSelectedGoals: (goalIds: string[]) => void;
  selectAllGoals: () => void;
  deselectAllGoals: () => void;
  goals: ChallengeGoalsType;
  isLoading: boolean;
};

const defaultSelectedGoalsContext: SelectedGoalsContextType = {
  selectedGoalsIds: [],
  updateSelectedGoals: () => {},
  selectAllGoals: () => {},
  deselectAllGoals: () => {},
  goals: [],
  isLoading: false,
};

const SelectedGoalsContext = createContext(defaultSelectedGoalsContext);

export const SelectedGoalsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [selectedGoalsIds, setSelectedGoalsIds] = useState<string[]>([]);

  const { data: goals = [], isLoading } = useGoalsQuery();

  const updateSelectedGoals = useCallback((goalIds: string[]) => {
    setSelectedGoalsIds(goalIds);
  }, []);

  const selectAllGoals = useCallback(() => {
    goals &&
      setSelectedGoalsIds([
        ...goals.map((goal) => goal.goalId),
        UNLINKED_CHALLENGES_VALUE,
      ]);
  }, [goals]);

  const deselectAllGoals = useCallback(() => {
    setSelectedGoalsIds([]);
  }, []);

  const contextValue = useMemo(
    () => ({
      selectedGoalsIds,
      updateSelectedGoals,
      selectAllGoals,
      deselectAllGoals,
      goals: goals || [],
      isLoading,
    }),
    [
      selectedGoalsIds,
      updateSelectedGoals,
      selectAllGoals,
      deselectAllGoals,
      goals,
      isLoading,
    ]
  );

  return (
    <SelectedGoalsContext.Provider value={contextValue}>
      {children}
    </SelectedGoalsContext.Provider>
  );
};

export const useSelectedGoalsContext = () => {
  const context = useContext(SelectedGoalsContext);

  if (!context) {
    throw new Error(
      'useSelectedGoalsContext must be used within a SelectedGoalsContext.Provider'
    );
  }

  return context;
};
