import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { LexicalEditor } from 'lexical';

import { Button, VARIANTS } from 'shared/components/Button';
import { Typography } from 'shared/components/Typography';

type InsertTemplateButtonProps = {
  handleClick: (editor: LexicalEditor) => void;
};

export const InsertTemplateButton = ({
  handleClick,
}: InsertTemplateButtonProps) => {
  const [editor] = useLexicalComposerContext();

  const onClick = () => {
    handleClick(editor);
  };

  return (
    <Button
      variant={VARIANTS.TERTIARY}
      _hover={{ bg: 'none' }}
      _focus={{ outline: 'none' }}
      onClick={onClick}
    >
      <Typography type="small" color="black">
        Template
      </Typography>
    </Button>
  );
};
