import PropTypes from 'prop-types';

import Loader from 'shared_DEPRECATED/components/Loader/Loader';

import { ChallengeNotesEmptyState } from 'features/challengeNotes/components/ChallengeNotesEmptyState';
import { ChallengeNotesFilterEmptyState } from 'features/challengeNotes/components/Filter';
import {
  useChallengeNotesContext,
  useChallengeNotesFilterContext,
} from 'features/challengeNotes/context';

const ChallengeNotesContentContainerPropTypes = {
  children: PropTypes.node.isRequired,
  noteType: PropTypes.string.isRequired,
  textPlaceholder: PropTypes.string.isRequired,
};

export const ChallengeNotesContentContainer = ({
  children,
  noteType,
  textPlaceholder,
}) => {
  const { isFetching, notes } = useChallengeNotesContext();
  const { notesFiltered, filter } = useChallengeNotesFilterContext();

  if (filter.filterNotesTypes !== noteType) {
    return null;
  }

  if (isFetching) {
    return <Loader />;
  }

  if (notesFiltered && notes.length === 0) {
    return <ChallengeNotesFilterEmptyState />;
  }

  if (notes.length === 0) {
    return <ChallengeNotesEmptyState text={textPlaceholder} />;
  }

  return children;
};

ChallengeNotesContentContainer.propTypes =
  ChallengeNotesContentContainerPropTypes;
