import { useFormContext, useWatch } from 'react-hook-form';

import { SIZES } from 'shared_DEPRECATED/components/Avatar';
import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { DatePicker } from 'shared_DEPRECATED/components/Form/DatePicker';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  SESSION_DATE_FORMAT,
  SESSION_DATE_LABEL,
  SESSION_TIME_INTERVAL,
} from 'features/participants/config';
import {
  SessionSidebarSection,
  SessionSidebarSectionBody,
  SessionSidebarSectionHeader,
} from 'features/sessions/components';
import {
  getFilterTimeSlots,
  getInitialSessionDate,
} from 'features/sessions/utils';

export const NextSessionDateAndTimeSelector = () => {
  const { setValue, getValues } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey') || '';
  const sessionDate = useWatch({ name: `${fieldKey}.startAt` });

  const date = sessionDate ? dateUtils.localDate(sessionDate).toDate() : null;

  const onChange = (date: Date) => {
    setValue(`${fieldKey}.startAt`, date.toISOString(), {
      shouldDirty: true,
    });
  };

  return (
    <SessionSidebarSection>
      <SessionSidebarSectionHeader title="Next session date and time" />
      <SessionSidebarSectionBody>
        <DatePicker
          // @ts-ignore
          date={date}
          onChange={onChange}
          format={SESSION_DATE_FORMAT}
          // @ts-ignore
          minDate={dateUtils().toDate()}
          placeholderText={SESSION_DATE_LABEL}
          filterTime={getFilterTimeSlots}
          // @ts-ignore
          size={SIZES.LG}
          showTimeSelect={true}
          timeIntervals={SESSION_TIME_INTERVAL}
          openToDate={getInitialSessionDate()}
          anchorVariant={VARIANTS.SECONDARY}
        />
      </SessionSidebarSectionBody>
    </SessionSidebarSection>
  );
};
