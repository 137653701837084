import { Divider } from 'shared_DEPRECATED/components/Divider';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  ExploreDialogQuickStartSprintsList,
  ExploreDialogChallengeInfoList,
} from 'features/planning';

import { Typography } from 'shared/components/Typography';

export const ExploreDialogTabLibraryPanelContent = () => (
  <>
    <Typography as="h2" fontWeight="semiBold" type="body" color="black">
      Quick start sprints
    </Typography>
    <Typography type="small" color="gray">
      Try sets of challenges designed by our team to help participant get
      started faster
    </Typography>
    <Spacer size="md zr" />
    <ExploreDialogQuickStartSprintsList />
    <Spacer size="md zr" />
    <Divider />
    <Spacer size="md zr" />
    <Typography as="h2" fontWeight="semiBold" type="body" color="black">
      Individual challenges
    </Typography>
    <Typography type="small" color="gray">
      Build participant’s own sprint
    </Typography>
    <Spacer size="md zr" />
    <ExploreDialogChallengeInfoList />
  </>
);
