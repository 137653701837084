import { UseFormReturn } from 'react-hook-form';

import { NextCoachingSessionAppointment } from 'features/sessions/config/types';
import {
  useNextSessionUpdateMutation,
  useParticipantProfileNoteUpdateMutation,
} from 'features/sessions/hooks';
import { hasDirtyFields } from 'features/sessions/utils';

export const useNextSessionUpdate = (
  formMethods: UseFormReturn<NextCoachingSessionAppointment>
) => {
  const {
    coachingSessionAppointmentId,
    coachingClientNote,
    coachingSessionId,
  } = formMethods.getValues();

  const nextSessionMutation = useNextSessionUpdateMutation(
    coachingSessionAppointmentId
  );

  const profileNoteMutation = useParticipantProfileNoteUpdateMutation({
    noteId: coachingClientNote?.noteId,
    appointmentId: coachingSessionAppointmentId,
    coachingSessionId,
  });

  const onMutate = async (nextSession: NextCoachingSessionAppointment) => {
    const { dirtyFields } = formMethods.formState;
    const { coachingClientNote, ...nextSessionFields } = dirtyFields;

    const isNextSessionDirty = hasDirtyFields(nextSessionFields);
    const isClientNoteDirty = hasDirtyFields(coachingClientNote);

    isClientNoteDirty &&
      (await profileNoteMutation.mutateAsync(
        nextSession.coachingClientNote.text
      ));

    isNextSessionDirty && (await nextSessionMutation.mutateAsync(nextSession));
  };

  return onMutate;
};
