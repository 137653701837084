import { toastConfig } from 'lib/reactToastify/config';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import {
  DAILY_STATUSES,
  DAILY_STATUS_VALUES,
} from 'shared_DEPRECATED/utils/config';

import { useDailyStatusMutation } from 'features/sprint/hooks/mutation/useDailyStatus';
import { useChallengePasses } from 'features/sprint/hooks/useChallengePasses';

import { useChallengeAnalytics } from './useChallengeAnalytics';

export const useHandleDailyStatusChange = ({
  challengeId,
  weekDayIndex,
  challengeType,
  currentStatus,
}) => {
  const { leftPasses } = useChallengePasses({ challengeId });
  const mutation = useDailyStatusMutation({
    challengeId,
    weekDayIndex,
    challengeType,
  });

  const { sendChallengeMarkedEvent } = useChallengeAnalytics();

  const handleStatusChange = useCallback(() => {
    const statusIndex = DAILY_STATUSES.findIndex(
      (status) => currentStatus === status
    );
    const status =
      DAILY_STATUSES[
        statusIndex === DAILY_STATUSES.length - 1 ? 0 : statusIndex + 1
      ];

    mutation.mutate({ status });

    sendChallengeMarkedEvent({
      type: challengeType,
      isTargetChallenge: false,
      status,
    });

    if (currentStatus === DAILY_STATUS_VALUES.PASS) {
      toast.success(
        `👉 One pass has been returned to you. You have ${
          leftPasses + 1
        } left.`,
        {
          icon: false,
          ...toastConfig,
        }
      );
    }
  }, [
    currentStatus,
    leftPasses,
    mutation,
    challengeType,
    sendChallengeMarkedEvent,
  ]);

  return { handleStatusChange };
};
