import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const CoachingToolDialogBanner = () => (
  <Box
    style={{
      backgroundColor: 'var(--bgCompSecondaryBig)',
      borderRadius: 'var(--border-radius)',
    }}
  >
    <Spacers sizes={['md', 'zr md']}>
      <Flex gap="1rem" justifyContent="flex-start" alignItems="flex-start">
        <Icon name={iconNames.communication} width={38} height={18} />
        <Typography as="div" type="meta" color="gray">
          This tool is designed to be completed in a coaching session alongside
          your coach; your coach will share more details and walk you through
          the tool when ready.
        </Typography>
      </Flex>
    </Spacers>
  </Box>
);
