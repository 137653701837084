import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { v4 as uuidv4 } from 'uuid';

import { IGoal, ColumnsType } from 'features/goal';

type TUseGoalsUpdateQuery = { userId: string; modifiedColumnId: string };

export const useGoalsUpdateQuery = ({
  userId,
  modifiedColumnId,
}: TUseGoalsUpdateQuery) => {
  const queryClient = useQueryClient();

  const previousValue = queryClient.getQueryData([queryKeys.goals, userId]);

  const addGoal = (updatedGoal: IGoal) => {
    const temporaryGoalId = uuidv4();
    queryClient.cancelQueries([queryKeys.goals, userId]);

    if (previousValue) {
      queryClient.setQueryData<{ columns: ColumnsType }>(
        [queryKeys.goals, userId],
        (oldVal) => {
          const updatedColumns = oldVal!.columns.map((column) => {
            if (column.columnId === modifiedColumnId) {
              column.goals = column.goals.filter(
                (goal) => goal.goalId !== updatedGoal.goalId
              );
            }

            if (column.columnId === updatedGoal.columnId) {
              column.goals.push({ ...updatedGoal, goalId: temporaryGoalId });
            }

            return column;
          });

          return { ...oldVal, columns: updatedColumns };
        }
      );
    }

    return { previousValue, temporaryGoalId };
  };

  const updateGoal = ({
    updatedGoal,
    temporaryGoalId,
  }: {
    updatedGoal: IGoal;
    temporaryGoalId: string;
  }) => {
    queryClient.cancelQueries([queryKeys.goals, userId]);

    if (previousValue) {
      queryClient.setQueryData<{ columns: ColumnsType }>(
        [queryKeys.goals, userId],
        (oldVal) => ({
          ...oldVal!,
          columns: oldVal!.columns.map((column) => ({
            ...column,
            goals: column.goals.map((goal) =>
              goal.goalId === temporaryGoalId ? updatedGoal : goal
            ),
          })),
        })
      );
    }
  };

  const editGoal = (updatedGoal: IGoal) => {
    queryClient.cancelQueries([queryKeys.goals, userId]);

    if (previousValue) {
      queryClient.setQueryData<{ columns: ColumnsType }>(
        [queryKeys.goals, userId],
        (oldVal) => {
          const updatedColumns = oldVal!.columns.map((column) => {
            if (column.columnId === modifiedColumnId) {
              column.goals = column.goals.filter(
                (goal) => goal.goalId !== updatedGoal.goalId
              );
            }

            if (column.columnId === updatedGoal.columnId) {
              column.goals.push(updatedGoal);
            }

            return column;
          });

          return { ...oldVal, columns: updatedColumns };
        }
      );
    }

    return { previousValue };
  };

  const removeGoal = (goalId: string) => {
    queryClient.cancelQueries([queryKeys.goals, userId]);

    if (previousValue) {
      queryClient.setQueryData<{ columns: ColumnsType }>(
        [queryKeys.goals, userId],
        (oldVal) => ({
          ...oldVal,
          columns: oldVal!.columns.map((column) => {
            if (column.columnId !== modifiedColumnId) {
              return column;
            }

            return {
              ...column,
              goals: column.goals.filter((goal) => goal.goalId !== goalId),
            };
          }),
        })
      );
    }

    return { previousValue };
  };

  return { editGoal, removeGoal, addGoal, updateGoal };
};
