import { useQuery } from 'react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getQueryString } from 'shared_DEPRECATED/utils';

import { useChallengeNotesQueryKey } from 'features/challengeNotes/hooks/useQueryKey';

export const useChallengeNotesQuery = (filter) => {
  const { request } = useQueryHTTPRequest();
  const queryString = getQueryString(filter);
  const challengeNotesQueryKey = useChallengeNotesQueryKey();

  const {
    data: notes,
    isFetching,
    refetch,
  } = useQuery(
    challengeNotesQueryKey,
    async () => {
      const response = await request({ url: `/api/notes?${queryString}` });

      return response.notes;
    },
    { initialData: [] }
  );

  return { notes, isFetching, refetch };
};
