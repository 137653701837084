import PropTypes from 'prop-types';

import { Divider } from 'shared_DEPRECATED/components/Divider';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import CreateChallengeButton from 'features/challenge/components/CreateChallengeButton';
import { useInitialChallenge } from 'features/challenge/hooks/useInitialChallenge';
import {
  ExploreDialogQuickStartSprintsList,
  useExploreDialogContentContext,
  useExploreChallengesAddChallengeModal,
} from 'features/planning';
import { ChallengeType } from 'features/sprint/utils';

import { Typography } from 'shared/components/Typography';

import { ExploreDialogChallengeInfoList } from './DialogChallengeInfoList';

export const ExploreDialogSearchResult = () => {
  const { challenges, quickStartSprints } = useExploreDialogContentContext();
  const initialChallenge = useInitialChallenge();
  const onCreateChallengeBtnClick = useExploreChallengesAddChallengeModal({
    initialChallenge,
    title: 'Create Challenge',
  });

  if (challenges?.length === 0 && quickStartSprints?.length === 0) {
    return (
      <Flex
        alignItems="stretch"
        flexDirection="column"
        justifyContent="center"
        height="60vh"
      >
        <Flex flexDirection="column" justifyContent="center">
          <Typography color="gray" type="meta">
            Try another search or create a custom challenge.
          </Typography>
          <Spacers sizes={['zr zr md zr', 'zr zr sm zr']} />
          <CreateChallengeButton
            onCreateChallengeBtnClick={onCreateChallengeBtnClick}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      {quickStartSprints?.length > 0 && (
        <>
          <Typography as="h2" fontWeight="semiBold" type="body" color="black">
            Quick start sprints
          </Typography>
          <Typography type="small" color="gray">
            Try sets of challenges designed by our team to help participant get
            started faster
          </Typography>
          <Spacer size="md zr" />
          <ExploreDialogQuickStartSprintsList />
        </>
      )}
      {challenges?.length > 0 && (
        <>
          <Spacer size="md zr" />
          <Divider />
          <Spacer size="md zr" />
          <Typography as="h2" fontWeight="semiBold" type="body" color="black">
            Individual challenges
          </Typography>
          <Typography type="small" color="gray">
            Build participant’s own sprint
          </Typography>
          <Spacer size="md zr" />
          <ExploreDialogChallengeInfoList />
        </>
      )}
    </>
  );
};

ExploreDialogSearchResult.propTypes = {
  searchResult: PropTypes.arrayOf(ChallengeType).isRequired,
};
