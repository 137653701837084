import { useNavigate } from 'react-router-dom';

import { BoardColumnPinButton, Button, ButtonGroup, VARIANTS } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { PlanningSprintModel } from 'features/planning';
import { SPRINT_STATES, SPRINT_STATUS_LABELS } from 'features/sprint/config';
import { SprintStateValues } from 'features/sprint/config/types';
import { useSprintLink } from 'features/sprint/hooks';

import { BADGE_COLORS, Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type TPlanningSprintHeader = {
  title: string;
  sprint: PlanningSprintModel;
};

export const PlanningSprintHeader = ({
  title,
  sprint,
}: TPlanningSprintHeader) => {
  const sprintLink = useSprintLink(sprint);

  const navigate = useNavigate();
  const onViewButtonClick = () => {
    navigate(sprintLink);
  };

  return (
    <Spacer size="sm md">
      <Flex
        dataTestid="planning-sprint-board-header"
        alignItems="center"
        width="100%"
      >
        <Flex alignItems="flex-start" flexDirection="column">
          <Typography color="gray" fontWeight="medium" type="meta">
            {title}
          </Typography>
          <Flex justifyContent="space-between" width="100%">
            <Flex>
              <Typography color="gray" fontWeight="regular" type="small">
                {sprint.formattedDate}
              </Typography>
              <Spacer size="sm" />
              <Badge bgColor={PLANNING_SPRINT_BADGE_STYLES_MAP[sprint.state]}>
                <Typography color="whiteTotal" type="small">
                  {SPRINT_STATUS_LABELS[sprint.state]}
                </Typography>
              </Badge>
            </Flex>
          </Flex>
        </Flex>
        <ButtonGroup spacing="0.5rem" size="sm">
          <BoardColumnPinButton columnId={sprint.sprintId} />
          <Button onClick={onViewButtonClick} variant={VARIANTS.TERTIARY}>
            <Typography color="black" type="meta" fontWeight="regular">
              View
            </Typography>
          </Button>
        </ButtonGroup>
      </Flex>
    </Spacer>
  );
};

const PLANNING_SPRINT_BADGE_STYLES_MAP: Record<
  SprintStateValues,
  BADGE_COLORS
> = {
  [SPRINT_STATES.ACTIVE]: 'green',
  [SPRINT_STATES.FINISHED]: 'blue',
};
