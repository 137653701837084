import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export type GoalResponseType = { goalId: string; title: string };

export const useGoalsQuery = () => {
  const { request } = useQueryHTTPRequest();
  const { userId } = useCurrentModeUser();

  return useQuery<{ items: GoalResponseType[] }, unknown, GoalResponseType[]>(
    [queryKeys.challengeGoals, userId],
    () => request({ url: `/api/web/goals?user_id=${userId}` }),
    {
      enabled: !!userId,
      select: (data) => data.items,
    }
  );
};
