import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  useExploreDialogContentContext,
  ExploreDialogChallengeInfo,
} from 'features/planning';
import { ChallengeType } from 'features/sprint/utils';

export const ExploreDialogChallengeInfoList = () => {
  const { areChallengesLoading, challenges } = useExploreDialogContentContext();

  if (areChallengesLoading) {
    return <Loader dataTestid="challenges-loader" size="sm" />;
  }

  return (
    <Flex flexDirection="column" alignItems="stretch">
      {challenges.map((challenge) => (
        <ExploreDialogChallengeInfo
          key={challenge.curatedChallengeId || challenge.customChallengeId}
          challenge={challenge}
        />
      ))}
    </Flex>
  );
};

ExploreDialogChallengeInfoList.propTypes = {
  challenges: PropTypes.arrayOf(ChallengeType).isRequired,
};
