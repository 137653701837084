import { useEffect } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';

import {
  ChallengeNotesRepliesContent,
  ChallengeNotesRepliesHeader,
} from 'features/challengeNotes/components/Replies';
import useSprintQuery from 'features/sprint/hooks/query/useSprint';

import { sidebar, SidebarBody } from 'shared/components/Sidebar';
import { useSidebarContext } from 'shared/components/Sidebar/context';

export const ChallengeNotesReplies = () => {
  const { isSidebarInited, sprint, note, user } = useSidebarContext();

  const { data: fetchedSprint } = useSprintQuery({
    userId: user.email || user.userEmail,
    sprintId: note.sprintId,
    options: {
      enabled: !sprint,
    },
  });

  useEffect(() => {
    if (!sprint && fetchedSprint && isSidebarInited) {
      sidebar.setContext({
        sprint: fetchedSprint,
      });
    }
  }, [isSidebarInited, fetchedSprint, sprint]);

  if (!sprint) {
    return <Loader />;
  }

  return (
    <>
      <ChallengeNotesRepliesHeader />
      <SidebarBody>
        <ChallengeNotesRepliesContent />
      </SidebarBody>
    </>
  );
};
