import { useQuery } from 'react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getSharedSprintsQueryKey } from 'api/utils';

import { SPRINT_TYPES } from 'features/sprint/config';
import { mapSprints } from 'features/sprint/utils';

export const useSharedSprintsQuery = (email, options = {}) => {
  const { request } = useQueryHTTPRequest();

  return useQuery(
    getSharedSprintsQueryKey(email),
    async () => await request({ url: `/api/sprintmates/${email}/sprints` }),
    {
      select: (data) =>
        mapSprints({
          sprints: data?.items || [],
          sprintType: SPRINT_TYPES.SHARED,
        }),
      ...options,
    }
  );
};
