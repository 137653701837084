import { Typography } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  CoachingToolDialogBanner,
  CoachingToolDialogInfo,
  useCoachingToolQuery,
} from 'features/coachingTool';

import { ModalBody, ModalHeader } from 'shared/components/Modal';

type TCoachingToolDialog = {
  toolId: string;
};

const CoachingToolDialog = ({ toolId }: TCoachingToolDialog) => {
  const { data: coachingTool, isLoading } = useCoachingToolQuery({
    toolId,
  });

  if (isLoading) {
    return <Loader />;
  }

  return coachingTool ? (
    <>
      <ModalHeader />
      <ModalBody>
        <HotjarSuppression>
          <Flex
            width="100%"
            height="100%"
            flexDirection="column"
            alignItems="flex-start"
            gap="1rem"
          >
            <CoachingToolDialogInfo
              name={coachingTool.name}
              url={coachingTool?.picture?.url}
            />
            <CoachingToolDialogBanner />
            <Typography color="black" fontWeight="medium" type="smallHeading">
              Description
            </Typography>
            <Typography color="black" fontWeight="regular" type="meta">
              {coachingTool.description}
            </Typography>
          </Flex>
        </HotjarSuppression>
      </ModalBody>
    </>
  ) : null;
};

export default CoachingToolDialog;
