import { useQuery } from 'react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

const useSprintHistoryQuery = ({ sprintId }) => {
  const { request } = useQueryHTTPRequest();
  const { data, isFetching } = useQuery(
    `history-${sprintId}`,
    () => request({ url: `/api/sprints/${sprintId}/history` }),
    { initialData: { items: [] } }
  );

  return { history: data.items, isFetching };
};

export default useSprintHistoryQuery;
