import Box from 'shared_DEPRECATED/components/Box';

import { ATTENDANCE_COLOR_MAP } from 'features/sessions/config';
import { SessionAttendanceStatusType } from 'features/sessions/config/types';

type ChallengeNotesCardRecapSessionStatusIconProps = {
  attendance: SessionAttendanceStatusType;
};

const getNoteIconColor = (attendanceStatus: SessionAttendanceStatusType) =>
  ATTENDANCE_COLOR_MAP[attendanceStatus] || {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  };

export const ChallengeNotesCardRecapSessionStatusIcon = ({
  attendance,
}: ChallengeNotesCardRecapSessionStatusIconProps) => {
  const { backgroundColor, borderColor } = getNoteIconColor(attendance);

  return (
    <Box
      style={{
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: 'var(--border-radius4)',
        border: `1px solid ${borderColor}`,
        backgroundColor,
        boxSizing: 'border-box',
      }}
    />
  );
};
