import { useQuery, UseQueryOptions } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { PlanningSprintModel, IPlanningSprint } from 'features/planning';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

const mapSprintsQueryData = (
  sprints: IPlanningSprint[]
): PlanningSprintModel[] =>
  sprints.map((sprint) => new PlanningSprintModel(sprint));

export const useSprintsQuery = <T>({
  options,
}: {
  options?: Omit<
    UseQueryOptions<PlanningSprintModel[], unknown, T, 'sprints'>,
    'queryKey' | 'queryFn'
  >;
} = {}) => {
  const { request } = useQueryHTTPRequest();
  const { userId } = useCurrentModeUser();
  const { select, ...otherOptions } = options || {};

  return useQuery<{ items: IPlanningSprint[] }, unknown, T, 'sprints'>(
    queryKeys.sprints,
    () => request({ url: `/api/web/sprints?user_id=${userId}` }),
    {
      enabled: !!userId,
      // @ts-ignore
      select: (data) => {
        let selectedData = mapSprintsQueryData(data?.items);

        return select ? select(selectedData) : selectedData;
      },
      ...otherOptions,
    }
  );
};
