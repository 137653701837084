import { useOpenChallengeModalWithBackButton } from 'features/challenge/hooks/useOpenChallengeModalWithBackButton';
import useQuickStartSprintModal from 'features/challenge/hooks/useQuickStartSprintModal';
import { useQuickStartSprintQuery } from 'features/sprint/hooks/query/useQuickStartSprint';

import { useModalContext } from 'shared/components/Modal';

export const useChallengeQuickStartSprintModalContent = ({ qssId }) => {
  const { onSubmit, sprint } = useModalContext();
  const { data: qss, isLoading } = useQuickStartSprintQuery({ qssId });

  const openChallengeModal = useOpenChallengeModalWithBackButton();
  const openQuickStartSprintModal = useQuickStartSprintModal();
  const onChallengeClick = (initialChallenge) => () =>
    openChallengeModal({
      challenge: initialChallenge,
      onChallengeSubmit: (challenge) => {
        onSubmit(challenge);

        openQuickStartSprintModal({ qssId })();
      },
      sprint,
      onBackBtnClick: openQuickStartSprintModal({ qssId }),
      title: 'Add Challenge',
      shouldCloseOnSubmit: false,
    });

  return {
    qss,
    isLoading,
    onChallengeClick,
    onSubmit,
  };
};
