import { OptionsMenu } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  PlanningDraftSprintMenuEditButton,
  PlanningDraftSprintMenuRemoveButton,
  IPlanningDraftSprint,
} from 'features/planning';

type TPlanningChallengeCardMenu = {
  challenge: IRepeatedChallenge | INonRepeatedChallenge;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintsItemMenu = ({
  challenge,
  sprint,
}: TPlanningChallengeCardMenu) => (
  <OptionsMenu
    //@ts-ignore
    icon={iconNames.ellipsisHorizontal}
  >
    <PlanningDraftSprintMenuEditButton sprint={sprint} challenge={challenge} />
    <PlanningDraftSprintMenuRemoveButton
      sprint={sprint}
      challengeId={challenge.challengeId!}
    />
  </OptionsMenu>
);
