import { useColorMode } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import TextField from 'shared_DEPRECATED/components/Form/TextField';

import { CoachingToolsCardEditButtons } from 'features/sessions/components';
import {
  SessionsFormCoachingToolsEffectivenessRate,
  SessionsFormCoachingToolsLinkField,
  SessionsFormCoachingToolsSection,
  SessionsFormCoachingToolsSelector,
} from 'features/sessions/components/Form/CoachingTools';
import { CoachingToolNote } from 'features/sessions/config/types';

type SessionsFormCoachingToolsCardEditProps = {
  formKey: string;
  coachingTool: CoachingToolNote;
  onDelete: () => void;
  canBeDeleted?: boolean;
};

export const SessionsFormCoachingToolsCardEdit = ({
  formKey,
  onDelete,
  canBeDeleted = false,
  coachingTool,
}: SessionsFormCoachingToolsCardEditProps) => {
  const { register, setValue } = useFormContext();
  const { colorMode: theme } = useColorMode();
  const isThemeLight = theme === 'light';

  return (
    <>
      <SessionsFormCoachingToolsSection>
        <SessionsFormCoachingToolsSelector
          onSelect={(coachingTool) => {
            setValue(`${formKey}.tool`, coachingTool, { shouldDirty: true });
          }}
          selected={coachingTool.tool.value}
        />
      </SessionsFormCoachingToolsSection>
      <SessionsFormCoachingToolsLinkField
        coachingToolValue={coachingTool.tool.value}
        formKey={formKey}
      />
      <SessionsFormCoachingToolsSection title="Session specific notes">
        <TextField
          {...register(`${formKey}.notes`)}
          //@ts-ignore
          style={{
            height: '5rem',
            backgroundColor: isThemeLight ? 'var(--bgPrimary)' : 'transparent',
            color: 'var(--fgPrimary)',
            border: 'var(--border-secondary)',
          }}
        />
      </SessionsFormCoachingToolsSection>
      <SessionsFormCoachingToolsSection>
        <SessionsFormCoachingToolsEffectivenessRate
          currentRate={coachingTool.toolEffectivenessRate.toString()}
          formKey={formKey}
        />
      </SessionsFormCoachingToolsSection>
      <Flex gap="var(--spacing04)">
        <CoachingToolsCardEditButtons
          canBeDeleted={canBeDeleted}
          onDelete={onDelete}
          onSave={() => setValue(`${formKey}.editMode`, false)}
          disabled={!coachingTool.tool.value}
        />
      </Flex>
    </>
  );
};
