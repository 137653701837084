import { CHALLENGE_PROOFS_FEATURE_FLAG } from 'featureFlags/config';
import { useFeatureFlag, useParticipantsFeatureFlag } from 'featureFlags/hooks';

import { USER_MODES } from 'features/user/config';
import { useUserMode } from 'features/user/hooks';

export const useChallengeProofsFeatureFlag = () => {
  const userMode = useUserMode();
  const participantFeatureFlag = useParticipantsFeatureFlag(
    CHALLENGE_PROOFS_FEATURE_FLAG
  );
  const myFeatureFlag = useFeatureFlag(CHALLENGE_PROOFS_FEATURE_FLAG);

  return userMode === USER_MODES.dashboard
    ? myFeatureFlag
    : participantFeatureFlag;
};
