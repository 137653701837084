import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Tab } from 'shared_DEPRECATED/components/Tabs';

import { ChallengeNotesUnreadIndicator } from 'features/challengeNotes/components/Card';
import { NOTE_FILTERS, NOTE_TYPES } from 'features/challengeNotes/config';
import {
  useChallengeNotesContext,
  useChallengeNotesFilterContext,
} from 'features/challengeNotes/context';

import { Typography } from 'shared/components/Typography';

const ChallengeNotesTabPropTypes = {
  title: PropTypes.string.isRequired,
  hasUnread: PropTypes.bool.isRequired,
  noteType: PropTypes.oneOf(Object.values(NOTE_TYPES)).isRequired,
};

export const ChallengeNotesTab = ({ title, noteType }) => {
  const { filter, setFilter } = useChallengeNotesFilterContext();
  const { unreadNotesCounterByType } = useChallengeNotesContext();
  const hasUnread = unreadNotesCounterByType[noteType] > 0;

  const onTabClick = () => {
    if (filter.filterNotesTypes === noteType) {
      return null;
    }

    setFilter({
      [NOTE_FILTERS.FILTER_NOTES_TYPES]: noteType,
      [NOTE_FILTERS.FILTER_CHALLENGE_IDS]: null,
    });
  };

  return (
    <Tab onClick={onTabClick}>
      <Box
        style={{ position: 'relative' }}
        dataTestid={`notes-sidebar-tab-${title.split(' ')[0].toLowerCase()}`}
      >
        <ChallengeNotesUnreadIndicator position="right" visible={hasUnread} />
        <Spacer size="zr md">
          <Spacer size="zr xsm">
            <Typography
              type="small"
              dataTestid={`notes-sidebar-tab-${title
                .split(' ')[0]
                .toLowerCase()}-title`}
            >
              {title}
            </Typography>
          </Spacer>
        </Spacer>
      </Box>
    </Tab>
  );
};

ChallengeNotesTab.propTypes = ChallengeNotesTabPropTypes;
