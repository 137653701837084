import Spacer from 'shared_DEPRECATED/components/Spacer/Spacer';
import { TabList, Tabs } from 'shared_DEPRECATED/components/Tabs';

import { ChallengeNotesChallengeFilter } from 'features/challengeNotes/components/ChallengeFilter';
import { ChallengeNotesContent } from 'features/challengeNotes/components/ChallengeNotesContent';
import { ChallengeNotesContentContainer } from 'features/challengeNotes/components/ContentContainer';
import {
  ChallengeNotesTab,
  ChallengeNotesTabPanel,
} from 'features/challengeNotes/components/Tabs';
import {
  NOTE_TABS_COMPONENT_CONFIG,
  NOTE_TYPES,
} from 'features/challengeNotes/config';
import { NotesType } from 'features/challengeNotes/config/propTypes';
import { useUserMode } from 'features/user/hooks';

const challengeNotesTabChildrenByType = {
  [NOTE_TYPES.CHALLENGE_NOTE]: (
    <>
      <Spacer size="xlg zr zr" />
      <ChallengeNotesChallengeFilter />
    </>
  ),
  [NOTE_TYPES.SESSION_RECAP_NOTE]: null,
};

export const ChallengeNotesTabs = () => {
  const userMode = useUserMode();

  return (
    <Tabs id="challenge-notes-tabs">
      <TabList>
        {Object.entries(NOTE_TABS_COMPONENT_CONFIG).map(
          ([noteType, config]) => {
            const { label } = config;

            return (
              <ChallengeNotesTab
                key={noteType}
                title={label}
                noteType={noteType}
              />
            );
          }
        )}
      </TabList>
      {Object.entries(NOTE_TABS_COMPONENT_CONFIG).map(([noteType, config]) => {
        const { getTextPlaceholder } = config;
        const textPlaceholder = getTextPlaceholder(userMode);

        return (
          <ChallengeNotesTabPanel key={noteType}>
            <ChallengeNotesContentContainer
              noteType={noteType}
              textPlaceholder={textPlaceholder}
            >
              <ChallengeNotesContent noteType={noteType} />
            </ChallengeNotesContentContainer>
            {challengeNotesTabChildrenByType[noteType]}
          </ChallengeNotesTabPanel>
        );
      })}
    </Tabs>
  );
};

ChallengeNotesTabs.propTypes = { notes: NotesType };
