import { TabPanel, TabPanelsProps } from '@chakra-ui/react';

import { ButtonGroup } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  PlanningBoardItems,
  canItemBeDroppedInDropZone,
  PlanningBoardItemsWrapper,
  PlanningBacklogAddChallengeButton,
  PlanningFilter,
  usePlanningBacklogFilterContext,
} from 'features/planning';

import { BoardColumn } from 'shared/components/Board';

type TPlanningBacklogChallengesTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogChallengesTab = (
  props: TPlanningBacklogChallengesTab
) => {
  const { filteredChallenges: challenges, isLoading } =
    usePlanningBacklogFilterContext();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <TabPanel
      m={0}
      p={0}
      height="100%"
      width="100%"
      overflowY="scroll"
      overflowX="hidden"
      {...props}
    >
      <PlanningBoardItemsWrapper
        type="challenge"
        itemsCount={challenges.length}
        navigation={
          <Flex alignItems="center" height="100%">
            <ButtonGroup spacing="0.5rem" size="sm">
              <PlanningFilter />
              <PlanningBacklogAddChallengeButton />
            </ButtonGroup>
          </Flex>
        }
      >
        <BoardColumn>
          <PlanningBoardItems
            challenges={challenges}
            canDrop={canItemBeDroppedInDropZone('backlog')}
          />
        </BoardColumn>
      </PlanningBoardItemsWrapper>
    </TabPanel>
  );
};
