import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { useAddGoal } from 'features/goal';

import { Typography } from 'shared/components/Typography';

export const GoalAddButton = () => {
  const { onAddGoalClick } = useAddGoal();

  return (
    <Button
      onClick={onAddGoalClick}
      variant={VARIANTS.PRIMARY}
      dataTestid="add-new-goal-btn"
    >
      <Spacers sizes={['md', 'xsm sm']}>
        <Flex>
          <Icon width={10} height={10} name={iconNames.plus} stroke="white" />
          <Spacer size="xsm" />
          <Typography type="small">Add goal</Typography>
        </Flex>
      </Spacers>
    </Button>
  );
};
