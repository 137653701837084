import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';

import { PLANNING_SPRINT_BACKGROUND_STYLES_MAP } from 'features/planning';
import { SprintStateValues } from 'features/sprint/config/types';

type TPlanningSprintWrapper = {
  children: ReactNode;
  sprintState: SprintStateValues;
};

export const PlanningSprintWrapper = ({
  children,
  sprintState,
}: TPlanningSprintWrapper) => (
  <Box
    style={{
      height: '100%',
      width: '100%',
      backgroundColor: PLANNING_SPRINT_BACKGROUND_STYLES_MAP[sprintState],
      borderRadius: 'var(--border-radius12)',
    }}
  >
    {children}
  </Box>
);
